<!-- <div class="marT20" style="background-color: #fdf8e6;padding-bottom: 3rem;">
    <div class="row downloadAppBlock" [style.backgroundImage]="'url('+imagePathPrepend + pageSection3.image.path+')'">

        

        <div class="col-sm-5">
            <!-- <img [src]="imagePathPrepend + pageSection3.image.path" style="max-width: 100% !important;padding: 20px;"> --
        </div>
        <div class="col-sm-7 padd20">
            <h2 class="appTitle"> {{pageSection3.heading}} </h2>
            <p>
                {{pageSection3.subheading}}
            </p>
            
            <div style="display: inline">
                <div class="appContext">
                    <img src="../../../assets/images/playstore.png" style="max-width: 100%;" class="imgWidth">
                </div>
            </div>
            <div class="appContext">
                <img src="../../assets/images/apple_store.png" style="max-width: 100%;" class="imgWidth">
            </div>
        </div>
    </div>
</div> -->


<div class="site-easy  marT20 mainContent">
    <div class="footer-bottom">
        <div class="container">
            <div class="row">
                <div class="col-xs-12 col-sm-6 col-md-6">

                    <!-- <div class="row downloadAppBlock"
                        [style.backgroundImage]="'url('+imagePathPrepend + pageSection3.image.path+')'"> 
                        
                    </div> -->
                    <img src="../../../assets/images/mobile.png" class="max-w-100 downloadImg">

                    <!-- <img [src]="imagePathPrepend + pageSection3.image.path" style="max-width: 100% !important;padding: 20px;"> -->
                </div>


                <div class="col-xs-12 col-sm-6 col-md-6" style="padding: 0 110px;">
                    <div class="module-body">
                        <h2 class="appTitle"> Download app </h2>
                        <p class="font14 MuiTypography-subtitle1" style="color:white;font-weight: 300;">
                            Enjoy the best deals in town all in a single app
                            from where ever you are
                        </p>
                        <div style="margin-top: 40px;">
                            <div class="appContext" style="margin-right: 20px;">
                                <img src="../../../assets/images/playstore.png" class="imgWidth">
                            </div>
                            <div class="appContext">
                                <img src="../../assets/images/appstore.png" class="imgWidth">
                            </div>
                        </div>
                        
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>