import { Component, Input, OnInit, SimpleChanges } from '@angular/core';

import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { Observable, of, Subject } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, map, switchMap, takeUntil, tap } from 'rxjs/operators';

import { SearchSection } from 'src/app/interfaces/search-section';
import { NgbTypeaheadSelectItemEvent } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { Constants } from 'src/app/config/constants';
import { HomeService } from 'src/app/services/home.service';

@Component({
  selector: 'app-slider-banners',
  templateUrl: './slider-banners.component.html',
  styleUrls: ['./slider-banners.component.scss']
})
export class SliderBannersComponent implements OnInit {

  imagePaths:any;

  @Input() bannerImages: any = [];
  @Input() parentMessage: string;
  imagePathPrepend: string = Constants.REST_API_SERVER;
  constructor(private homeService: HomeService,
    private router: Router) {

  }

  ngOnInit(): void {

  

  }

  ngOnChanges(changes: SimpleChanges) {
    console.log('OnChanges');
    console.log(JSON.stringify(changes));

    // tslint:disable-next-line:forin
    for (const propName in changes) {
      const change = changes[propName];
      console.log("ggetting data", change);
      const gettingParentData = change.currentValue;
      this.imagePaths = gettingParentData;
      console.log("=======", gettingParentData);

    }
  }

}
