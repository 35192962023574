import { Pipe, PipeTransform } from '@angular/core';
import { LocationProduct } from 'src/app/interfaces/location-product';

@Pipe({
  name: 'productsPipe'
})
export class ProductsPipePipe implements PipeTransform {

  transform(locationProducts: LocationProduct[], ...args: any[]): LocationProduct[] {

    if(args.length != 2) {
      return locationProducts;
    }
    if(args[0] == 'all') {
      if(args[1] == undefined)
        return locationProducts;
      else {
        locationProducts = locationProducts.filter(lp => lp.product.name.toLowerCase().indexOf(args[1].toLowerCase()) > -1)
      }
    } else if(args[0] != 'all') {

      locationProducts.filter(lp => {
        console.log(lp.product.categories)
        return true;
      })
      locationProducts = locationProducts.filter(lp => {
        if(lp.product.categories.length > 0) {
          return lp.product.categories.findIndex(c => c.name.toLowerCase().indexOf(args[0].toLowerCase()) > -1) > -1
        } else {
          return true;
        }  
      })
      if(args[1] != undefined)
        locationProducts = locationProducts.filter(lp => lp.product.name.toLowerCase().indexOf(args[1].toLowerCase()) > -1)
    }
    return locationProducts;
  }

}
