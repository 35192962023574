import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { HomeService } from 'src/app/services/home.service';

@Component({
  selector: 'app-register-supplier',
  templateUrl: './register-supplier.component.html',
  styleUrls: ['./register-supplier.component.scss']
})
export class RegisterSupplierComponent implements OnInit,OnDestroy, AfterViewInit {
 


  @ViewChild('ngOtpInput') ngOtpInputRef:ElementRef;
  registerForm: FormGroup;
  registerFormSubmitted: boolean = false;
  registerUser: { first_name: string, last_name: string, email: string, mobile_number: string, password: string } = {
    first_name: '',
    last_name: '',
    email: '',
    mobile_number: '',
    password: ''
  }
  otpSent: boolean = false;
  otp: string = '';
  constructor(public router: Router, public homeService: HomeService,
    private cd: ChangeDetectorRef,
    public formBuilder: FormBuilder,private toast: ToastrService) {

        
   }
   get registerEror() {
    return this.registerForm.controls;
  }
  ngOnDestroy(): void {
    
  }
  ngAfterViewInit(): void {
    if(this.ngOtpInputRef) this.ngOtpInputRef.nativeElement.setValue('')
  }

  ngOnInit(): void {
    this.registerForm = this.formBuilder.group({
      first_name: ['', [Validators.required]],
      last_name: ['', [Validators.required]],
      register_email: ['', [Validators.required, Validators.email]],
      mobile_number: ['', [Validators.required]],
      register_pwd: ['', [Validators.required, Validators.minLength(6)]],
    })
  }
  doRegister() {
    this.registerFormSubmitted = true;
    if (this.registerForm.invalid) {
      return;
    } else {
      let obj = {
        name: this.registerUser.first_name + ' ' + this.registerUser.last_name,
        email: this.registerUser.email,
        phone: this.registerUser.mobile_number,
        password: this.registerUser.password,
        otp: this.otp,
        role: 'supplier'
      }
      this.homeService.userRegister(obj).subscribe((resp: any) => {
          // alert("Verification email sent successfully.");
          this.toast.success('Account created and please contact support team for verification.')
          // this.registerUser= {
          //   first_name: '',
          //   last_name: '',
          //   email: '',
          //   mobile_number: '',
          //   password: ''
          // };
          this.otpSent = false;
          // if(this.ngOtpInputRef) this.ngOtpInputRef.nativeElement.setValue('')

          this.router.navigate([''])
      }, (error) => {
        console.log("Error Occured Here", error);
        this.toast.error(error)
      });
    }
  }

  mobileOTP() {
    this.homeService.userRegisterMobileOtp({phone: this.registerUser.mobile_number}).subscribe((data) => {
      if(data.errors) {

      } else if(data) {
        this.otpSent = true;
      }
    })
    
  }
  onOtpChange(e:any) {
    this.otp = e;
  }

  
}
