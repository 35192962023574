import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Constants } from 'src/app/config/constants';
import { HomeService } from 'src/app/services/home.service';

@Component({
  selector: 'app-order-confirmed',
  templateUrl: './order-confirmed.component.html',
  styleUrls: ['./order-confirmed.component.scss']
})
export class OrderConfirmedComponent implements OnInit {

  orderedItems: string = '';
  orderedPrice: number = 0;
  currency = 'USD';
  constructor(public homeService: HomeService, private router: Router) {
    this.orderedItems = homeService.recentOrder?.order_items.map((o, i) => (i+1) + '. ' + o.name).join('<br />')
    this.orderedPrice = homeService.recentOrder?.total;

    this.homeService.userPinLocationCountry = localStorage.getItem('userPinLocationCountry');
    let country:string = this.homeService.userPinLocationCountry;
    this.currency = Constants.CURRENCY[country]
   }

  ngOnInit(): void {
  }

  orders() {
    this.router.navigate(['orders']);
  }

}
