<div class="container">
    <div class="row">
        <div class="col-sm-4">
            <div class="mainExplore">
                <img src="../../../assets/images/bg.png" class="exploreImg">
                <div class="contentTitle">
                    <div class="headerTitle">
                        <span class="dark-bl f-b">Why</span> <span class="light-bl f-b"> Easy Zabiha </span>
                    </div>
                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been
                        the
                        industry's standard dummy text ever since,Lorem Ipsum is simply dummy text of the printing and
                        typesetting industry.
                    </p>
                </div>
            </div>
        </div>

        <div class="col-sm-4">
            <div class="mainExplore">
                <img src="../../../assets/images/dlvr.png" class="exploreImg">
                <div class="contentTitle">
                    <div class="headerTitle">
                        <span class="dark-bl f-b">Why</span> <span class="light-bl f-b"> Easy Zabiha </span>
                    </div>
                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been
                        the
                        industry's standard dummy text ever since,Lorem Ipsum is simply dummy text of the printing and
                        typesetting industry.
                    </p>
                </div>
            </div>
        </div>

        <div class="col-sm-4">
            <div class="mainExplore">
                <img src="../../../assets/images/meat.png" class="exploreImg">
                <div class="contentTitle">
                    <div class="headerTitle">
                        <span class="dark-bl f-b">Why</span> <span class="light-bl f-b"> Easy Zabiha </span>
                    </div>
                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been
                        the
                        industry's standard dummy text ever since,Lorem Ipsum is simply dummy text of the printing and
                        typesetting industry.
                    </p>
                </div>
            </div>
        </div>
    </div>
</div>