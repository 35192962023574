<!-- <p>about-us works!</p> -->

<div class="mainHeader" style="padding-top: 100px;">
    <ng-scrollbar>
        <div>
            <div class="container">
                <div class="row single-product">
                    <div class="col-md-12">
                        <div class="ex-sec">
                            <div class="title">
                                <span class="dark-bl f-b"> Latest </span> <span class="light-bl f-b"> Updates </span>
                            </div>

                        </div>
                    </div>

                    <div class="row">
                        <div class="col-sm-4">
                            <div class="mainExplore">
                                <img src="../../../assets/images/bg.png" class="exploreImg">
                                <div class="contentTitle">
                                    <div class="headerTitle">
                                        <span class="dark-bl f-b">Why</span> <span class="light-bl f-b"> Easy Zabiha
                                        </span>
                                    </div>
                                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem
                                        Ipsum has been
                                        the
                                        industry's standard dummy text ever since,Lorem Ipsum is simply dummy text of
                                        the printing and
                                        typesetting industry.
                                    </p>
                                    <button type="button" style="display: block;"
                                        class="btn btn-outline-primary w-100">Buy Now</button>
                                </div>
                            </div>
                        </div>

                        <div class="col-sm-4">
                            <div class="mainExplore">
                                <img src="../../../assets/images/dlvr.png" class="exploreImg">
                                <div class="contentTitle">
                                    <div class="headerTitle">
                                        <span class="dark-bl f-b">Why</span> <span class="light-bl f-b"> Easy Zabiha
                                        </span>
                                    </div>
                                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem
                                        Ipsum has been
                                        the
                                        industry's standard dummy text ever since,Lorem Ipsum is simply dummy text of
                                        the printing and
                                        typesetting industry.
                                    </p>
                                    <button type="button" style="display: block;"
                                        class="btn btn-outline-primary w-100">Buy Now</button>
                                </div>
                            </div>
                        </div>

                        <div class="col-sm-4">
                            <div class="mainExplore">
                                <img src="../../../assets/images/meat.png" class="exploreImg">
                                <div class="contentTitle">
                                    <div class="headerTitle">
                                        <span class="dark-bl f-b">Why</span> <span class="light-bl f-b"> Easy Zabiha
                                        </span>
                                    </div>
                                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem
                                        Ipsum has been
                                        the
                                        industry's standard dummy text ever since,Lorem Ipsum is simply dummy text of
                                        the printing and
                                        typesetting industry.
                                    </p>
                                    <button type="button" style="display: block;"
                                        class="btn btn-outline-primary w-100">Buy Now</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- /.col -->
                </div>               
            </div>
        </div>
    </ng-scrollbar>
</div>