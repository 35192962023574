export class Constants {
    // PAGE NAMES
    public static ADMIN_PAGES_HOME_SECTION1 = 'ADMIN_PAGES_HOME_SECTION1';
    public static ADMIN_PAGES_EID_SECTION1 = 'ADMIN_PAGES_EID_SECTION1';
    public static ADMIN_PAGES_REGULAR_SECTION1 = 'ADMIN_PAGES_REGULAR_SECTION1';
    public static ADMIN_PAGES_DONATION_SECTION1 = 'ADMIN_PAGES_DONATION_SECTION1';

    public static ADMIN_PAGES_HOME_SECTION2 = 'ADMIN_PAGES_HOME_SECTION2';
    public static ADMIN_PAGES_HOME_SLIDER = 'ADMIN_PAGES_HOME_SLIDER'
    public static ADMIN_PAGES_HOME_SECTION3 = 'ADMIN_PAGES_HOME_SECTION3';
    public static ADMIN_PAGES_HOME_SECTION4 = 'ADMIN_PAGES_HOME_SECTION4';

    // API
    public static REST_API_SERVER = "https://easyzabiha.com:3010";
    // public static REST_API_SERVER = "http://localhost:3000";

    public static GOOGLE_MAPS_API_KEY = "AIzaSyARBfux0gfS740KP2zkhmD-DFYPebpcczU";

    public static CURRENCY:{ [key: string]: string } = {
        'Canada': 'CAD',
        'India': 'INR',
        'United States': 'USD',
        'United Kingdom': 'GBP',
        'Germany': 'EUR',
        'Australia': 'AUD',
        'New Zealand': 'NZD',
        'Sigapore': 'SGD',
        'United Arab Emirates': 'AED',
        'Saudi Arabia': 'SAR',
        'Norway': 'NOK'
    }
    

}
