<div style="background-color: #faf4f4 !important;" >
    <!-- <ng-scrollbar> -->
        <!-- *ngIf="homeService.recentOrder" -->
        <div class="confirmOrdr">
            <div class="dataInfo">
                <img src="../../../assets/images/cnfirm_sucess.png" class="orderSucess">
                <h3 *ngIf="homeService?.homeRoute == 'donate'">Donation Confirmed </h3>
                <h3 *ngIf="homeService?.homeRoute != 'donate'">Order Confirmed </h3>

                <p> It will Get Soon </p>
            </div>
            <div class="orderDetails bxshadow">
                <img src="../../../assets/images/del.png" class="del">
                <p> <span class="navlink" (click)="orders()">Click here</span> to track your order. </p>
            </div>
        </div>
        <div class="container">

            <div class="restaInfo ">
                <div class="bxshadow1">

                    <div class="itemOrder cuponcodes">
                        <p class="noItem hintLabel">Items </p>
                        <p class="noItem" [innerHTML]="orderedItems">
                        </p>
                    </div>


                    <div class="total">
                        <p class="noItem hintLabel">Total Amount </p>
                        <p class="noItem"> {{orderedPrice | currency: currency}} </p>
                    </div>
                </div>
            </div>
            <!-- <div class="restaInfo ">
                <div class="bxshadow1">
                    <div class="row cuponcodes" style="margin: 0px;">
                        <div class="col-xs-5">
                            <img src="../../../assets/images/sample.png" style="padding:5px 15px" />
                        </div>
                        <div class="col-xs-7">
                            <h3>Hello World</h3>
                            <p> Sample City, Duba </p>

                        </div>
                    </div>

                    <div class="deliveryby cuponcodes">
                        <p class="delItem">Delivery by <span class="paddL13"> Order On </span> </p>
                        <p class="noItem">30 May 2021 at 5:35 pm <span class="paddL13"> 29 May 2021 at 5:35 pm </span>
                        </p>
                    </div>
                    <div class="itemOrder cuponcodes">
                        <p class="noItem">Items </p>
                        <p class="noItem">1 X Goat meat 1 </p>
                    </div>


                    <div class="total">
                        <p class="noItem">Total Amount </p>
                        <p class="noItem"> $ 4567.00 </p>
                    </div>
                </div>
            </div> -->


            <!-- <div class="orderSta">

                <h4 class="font24"> Order Statues </h4>
                <p class="subtitle">Here Order Way </p>

                <div class="radioButtonsgroup">
                    <label class="radio-inline">
                        <input name="account_type" id="input-type-tutor" value="Tutor" type="radio" /> <b
                            class="font19"> Give Order </b>
                    </label>
                </div>
                <div class="radioButtonsgroup">
                    <label class="radio-inline">
                        <input name="account_type" id="input-type-tutor" value="Tutor" type="radio" /> <b
                            class="font19"> Provider Confirmed Your Order </b>
                    </label>
                </div>
                <div class="radioButtonsgroup">
                    <label class="radio-inline">
                        <input name="account_type" id="input-type-tutor" value="Tutor" type="radio" /> <b
                            class="font19"> Your Meat Preparing </b>
                    </label>
                </div>
                <div class="radioButtonsgroup">
                    <label class="radio-inline">
                        <input name="account_type" id="input-type-tutor" value="Tutor" type="radio" /> <b
                            class="font19"> Order Out of Delivery </b>
                    </label>
                </div>
                <div class="radioButtonsgroup">
                    <label class="radio-inline">
                        <input name="account_type" id="input-type-tutor" value="Tutor" type="radio" /> <b
                            class="font19"> On the Way </b>
                    </label>
                </div>
                <div class="radioButtonsgroup">
                    <label class="radio-inline">
                        <input name="account_type" id="input-type-tutor" value="Tutor" type="radio" /> <b
                            class="font19"> Delivered </b>
                    </label>
                </div>

            </div> -->
        </div>

        <app-footer-bar></app-footer-bar>
    <!-- </ng-scrollbar> -->
</div>