import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { HomeService } from 'src/app/services/home.service';

@Component({
  selector: 'app-join-with-easy-zaiha',
  templateUrl: './join-with-easy-zaiha.component.html',
  styleUrls: ['./join-with-easy-zaiha.component.scss']
})
export class JoinWithEasyZaihaComponent implements OnInit {

  constructor(private router: Router, public homeService: HomeService) { }

  ngOnInit(): void {
  }

  navToSupplier() {
    this.router.navigate(['register/supplier'])
  }

  navToDelivery() {
    this.router.navigate(['register/delivery'])

  }

}
