<!-- <p>careers works!</p> -->


<div class="mainHeader">
    <ng-scrollbar>
        <div class="top-banner-container">
            <img src="../../../assets/images/bg.png">
        </div>
        <div class="search-form-section">
            <div class="logo-s">
                <h4> We don’t accept job applications </h4>
                <p>
                    We only take applications through employee referrals. If you know someone at Zomato, reach out to
                    them
                    and share an eye-catching application (with a brief video, maybe?). Otherwise, if you have what it
                    takes, we will find you.
                </p>
            </div>
        </div>

        <div class="row">
            <div class="col-sm-5"><img src="../../assets/images/dlvr.png"
                    style="max-width: 100% !important;padding: 20px;">
            </div>
            <div class="col-sm-7 padd20">
                <div class="font24">
                    <span class="dark-bl f-b">Why</span> <span class="light-bl f-b"> Easy Zabiha </span>
                </div>
                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the
                    industry's standard dummy text ever since,Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since Lorem Ipsum
                    is
                    simply dummy text of the printing</p>
                <ul style="list-style-type:none;padding: 0px;">
                    <li><i class="fa fa-check" aria-hidden="true"></i>
                        Lorem Ipsum is simply dummy text of the printing.
                    </li>
                    <li><i class="fa fa-check" aria-hidden="true"></i>
                        Lorem Ipsum is simply dummy text of the printing.
                    </li>
                    <li><i class="fa fa-check" aria-hidden="true"></i>
                        Lorem Ipsum is simply dummy text of the printing.
                    </li>
                </ul>
            </div>
        </div>
        <app-explore-careers></app-explore-careers>
        
        <app-footer-bar></app-footer-bar>
    </ng-scrollbar>
</div>