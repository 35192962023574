<div class="mainHeader">
    <ng-scrollbar>
        <div class="container">
            <div class="site-search card" style="box-shadow: rgb(0 0 0 / 12%) 0px 4px 16px;">
                <div class="row">
                    <div class="col-xs-12 col-sm-6 col-md-6">
                        <div class="custom-search">
                            <div class="marker">
                                <img src="../../../assets/images/loca.png" style="float:left;">
                                <!-- <i class="fa fa-map-marker" aria-hidden="true" style="font-size: 30px;"></i> -->
                                <span class="MuiTypography-colorTextPrimary locationData"
                                    >{{homeService.userPinLocationAddress}}</span>
                            </div>
                        </div>
                    </div>
                    <div class="col-xs-12 col-sm-6 col-md-6 paddT7">
                        
                        <div class="custom-search">
                            <div class="searchIcon">
                                <i class="fa fa-search" aria-hidden="true"></i>
                            </div>

                            <input type="text" class="custom-search-input" [(ngModel)]="searchTerm" (keyup.enter)="findMosque()" />
                            <button class="custom-search-botton" type="submit" (click)="findMosque()"> Search </button>
                        </div>

                    </div>
                   

                </div>
            </div>
        </div>


        <div class="near_stores">
            <div class="container">
                <div class="row" >
                    <div class="col-xs-12 col-sm-6 col-md-6">
                        <div class="restTitle">
                            <!-- <h6 class="MuiTypography-h6" style="max-width: 100%;">Nearest sloters</h6> -->
                            <h6 class="MuiTypography-h6" style="max-width: 100%;">Select your preference</h6>

                        </div>
                    </div>
                    <div class="col-xs-12 col-sm-6 col-md-6 text-right restTitleRight" *ngIf="false">
                        <div class="btn-group btn-group-toggle" ngbRadioGroup name="radioBasic" [(ngModel)]="homeService.selectedOrderType">
                            <label ngbButtonLabel class="btn-success" *ngFor="let type of orderTypes">
                              <input ngbButton type="radio" [value]="type._id"> {{type.name}}
                            </label>
                            
                        </div>
                    </div>
                </div>
                <div style="border-top: 1px solid #ddd;margin: 0 60px;"></div>
                

                <div class="row restarent" *ngFor="let restarents of paginated;let i = index"
                    style="box-shadow: rgb(0 0 0 / 12%) 0px 4px 16px;" (click)="restarentDetails(restarents)">
                    <div class="col-xs-12 col-sm-6 col-md-4" style="padding:0px ;">
                        <img [src]="imagePathPrepend + restarents.image[0].path" class="restarentImg">
                        <span class="reating"> {{restarents.overallRating}} </span>
                        <div class="restImg">
                            <img [src]="imagePathPrepend + restarents.logo" class="ex-ico">
                        </div>
                    </div>
                    <div class="col-xs-12 col-sm-6 col-md-5">
                        <h4 class="restName font14">
                            {{restarents.name}}
                        </h4>
                        <!-- <span class="paddL35 font13"> {{restarents.address}} </span> -->
                    </div>
                    <div class="col-xs-12 col-sm-6 col-md-3">
                        <div class="restrentDel">
                            <div class="trunt">
                                <i class="fa fa-truck" aria-hidden="true" style="font-size: 30px;color: green;"></i>
                            </div>
                            <br />
                            <p class="paddingB5 font13">{{homeService.caluclateDeliveryCharges(restarents?.distanceInKm) | currency:currency}} Delivery</p>
                            <p class="paddingB5 font13">{{restarents?.deliveryNote}} </p>
                            <span class="font13">{{restarents.notes}}</span>
                        </div>
                    </div>
                </div>
                <div *ngIf="listofRestarents.length > pageSize" class="d-flex justify-content-between p-2 borderT2" style="border-top: 2px solid #ddd; margin: 2rem 3.8rem 0px;">
                    <ngb-pagination [collectionSize]="collectionSize" [(page)]="page" [pageSize]="pageSize"
                         class="textCenter"
                          (pageChange)="onPageChange($event)">
                    </ngb-pagination> 

                    <!-- <select class="custom-select" style="width: auto" [(ngModel)]="pageSize"
                        (ngModelChange)="getPremiumData();">
                        <option [ngValue]="2">2 Restarents </option>
                        <option [ngValue]="5">5 Restarents</option>
                        <option [ngValue]="10">10 Restarents</option>
                        <option [ngValue]="50">50 Restarents</option>
                        <option [ngValue]="100">100 Restarents</option>
                    </select> -->
                </div>
                <div *ngIf="listofRestarents.length == 0" class="hintPlaceholder text-center mb-5 mt-5">
                    No restaurants available.
                </div>
            </div>
        </div>



    </ng-scrollbar>
</div>


<!-- adding new html  -->

<!-- <table class="table table-striped table-hover">
    <thead>
        <tr>
            <th scope="col">SL No.</th>
            <th scope="col">Name</th>
            <th scope="col">Current Policy No.</th>
            <th scope="col">Existing Premium</th>
            <th scope="col">Proposed Premium</th>
            <th scope="col">Percentage Saving</th>
        </tr>
    </thead>
    <tbody>
        <tr *ngFor="let item of listofRestarents; let i = index">
            <th scope="row">{{i+1}}</th>
            <td>{{item.name}}</td>
        </tr>

    </tbody>
</table>

<div class="d-flex justify-content-between p-2">
    <ngb-pagination [collectionSize]="collectionSize" [(page)]="page" [pageSize]="pageSize"
        (pageChange)="getPremiumData();">
    </ngb-pagination>

    <select class="custom-select" style="width: auto" [(ngModel)]="pageSize" (ngModelChange)="getPremiumData();">
        <option [ngValue]="1">1 items per page</option>
        <option [ngValue]="2">2 items per page</option>
        <option [ngValue]="4">4 items per page</option>
        <option [ngValue]="6">6 items per page</option>
    </select>
</div> -->