<ng-template #rt let-r="result" let-t="term">
    <div class="autocomplete-res">
    <ngb-highlight [result]="r.formatted_address" [term]="t"></ngb-highlight>
    </div>
</ng-template>
<div>
    <div class="top-banner-container" >
        <img [src]="imagePathPrepend + section?.image?.path" onerror="this.src='assets/images/black.jpg'"  class="max-w-100">
    </div>
    <div class="search-form-section">
        <div class="logo-s">
            <h4 class="textHeading centered"> <span class="break-mobile"> {{section?.subheading}}</span></h4>
        </div> 

        <div class="usesType form-field centered" *ngIf="homeService.homeRoute.toLowerCase().indexOf('donat') == -1">
            <div class="donation-combobox">

                <input #placeSearch id="typeahead-http" type="text" class="mx-sm-3" [class.is-invalid]="searchFailed"
                 [ngbTypeahead]="search" placeholder="Enter delivery address" maxlength="500" 
                [resultTemplate]="rt" [inputFormatter]="formatter" (selectItem)="selectSearchItem($event)" />
                <div class="vertical-divider"></div>
                <div class="donate-now-button" (click)="setMapLOcation()">
                    Find
                </div>
            </div>
            <div class="mapRightFixed" *ngIf="showMap">
                <!-- <img src="assets/images/banners/map.jpg" style="width:100%"> -->
                <google-map width="100%" height="100%" 
                [center]="center"
                [zoom]="zoom"
                [options]="options">
                </google-map>

                <img src="assets/images/marker.png" class="mapMarkerCenter" />
                <div class="setMarkerLocation" (click)="setMapLOcation()">
                    Set Pin Location
                </div>
            </div>
        </div>

        <div class="usesType form-field centered" *ngIf="homeService.homeRoute.toLowerCase().indexOf('donat') !== -1">
            <div class="donation-combobox">

                <select class="donation-combobox-select" [(ngModel)]="donationcountry"> 
                    <option value="">Select country</option>
                    <option [value]="c.key" *ngFor="let c of donationCountrieslist | donationspipe">{{c.countryName}}</option>
                </select>
                <div class="vertical-divider"></div>
                <div class="donate-now-button" (click)="donateNow()">
                    Donate Now
                </div>
            </div>
        </div>

    </div>
</div>