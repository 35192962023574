<div class="mainHeader">

    <ng-scrollbar>

        <div class="container orders-container">
            <div class="row no-gutters orders-header">
                <div class="col mb-2 font16">
                    Order List
                </div>
                <div class="col-auto text-right font16">
                    <b class="theme-color">{{OrdersCount}} Order(s)</b>
                </div>
            </div>
            <div class="row">
                <div class="col-xs-12 col-sm-12 col-md-12">
                    <app-order *ngFor="let order of ordersList" [order]="order" (confirm)="confirmation($event)"></app-order>
                    <div class="hintPlaceholder" style="text-align: center;" *ngIf="ordersList.length==0&&!loading">
                        no orders placed yet.
                    </div>
                    <div class="hintPlaceholder" style="text-align: center;" *ngIf="loading">
                        Loading...
                    </div>
                </div>
            </div>
        </div>
        
    </ng-scrollbar>
</div>
<div class="modal fade" id="confirmModal" tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered " role="document">
      
      <div class="modal-content" style="border-radius: 10px;overflow: hidden;">
        <div class="modal-close-inside">
            <button type="button" class="closeButton" data-dismiss="modal" aria-label="Close" #closeBtn>
              <span aria-hidden="true" #closeBtn>
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 18 18"><path d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"/></svg>
              </span>
            </button>
          </div>
        <div class="modal-body specPopup" style="padding:0;">
            <div class="module-heading">
                <div class="productName">Select name whom completed his / her prayer.</div>
            </div>
            <div class="p-4 members">
                <div class="form-check"  *ngFor="let mem of members;let j=index">
                    <input type="checkbox" class="form-check-input" name="membersCheck" [id]="'mem'+j" [(ngModel)]="confirmMembers[j]" (change)="memberConfirmedCheck($event)">
                    <label class="form-check-label theme-color" [for]="'mem'+j">{{mem}}</label>
                </div>
            </div>
            <div>
                <button class="btn btn-success w-100" [disabled]="confirmDisabled" (click)="confirmPrayer()"> 
                    Confirm
                </button>
            </div>
        </div>
      </div>
    </div>
</div>