<div class="mainHeader">

    <ng-scrollbar>

        <div class="container orders-container">
            <div class="row no-gutters" style="margin: 10px auto;margin-bottom: 10px;">
                <div class="col mb-2 font16">
                    Register Supplier
                </div>
                
            </div>
            <div class="row">
                <div class="col-xs-12 col-sm-12 col-md-12">
                    <form [formGroup]="registerForm" (ngSubmit)="doRegister()" >
                        <div class="form-group">
                          <label class="f12lightgray mb-1"> First Name </label>
                          <input type="text" formControlName="first_name" class="form-control" placeholder="firstname"
                          [(ngModel)]="registerUser.first_name"
                            [ngClass]="{ 'is-invalid': registerFormSubmitted && registerEror.first_name.errors }" />
                          <div *ngIf="registerFormSubmitted && registerEror.first_name.errors" class="invalid-feedback">
                            <div *ngIf="registerEror.first_name.errors.required"> First Name is required</div>
                            <!-- <div *ngIf="f.email.errors.email">Email must be a valid email address</div> -->
                          </div>
                        </div>
            
                        <div class="form-group">
                          <label class="f12lightgray mb-1"> Last Name </label>
                          <input type="text" formControlName="last_name" class="form-control" placeholder="lastname"
                          [(ngModel)]="registerUser.last_name"
                            [ngClass]="{ 'is-invalid': registerFormSubmitted && registerEror.last_name.errors }" />
                          <div *ngIf="registerFormSubmitted && registerEror.last_name.errors" class="invalid-feedback">
                            <div *ngIf="registerEror.last_name.errors.required"> Last Name is required</div>
                            <!-- <div *ngIf="f.email.errors.email">Email must be a valid email address</div> -->
                          </div>
                        </div>
            
                        <div class="form-group">
                          <label class="f12lightgray mb-1">Email</label>
                          <input type="text" formControlName="register_email" class="form-control" placeholder="email"
                          [(ngModel)]="registerUser.email"
                            [ngClass]="{ 'is-invalid': registerFormSubmitted && registerEror.register_email.errors }" />
                          <div *ngIf="registerFormSubmitted && registerEror.register_email.errors" class="invalid-feedback">
                            <div *ngIf="registerEror.register_email.errors.required"> Email is required</div>
                            <div *ngIf="registerEror.register_email.errors.email">Email must be a valid email address</div>
                          </div>
                        </div>
            
                        <div class="form-group">
                          <label class="f12lightgray mb-1"> Password </label>
                          <input type="password" formControlName="register_pwd" class="form-control" placeholder="password"
                          [(ngModel)]="registerUser.password"
                            [ngClass]="{ 'is-invalid': registerFormSubmitted && registerEror.register_pwd.errors }" />
                          <div *ngIf="registerFormSubmitted && registerEror.register_pwd.errors" class="invalid-feedback">
                            <div *ngIf="registerEror.register_pwd.errors.required"> Password Name is required</div>
                            <div *ngIf="registerEror.register_pwd.errors.minlength"> Password length must be at least 6.</div>
            
                          </div>
                        </div>
            
                        <div class="form-group">
                          <label class="f12lightgray mb-1"> Mobile Number </label>
                          <div class="input-group mb-3">
                            <input type="tel" formControlName="mobile_number" class="form-control" placeholder="+1xxxxxxxx"
                            [(ngModel)]="registerUser.mobile_number"
                              [ngClass]="{ 'is-invalid': registerFormSubmitted && registerEror.mobile_number.errors }" />
                            <div class="input-group-append">
                              <button class="btn btn-outline-secondary font14" [disabled]="otpSent" type="button" (click)="mobileOTP()">Validate</button>
                            </div>
                          </div>
                          <div>
                            <input type="checkbox" name="smsAccept" id="smsAccept" checked > <span class="noteText">By continuing you will receive a one-time verification code to your phone number by SMS. Message and data rates may apply.</span>
                          </div>
                          <div *ngIf="registerFormSubmitted && registerEror.mobile_number.errors" class="invalid-feedback">
                            <div *ngIf="registerEror.mobile_number.errors.required"> Mobile Number Name is required</div>
                          </div>
                        </div>
            
                        <div  class="form-group" *ngIf="otpSent">
                          <label class="f12lightgray mb-1"> One time password </label>
                          <ng-otp-input #ngOtpInput (onInputChange)="onOtpChange($event)" [config]="{length:6, allowNumbersOnly: true}"></ng-otp-input>
                        </div>
            
                        <div class="form-group">
                          <button class="btn btn-success btn-block" type="submit" [disabled]="otp.length!=6"> Register </button>
                        </div>
                      </form>
                </div>
            </div>
        </div>
        
    </ng-scrollbar>
</div>
