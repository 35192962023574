<div class="eachOrder">
    <div class="row">
        <div class="col mb-2">
            <span class="f12lightgray">
                Booking Id: &nbsp;
            </span>
            <span class="pr-2 font13">{{order?.tracking_number}}</span>
        </div>
        <div class="col-auto text-right font14">
            <b *ngIf="!delivered" class="theme-color">Active Order</b>
            <div *ngIf="delivered"><i class="fa fa-check-circle-o font16 theme-color" aria-hidden="true" style="font-weight: 200;"></i>  Delivered</div>
        </div>
    </div>

    <div class="row no-gutters font14">
        <div class="col-auto">
            <img class="img-responsive order-logo" src="{{imagePathPrepend + order?.location?.logo}}" alt="" width="60" />
        </div>
        <div class="col align-self-center pl10">
            <div><b>{{order?.location?.name}}</b></div>
            <div class="f12lightgray">{{order?.location?.address}}</div>
        </div>
    </div>
    <div class="row no-gutters font14 mt-2">
        <div class="col-12">
            <!-- <span class="flabel"> </span><span>{{orderedItems}}</span> -->
            <div class="row no-gutters font14 mt-2">
                <div class="col-auto" style="padding-top:4px;">
                    <b>Items&nbsp;&nbsp;&nbsp;</b>
                </div>
                <div class="col">
                    <div *ngFor="let item of order?.order_items;let i=index" style="line-height: 30px;">
                        {{item.quantity}} X {{ item.name }} &nbsp;&nbsp;&nbsp;
                        <span *ngIf="isSpecialOrder">
                            <button *ngIf="!isConfirmed(item) && !isReadyToPick(item)" class="btn btn-success btn-sm mb-1" type="button" data-toggle="modal" data-target="#confirmModal" (click)="openConfirmModal(item)"> Prayer Confirmation </button>
                            <span *ngIf="isConfirmed(item) && !isReadyToPick(item)" class="mb-1 mob-confimed"><i class="fa fa-check-circle-o font16 theme-color" aria-hidden="true" style="font-weight: 200;"></i> Confirmed </span>
                        </span>
                        <!-- <span *ngIf="!isSpecialOrder">
                            <span *ngIf="isConfirmed(item) && !isReadyToPick(item)" class="mb-1 mob-confimed"><i class="fa fa-check-circle-o font16 theme-color" aria-hidden="true" style="font-weight: 200;"></i> Confirmed </span>
                        </span> -->
                        <!-- <button *ngIf="order?.pickup&& isReadyToDeliver(item)&&!isDelivered(item)" class="btn btn-success btn-sm mb-1" type="button" (click)="confirmPickedUp(item)"> Picked-up Confirmation </button> -->
                    </div>
                </div>
            </div>
            <div class="row no-gutters font14 mt-2">
                <div class="col-auto">
                    <b>Amount Paid&nbsp;&nbsp;&nbsp;</b>
                </div>
                <div class="col">
                    {{order.total | currency: currency}}
                </div>
            </div>
        </div>
        
    </div>
    <div class="mt-4"></div>
    
    <div *ngFor="let status of order?.order_status_history;let i=index">
        <div class="row no-gutters font14">
            <div class="col-auto p-1">
                <i class="fa fa-circle theme-color" aria-hidden="true" *ngIf="i!==orderedStatusHistoryLength-1||delivered"></i>
                <i class="fa fa-square-o" aria-hidden="true" *ngIf="i==orderedStatusHistoryLength-1&&!delivered"></i>
            </div>
            <div class="col-sm-auto col-11 p-1 pl-2 pr-2 flabel">
                {{status?.desc}}
            </div>
            <div class="col-sm col p-1 pl-2 pr-2 mob-pr-2">
                {{convertDate(status?.date)}}
            </div>
        </div>
        <div class="row no-gutters connectorRow" *ngIf="i!==orderedStatusHistoryLength-1">
            <div class="col-auto">
                <i class="fa fa-long-arrow-down theme-color" aria-hidden="true"></i>
            </div>
        </div>
    </div>
</div>
<!-- 
<div class="modal fade" id="confirmModal" tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered " role="document">
      
      <div class="modal-content" style="border-radius: 10px;overflow: hidden;">
        <div class="modal-close-inside">
            <button type="button" class="closeButton" data-dismiss="modal" aria-label="Close" #closeBtn>
              <span aria-hidden="true" #closeBtn>
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 18 18"><path d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"/></svg>
              </span>
            </button>
          </div>
        <div class="modal-body specPopup" style="padding:0;">
            <div>
                <button class="btn btn-success w-100" [disabled]="true" (click)="confirmPrayer()"> 
                    Confirm
                </button>
          </div>
        </div>
      </div>
    </div>
</div> -->