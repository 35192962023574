<div class="mainHeader">

    <ng-scrollbar>

        <div class="container profile-container">
            <div class="row no-gutters" style="margin-bottom: 10px;margin-top: 10px;">
                <div class="col mb-2 font16">
                    Profile
                </div>
                <div class="col-auto text-right font16">
                </div>
            </div>
            <div class="row">
                <div class="col-xs-12 col-sm-12 col-md-12">
                    <div class="form-group">
                        <label class="f12lightgray"> Full Name </label>
                        <input type="text" class="form-control" placeholder="Name" [(ngModel)]="req.name" maxlength="30"/>
                    </div>
                    <div class="form-group">
                        <label class="f12lightgray"> Email </label>
                        <input type="email" class="form-control" placeholder="email" [(ngModel)]="req.email" maxlength="50" />
                    </div>
                    <div class="form-group">
                        <label class="f12lightgray"> Mobile </label>
                        <input type="text" class="form-control" placeholder="Mobile" disabled [value]="homeService.loggedInUser?.user?.phone" />
                    </div>
                </div>
            </div>
            <br><br>
            <div class="mart30">
                <div class="form-group">
                    <button class="btn btn-success btn-block" type="button" (click)="save()"> Save </button>
                  </div>
            </div>
        </div>
        
    </ng-scrollbar>
</div>
