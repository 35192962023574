import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { faUser } from '@fortawesome/free-regular-svg-icons';
import { UserRes } from 'src/app/interfaces/user.res';
import { HomeService } from 'src/app/services/home.service';

@Component({
  selector: 'app-reset',
  templateUrl: './reset.component.html',
  styleUrls: ['./reset.component.scss']
})
export class ResetComponent implements OnInit {
  
  verifyId: string = '';
  password: string = '';
  verificationMsg: string = '';

  submitted: boolean = false;

  loginForm: FormGroup;

  constructor(public homeService: HomeService, private route: ActivatedRoute, private formBuilder: FormBuilder) { 
    this.route.params.subscribe(params => {
      this.verifyId = params['id'];
    });
  }
  ngOnInit(): void {
    this.loginForm = this.formBuilder.group({
      password: ['', [Validators.required, Validators.minLength(6)]],
    });


  }

  get submitError() {
    return this.loginForm.controls;
  }
  doSubmit() {
    this.submitted = true;
    this.homeService.userReset({id: this.verifyId, password: this.password}).toPromise().then(() => {
      this.verificationMsg = 'Password changed.';  
    }).catch((err) => {
      this.verificationMsg = 'Unable to change password.';
      
    })
  }
}
