<div class="easyzabia">
    <div class="row">
        <div class="col-xs-12 col-sm-6 col-md-6">
            <img src="../../assets/images/delivery.png" class="max-w-100">
        </div>
        <div class="col-xs-12 col-sm-6 col-md-6">
            <!-- <div class="col-xs-12 col-sm-6 col-md-5"> -->
            <h2 class="MuiTypography-h2 paddT5rem">
                <span class="dark-bl f-b">Why</span> <span class="light-bl f-b"> Easy Zabiha </span>
            </h2>
            <p class="font14 paddB1 MuiTypography-subtitle1">Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                Lorem Ipsum has been the
                industry's standard dummy text ever since,Lorem Ipsum is simply dummy text of the printing and
                typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since Lorem Ipsum is
                simply dummy text of the printing</p>
            <ul style="list-style-type:none;padding: 0px;">
                <li class="listInfo"><i class="fa fa-check" aria-hidden="true"></i>
                    Lorem Ipsum is simply dummy text of the printing.
                </li>
                <li class="listInfo"><i class="fa fa-check" aria-hidden="true"></i>
                    Lorem Ipsum is simply dummy text of the printing.
                </li>
                <li class="listInfo"><i class="fa fa-check" aria-hidden="true"></i>
                    Lorem Ipsum is simply dummy text of the printing.
                </li>
            </ul>
        </div>
    </div>
</div>