<!-- <p>about-us works!</p> -->

<div style="height: 100vh;padding-top: 100px;">
    <ng-scrollbar>
        <div>
            <div class="container">
                <div class="row single-product">
                    <div class="col-md-12">
                        <div class="ex-sec">
                            <div class="font24">
                                <span class="light-bl f-b"> Privacy </span><span class="dark-bl f-b">  Policy </span> 
                            </div>
                            <div class="ex-space" style="margin-bottom:10px;">
                                <p class="para_inner">Lorem Ipsum is simply dummy text of the printing and typesetting
                                    industry.
                                    Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when
                                    an unknown
                                    printer took a galley of type and scrambled it to make a type specimen book. It has
                                    survived not
                                    only five centuries, but also the leap into electronic typesetting, remaining
                                    essentially
                                    unchanged. It was popularised in the 1960s with the release of Letraset sheets
                                    containing Lorem
                                    Ipsum passages, and more recently with desktop publishing software like Aldus
                                    PageMaker
                                    including versions of Lorem Ipsum.</p>

                                <p><strong> Zabiha </strong></p>
                                <p class="para_inner">It is a long established fact that a reader will be distracted by
                                    the readable
                                    content of a page when looking at its layout. The point of using Lorem Ipsum is that
                                    it has a
                                    more-or-less normal distribution of letters, as opposed to using 'Content here,
                                    content here',
                                    making it look like readable English. Many desktop publishing packages and web page
                                    editors now
                                    use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will
                                    uncover many
                                    web sites still in their infancy. Various versions have evolved over the years,
                                    sometimes by
                                    accident, sometimes on purpose (injected humour and the like).</p>
                                <p class="para_inner">What's more, with our year-round shopping festivals and events,
                                    our prices are
                                    irresistible. We're sure you'll find yourself picking up more than what you had in
                                    mind. </p>

                                <p><strong> Where does it come from? </strong></p>
                                <p class="para_inner">
                                    Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a
                                    piece of
                                    classical Latin literature from 45 BC, making it over 2000 years old. Richard
                                    McClintock, a
                                    Latin professor at Hampden-Sydney College in Virginia, looked up one of the more
                                    obscure Latin
                                    words, consectetur, from a Lorem Ipsum passage, and going through the cites of the
                                    word in
                                    classical literature, discovered the undoubtable source. Lorem Ipsum comes from
                                    sections 1.10.32
                                    and 1.10.33 of "de Finibus Bonorum et Malorum" (The Extremes of Good and Evil) by
                                    Cicero,
                                    written in 45 BC. This book is a treatise on the theory of ethics, very popular
                                    during the
                                    Renaissance. The first line of Lorem Ipsum, "Lorem ipsum dolor sit amet..", comes
                                    from a line in
                                    section 1.10.32.
                                </p>
                                <ul>
                                    <li>Lorem ipsum, or lipsum as it is sometimes known, is dummy text used in laying
                                        out print,
                                        graphic or web designs.</li>
                                    <li>The purpose of lorem ipsum is to create a natural looking block of text
                                        (sentence,
                                        paragraph, page, etc.) that doesn't distract from the layout.</li>
                                    <li> Use our generator to get your own, or read on for the authoritative history of
                                        lorem ipsum.
                                    </li>
                                    <li> As Cicero would put it, “Um, not so fast.” </li>
                                    <li> Rrow itself, let it be sorrow; let him love it; let him pursue it, ishing for
                                        its
                                        acquisitiendum. </li>
                                    <li> Among design professionals, there's a bit of controversy surrounding the filler
                                        text.</li>
                                </ul>

                                <br>
                                <p><strong>Where can I get some : </strong></p>
                                <ul>
                                    <li> On the other hand, we denounce with righteous indignation and dislike men who
                                        are so
                                        beguiled and demoralized by the charms of pleasure of the moment. </li>
                                    <li> These cases are perfectly simple and easy to distinguish </li>
                                    <li> Order Status: Track &amp; find the status of your orders is possible by easy
                                        log-in on
                                        multiple devices at same time</li>

                                    <li> principle of selection: he rejects pleasures to secure other greater pleasures,
                                        or else he
                                        endures pains to avoid worse pains."</li>
                                </ul>



                            </div>
                        </div>
                    </div>
                    <!-- /.col -->
                </div>
            </div>
        </div>
    </ng-scrollbar>
</div>