import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Constants } from 'src/app/config/constants';
import { HomeService } from 'src/app/services/home.service';

@Component({
  selector: 'app-tabs',
  templateUrl: './tabs.component.html',
  styleUrls: ['./tabs.component.scss'],
})
export class TabsComponent implements OnInit {
  selectedTab = '';
  orderTypes: any[];
  imagePathPrepend: string = Constants.REST_API_SERVER;

  constructor(private route: ActivatedRoute, private homeService: HomeService) {
    console.log(route.snapshot);
    homeService.getOrderType().subscribe(
      (resp: any) => {
        this.orderTypes = resp.filter((r: any) => r.active).reverse();
        this.selectedTab = this.orderTypes[0].name;
        this.homeService.homeRoute = this.orderTypes[0].name;
        this.homeService.selectedOrderType = this.orderTypes[0]._id;
        this.setOrderType();
      },
      (error) => {
        console.log('Error Occured Here', error);
      }
    );
  }

  ngOnInit(): void {
    // this.homeService.homeRoute = 'eid';
    // this.router.url.subscribe((url) => {
    //   console.log(url);
    //   if (url[0].path == '/') this.selectedTab = 'eid';
    //   else if (url[0].path == '/regular') this.selectedTab = 'regular';
    //   else if (url[0].path == '/donation') this.selectedTab = 'donation';
    // });
  }

  tabChange(e: any): void {
    this.selectedTab = e;
    this.homeService.homeRoute = e;
    this.homeService.pageChangedTrigger(e);
    this.setOrderType();
  }

  getRouterLink(link: string) {
    return `/`;
  }
  setOrderType() {
    let fo = this.orderTypes.filter((o: any) => {
      let oname: string = o['name'];
      if (oname.indexOf(this.selectedTab) > -1) {
        return true;
      }
      return false;
    });

    console.log('name', fo);
    this.homeService.selectedOrderType = fo ? fo[0]._id : '';
    localStorage.setItem('selectedOrderType', fo[0].name);
  }
}
