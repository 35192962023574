import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { Constants } from 'src/app/config/constants';


@Component({
  selector: 'app-downloadapp',
  templateUrl: './downloadapp.component.html',
  styleUrls: ['./downloadapp.component.scss']
})
export class DownloadappComponent implements OnInit {
  @Input() pageSection3: any = {};
  // @Input() parentMessage: string;
  imagePathPrepend: string = Constants.REST_API_SERVER;
  siva: any = [];
  to: any = {};
  headingName: any;
  constructor() {

  }

  ngOnInit(): void {
    
  }

  ngOnChanges(changes: SimpleChanges) {

    console.log("gettin object information",this.pageSection3);
    // console.log('OnChanges');
    // console.log(JSON.stringify(changes));

    // tslint:disable-next-line:forin
    // for (const propName in changes) {
    //   const change = changes[propName];
    //   console.log("ggetting data", change);
    //   const to = change.currentValue;
    //   this.siva = to;
    //   // const from = JSON.stringify(change.previousValue);
    //   // const changeLog = `${propName}: to ${to} `;
    //   console.log("=======", this.to);

    //   let tempObj = {};
    //   tempObj = this.to;

    //   this.siva = JSON.stringify(this.to);

    //   this.headingName = this.to.heading;
    //   // this.siva.push(changeLog);
    //   // console.log("=======", changeLog);
    // }
  }

}
