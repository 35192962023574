import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { faSearch, faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Constants } from 'src/app/config/constants';
import { HomeService } from 'src/app/services/home.service';
import { SearchResult } from '../../interfaces/search-result';
import { City } from '../../interfaces/city';
import { GoogleMap, MapMarker } from '@angular/google-maps';

@Component({
  selector: 'app-locations',
  templateUrl: './locations.component.html',
  styleUrls: ['./locations.component.scss']
})
export class LocationsComponent implements OnInit {

  faSearch = faSearch;
  faMapMarkedAlt = faMapMarkerAlt;
  destroy$: Subject<boolean> = new Subject<boolean>();
  locationId: string = '';
  searchResults: SearchResult[] = [];

  cityInfo: City = {
    name: '',
    _id: '',
    active: false,
    country: {
      name: '',
      _id: '',
      active: false
    }
  };
  imagePathPrepend: string = Constants.REST_API_SERVER;

  //Maps
  zoom = 12
  center: google.maps.LatLngLiteral = {lat: -34, lng: 151};
  options: google.maps.MapOptions = {
    mapTypeId: 'roadmap',
    zoomControl: true,
    scrollwheel: false,
    disableDoubleClickZoom: true,
    maxZoom: 20,
    minZoom: 10,
  }
  @ViewChild(GoogleMap, { static: false }) map: GoogleMap | undefined
  markers: any[] = [];
  
  constructor(private route: ActivatedRoute,
    private homeService: HomeService,
    private router: Router) { }

  ngOnInit(): void {
    navigator.geolocation.getCurrentPosition((position) => {
      this.center = {
        lat: position.coords.latitude,
        lng: position.coords.longitude,
      }
    })
    this.route.params.subscribe(params => {
      this.locationId = params['id'];
      this.homeService.getCityInfo(this.locationId).pipe(takeUntil(this.destroy$)).subscribe((data: City)=>{
        this.cityInfo = data;
      })
      this.homeService.getLocationsBasedOnCity(this.locationId).pipe(takeUntil(this.destroy$)).subscribe((data: SearchResult[])=>{
        this.searchResults = data;
        this.markers = this.searchResults.map(sr => {
          let marker = {
            _id: sr._id,
          position: {
            lat: sr.lat,
            lng: sr.lng,
          },
          label: {
            color: 'white',
            text: sr.name,
          },
          title: sr.name,
          options: { animation: google.maps.Animation.DROP, icon: 'https://developers.google.com/maps/documentation/javascript/examples/full/images/beachflag.png' }
        }
        return marker;
        })
      })
    })
  }

  onChangeLocation = () => {
    this.router.navigate([''])
  }

  setCenter = (item: SearchResult) => {
    this.center = {
      lat: item.lat,
      lng: item.lng,
    }
  }

  markerClick = (marker: SearchResult) => {
    console.log(marker)
    this.router.navigate([`location/${marker._id}`]);
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    // Unsubscribe from the subject
    this.destroy$.unsubscribe();
  }

}
