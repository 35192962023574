<div class="mainHeader">


    <ng-scrollbar>
        <div class="top-banner-container">
            <img src="{{imagePathPrepend + restDetails?.image[0].path}}" onerror="this.src='assets/images/black.jpg'" class="banner">
        </div>

        <div id="site" class="restarent-information">
            <div class="restarent-middle">
                <div class="container">
                    <div class="row">
                        <div class="col-xs-12 col-sm-6 col-md-9 nopadding">
                            <div class="restlogo">
                                <img src="{{imagePathPrepend + restDetails?.logo}}" onerror="this.src='assets/images/black.jpg'" class="ex-ico">
                            </div>
                            <div class="titleBlock">
                                <div class="titleHeading">
                                    <h3 class="mainTitle"> {{restDetails?.name}} </h3>
                                </div>
                                
                                <div class="infoBlock">
                                    <div class="font13">{{restDetails?.address}}</div>
                                    <div>|</div>
                                    <div class="trunt">
                                        <i class="fa fa-truck" aria-hidden="true" style="font-size: 30px;color: green;"></i>
                                    </div>
                                    <div class="font13">{{restDetails?.deliveryNote}}</div>
                                    <div>|</div>
                                    <div class="font13">{{restDetails?.notes}}</div>
                                </div>
                                    
                            </div>
                        </div>
                        
                        <div class="col-xs-12 col-sm-6 col-md-3">
                            <div class="custom-search">
                                <div class="searchIcon" type="submit">
                                    <i class="fa fa-search" aria-hidden="true"></i>
                                </div>
                                <input type="text" class="custom-search-input" placeholder="Item name" [(ngModel)]="searchProduct">
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>


        <div id="site" class="restarent-items">
            <div class="restarent-items-infor ">
                <div class="container">
                    <div class="row">
                        <div class="col-xs-12 col-sm-6 col-md-2 nopadding">
                            <div class="nopadding1">
                                <ul class="categoryList">
                                    <li class="categoryItem"
                                        (click)="onSelect('all')" [ngClass]="{'active': selectedCategory == 'all'}">
                                        All </li>
                                    <li class="categoryItem" *ngFor="let category of categories;let i=index"
                                        (click)="onSelect(category.name)" [ngClass]="{'active': selectedCategory == category.name}">
                                        {{category.name}} </li>

                                </ul>

                            </div>
                        </div>

                        <!-- <button class="btn btn-danger" >ajklsdf</button> -->
                        <div class="col-xs-12 col-sm-6 col-md-7 nopadding1">

                            <div class="">
                                <div class="container">
                                    <div class="row restaurantProduct"
                                        *ngFor="let restarentsList of list_restarents | productsPipe:selectedCategory:searchProduct">
                                        <div class="col-xs-12 col-sm-6 col-md-9 nopadding flexRow">
                                            <div class="module-body" *ngIf="restarentsList.product.images.length > 0">
                                                <img src="{{imagePathPrepend + restarentsList.product.images[0].path}}" alt="imagesDisplayed"
                                                    class="restImges">
                                            </div>
                                            <div class="module-heading">
                                                <div class="productName">
                                                    {{restarentsList.product.name}} 
                                                    
                                                </div>
                                                <div class="productDesc" [innerHTML]="restarentsList.product.description"></div>
                                                <div class="show-more-less" *ngIf="restarentsList.product.description.length > 35" (click)="toggleRead($event)">
                                                    more
                                                </div>
                                                <div class="productPriceBlck"> {{restarentsList.price * ((100 - restarentsList.discount) / 100 ) | currency: currency}} <del *ngIf="restarentsList.discount>0">{{restarentsList.price  | currency: currency}}</del> <span *ngIf="restarentsList.discount>0">({{restarentsList.discount}}% Off)</span></div>
                                            </div>
                                        </div>
                                        <div  class="col-xs-12 col-sm-6 col-md-3 nopadding" *ngIf="orderType == 'AQIQAH' || orderType == 'EID'">
                                            <span class="addButton"
                                                        (click)="onProductSelect(restarentsList)" data-toggle="modal" data-target="#specModal"
                                                        *ngIf="restarentsList.stock>0"
                                                        >
                                                        <!-- notInCartList(restarentsList)&& -->
                                                        Add </span>
                                            <span *ngIf="restarentsList.stock<=0" class="outofstock">
                                                Out of stock
                                            </span>
                                        </div>
                                        <div  class="col-xs-12 col-sm-6 col-md-3 nopadding" *ngIf="orderType != 'AQIQAH' && orderType != 'EID'">
                                            <span class="addButton"
                                                        (click)="onProductSelect(restarentsList)" data-toggle="modal" data-target="#specModal"
                                                        *ngIf="notInCartList(restarentsList)&&restarentsList.stock>0"
                                                        >
                                                        <!-- notInCartList(restarentsList)&& -->
                                                        Add </span>
                                            <span class="addButton" style="margin-bottom: 10px;background-color: #eee;"
                                                        *ngIf="!notInCartList(restarentsList)&&restarentsList.stock>0"
                                                        >
                                                        Added </span>
                                            <input integerInput *ngIf="!notInCartList(restarentsList)" (keypress)="quantityChangeInput($event)"  (change)="quantityChange($event, restarentsList.product._id)" class="addButton" style="width: 100px;" type="number" [value]="getQuantity(restarentsList.product._id)" min="1" max="999" />
                                            <span *ngIf="restarentsList.stock<=0" class="outofstock">
                                                Out of stock
                                            </span>
                                        </div>
                                    </div>
                                    <div class="row restaurantProduct"
                                        *ngIf="(list_restarents | productsPipe:selectedCategory:searchProduct).length == 0">
                                        <div class="col-xs-12 col-sm-6 col-md-12 nopadding flexRow">
                                            <div class="hintPlaceholder">
                                                No products available.
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                
                            </div>
                        </div>

                        <div class="col-xs-12 col-sm-6 col-md-3 nopadding">
                            <div class="card bxshadow marT10 height200">
                                <div class="padd10">
                                    <div class="emtyCart" *ngIf="homeService.cartLoctionProducts.length ==0">
                                        <div class="emptyImg">
                                            <img src="../../../assets/images/emptycart.png" class="cartEmptyImg">
                                        </div>

                                        <div class="cartEmptyLabel">
                                            No products in cart.
                                        </div>

                                    </div>
                                    <div class="cart" *ngIf="homeService.cartLoctionProducts.length != 0">
                                        <h4 class="oreder">
                                            <img src="../../../assets/images/bag.png"> Order cart
                                        </h4>
                                        <div class="orderItems">
                                            <div *ngFor="let price of homeService.cartLoctionProducts;let i = index">
                                                <div class="orderItem">
                                                    <span class="item-quantity" style="margin-right: 10px;">{{price.quantity ? price.quantity : 1}} X </span>
                                                    {{price.product.name}} 
                                                    <span style="float: right;color: red; padding: 0 10px;font-size: 16px;margin-top: -3px;" (click)="remove(i)"> <i class="fa fa-times-circle" aria-hidden="true"></i> </span>
                                                    <span style="float: right;"> {{discountedPrice(price)| currency:currency}}</span>
                                                    <!-- <div class="item-ans">
                                                        <div *ngFor="let prod of price.product.specs">{{prod.ans}}</div>
                                                    </div> -->
                                                </div>
                                                
                                            </div>
                                        </div>
                                        <button class="btn btn-success w-100" style="margin-top: 5rem;" (click)="proceedToSummary()"> Proceed <br /> 
                                            <span style="font-size: 10px;color: #ddd;">Sub Total: {{subTotal() | currency:currency}} </span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <app-footer-bar></app-footer-bar>
    </ng-scrollbar>
</div>

<div class="modal fade" id="specModal" tabindex="-1" role="dialog" 
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered " role="document">
      
      <div class="modal-content" style="border-radius: 10px;overflow: hidden;">
        <div class="modal-close-inside">
            <button type="button" class="closeButton" data-dismiss="modal" aria-label="Close" #closeBtn>
              <span aria-hidden="true" #closeBtn>
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 18 18"><path d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"/></svg>
              </span>
            </button>
          </div>
        <div class="modal-body specPopup" style="padding:0;">
            <div *ngIf="selectedProduct">
          <div class="module-heading">
            <div class="productName">{{selectedProduct.product.name}}</div>
            <div class="productDesc" [innerHTML]="selectedProduct.product.description"></div>
        </div>
            <div *ngFor="let spec of selectedProduct.product.specs; let i=index" class="questionBlock">
                <div class="row question">
                    <div class="col">{{i+1}}. {{spec.question}}</div>
                    <div class="col-auto required">{{spec.isMust=='on'?'Required':''}}</div>
                </div>
                <div class="options" *ngIf="spec.type=='text'">
                    <div class="form-group">
                        <input type="email" class="form-control" placeholder="Your answer" [(ngModel)]="spec.ans" maxlength="100">
                    </div>
                </div>
                <div class="options" *ngIf="spec.type=='single'">
                    <div class="form-check" *ngFor="let value of spec.values.split(',')">
                        <input class="form-check-input" type="radio" [name]="'q'+i" [value]="value" [(ngModel)]="spec.ans">
                        <label class="form-check-label" [for]="i">
                          {{value}}
                        </label>
                    </div>
                </div>

                <div class="options" *ngIf="spec.type=='multi'">
                    <div class="form-check" *ngFor="let value of spec.values.split(',');let j=index">
                        <input class="form-check-input" type="checkbox" [name]="'q'+i" [value]="value" [(ngModel)]="spec.ans[j]">
                        <label class="form-check-label" [for]="i">
                          {{value}}
                        </label>
                    </div>
                </div>
            </div>
            <div *ngIf="orderType=='AQIQAH'||orderType=='EID'" class="aquiqaMembers">
                <div class="row" *ngFor="let member of membersList; let i=index">
                        <div class="col-4 align-self-center hintLabel">
                            Member {{i+1}}
                        </div>
                        <div class="col-8 align-self-center">
                            <div class="form-group m-10">
                                <input type="text" name={{i}} maxlength="20" class="form-control" placeholder="Full name" [(ngModel)]="member.name">
                            </div>
                        </div>
                    
                </div>
            </div>
            <button class="btn btn-success w-100" [disabled]="isDisabled()" (click)="orderRestarent(selectedProduct)"> 
                Add to Cart
            </button>
          </div>
        </div>
      </div>
    </div>
</div>