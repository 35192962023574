import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import * as moment from 'moment';
import { Constants } from 'src/app/config/constants';
import { OrderItem } from 'src/app/interfaces/order-item';
import { OrderRes } from 'src/app/interfaces/order-res';
import { HomeService } from 'src/app/services/home.service';

@Component({
  selector: 'app-order',
  templateUrl: './order.component.html',
  styleUrls: ['./order.component.scss']
})
export class OrderComponent implements OnInit {

  @Input('order') order: OrderRes;
  @Output() confirm = new EventEmitter<OrderItem>();
  orderedItems: string = '';
  orderedStatusHistoryLength: number = 1;
  delivered = false;
  isSpecialOrder = false; // orderType=='AQIQAH'||orderType=='EID
  currency = 'USD'
  imagePathPrepend: string = Constants.REST_API_SERVER;
  constructor(private homeService: HomeService) { }

  ngOnInit(): void {

    this.homeService.userPinLocationCountry = localStorage.getItem('userPinLocationCountry');
    // let country:string = this.homeService.userPinLocationCountry;
    // this.currency = Constants.CURRENCY[country]
    this.currency = (this.order.payment['currency']).toUpperCase()
    this.orderedItems = this.order.order_items.map(oi => oi.quantity + ' x ' + oi.name).join(', ');
    this.orderedStatusHistoryLength = this.order.order_status_history.length;
    this.delivered = this.order.order_items.filter(oi => oi.currentStatus == 'DELIVERED').length == this.order.order_items.length;
    if(this.order.orderType=='AQIQAH'||this.order.orderType=='EID') this.isSpecialOrder = true;
  }

  convertDate(date: string) {
    return moment(date).format('llll')
  }

  isConfirmed(item: OrderItem) {
    // debugger;
    return item.orderItemStatuses ? item.orderItemStatuses.findIndex(o => o.status == 'IN_PROGRESS') > -1 : false
  }

  isReadyToPick(item: OrderItem) {
    return item.orderItemStatuses ? item.orderItemStatuses.findIndex(o => o.status == 'READY_TO_PICK') > -1 : false
  }

  openConfirmModal(item: OrderItem) {
    this.confirm.emit(item)
  }

  

}
