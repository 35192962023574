<div  class="mainHeader" style="height: 100vh;padding-top: 100px;background-color: #faf4f4 !important;">
    <ng-scrollbar>
        <div class="container">
            <div class="row single-product">
                <div class="col-md-12">
                    <div class="ex-sec">
                        <div class="font24">
                            <span class="dark-bl f-b"> Contact </span> <span class="light-bl f-b"> Us </span>
                        </div>
                    </div>
                    <form [formGroup]="contactForm" (ngSubmit)="formSbmitted()">
                        <div class="panel-body">
                            <div class="form-group">
                                <label>Name</label>
                                <input type="text" name="name" [(ngModel)]="account.name" class="form-control"
                                    placeholder="Enter Name" id="name" formControlName="name" maxlength="20"
                                    [ngClass]="{ 'is-invalid': submitted && f.name.errors }" required="required">

                                <div *ngIf="submitted && f.name.errors" class="invalid-feedback">
                                    <div *ngIf="f.name.errors.required"> Name is required</div>
                                    <div *ngIf="f.name.errors.maxlength"> Name max length 20.</div>
                        
                                </div>
                            </div>
                            <div class="form-group">
                                <label>Mail Id</label>
                                <input type="email" class="form-control" name="email" id="email"
                                    placeholder="Enter mail Id" formControlName="email" [(ngModel)]="account.email"
                                    [ngClass]="{ 'is-invalid': submitted && f.email.errors }" required="required">

                                <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                                    <div *ngIf="f.email.errors.required"> Email is required</div>
                                      <div *ngIf="f.email.errors.email">Email must be a valid email address</div>
                                </div>
                            </div>
                            <div class="form-group">
                                <label for="contact_no">Mobile Number</label>
                                <input type="tel" class="form-control" id="contact_no" name="contact_no"
                                    formControlName="mobile_number" [(ngModel)]="account.mobile_number"
                                    placeholder="Mobile Number" maxlength="10" value="" required="required"
                                    [ngClass]="{ 'is-invalid': submitted && f.mobile_number.errors }">
                                <div *ngIf="submitted && f.mobile_number.errors" class="invalid-feedback">
                                    <div *ngIf="f.mobile_number.errors.required"> Mobile Number is required</div>
                                </div>
                            </div>
                            <div class="form-group">
                                <label>Message</label>
                                <textarea name="message" id="message" class="form-control" rows="3"
                                    formControlName="message" [(ngModel)]="account.message" required="required" maxlength="100"
                                    [ngClass]="{ 'is-invalid': submitted && f.message.errors }"></textarea>
                                <div *ngIf="submitted && f.message.errors" class="invalid-feedback">
                                    <div *ngIf="f.message.errors.required"> Message is required</div>
                                    <div *ngIf="f.message.errors.maxlength"> Message max length 20.</div>
                                </div>
                            </div>
                            <!-- <div class="modal-close-outside">
        <button type="button" class="close primary-color" data-dismiss="modal" aria-label="Close" #closeBtn>
          <span aria-hidden="true" #closeBtn>&times;</span>
        </button>
      </div> -->
                            <button type="submit" class="btn btn-success btn-submit" role="button">Submit</button>

                        </div>
                    </form>
                </div>
            </div>
        </div>
    </ng-scrollbar>
</div>