import { Component, Input, OnInit } from '@angular/core';
import { Constants } from 'src/app/config/constants';
import { PageSection } from 'src/app/interfaces/page-section';
import { Observable, of, OperatorFunction } from 'rxjs';
import { catchError, concatAll, concatMap, debounceTime, distinctUntilChanged, map, filter, switchMap, tap } from 'rxjs/operators';
import { SearchSection } from 'src/app/interfaces/search-section';
import { NgbTypeaheadSelectItemEvent } from '@ng-bootstrap/ng-bootstrap';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { HomeService } from 'src/app/services/home.service';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { NgbPaginationConfig } from '@ng-bootstrap/ng-bootstrap';
import { City } from 'src/app/interfaces/city';

@Component({
  selector: 'app-search-restaurant',
  templateUrl: './search-restaurant.component.html',
  styleUrls: ['./search-restaurant.component.scss'],
  providers: [NgbPaginationConfig]
})
export class SearchRestaurantComponent implements OnInit {
  @Input() section: PageSection;
  imagePathPrepend: string = Constants.REST_API_SERVER;
  faSearch = faSearch;

  name = 'Angular';

  searchTerm: any;
  searching = false;
  searchFailed = false;
  listofRestarents: any = [];
  paginated: any = [];
  premiumData: any[] = [];
  source$: Observable<any>;
  page = 1;
  pageSize = 10;
  collectionSize = 0;
  searchList: any = [];
  orderTypes: any;
  
  showSort: boolean;
  filter_source_type_value: string;
  cityId = '';
  location = '';
  currency: string = 'USD';

  geoCoder: google.maps.Geocoder = new google.maps.Geocoder();


  // key = 'name'; // sort default by name
  // ascSort:boolean = false;

  constructor(public homeService: HomeService,
    config: NgbPaginationConfig,
    private route: ActivatedRoute,
    private router: Router) {

      this.route.params.subscribe(params => {
        this.cityId = params['id'];
        this.location = params['location'];
        let country:string = this.homeService.userPinLocationCountry;
        this.currency = Constants.CURRENCY[country]
        let lat = this.location.split(',')[0];
        let lng = this.location.split(',')[1];
        if(lat == undefined || lng == undefined) {
          alert("Something went wrong");return;
        }
        this.homeService.userPinLocation = {lat,lng}
        homeService.getCityInfo(this.cityId).toPromise().then((cityInfo: City) => {
          this.homeService.userPinLocationAddress = cityInfo.name + ', ' + cityInfo.country.name;
        })
      });
      this.homeService.getHomeSettings().subscribe((d) => {
        this.homeService.appSettings = d;
      });

      if(this.homeService.userPinLocation) {
        this.geoCoder.geocode({
          location: this.homeService.userPinLocation
        }, ((results: google.maps.GeocoderResult[], status: google.maps.GeocoderStatus) => {
          if (status === google.maps.GeocoderStatus.OK) {
            this.homeService.userPinLocationAddress = results[0].formatted_address;
            let addr = results[0].address_components;
          let pincode = addr.find(a => a['types'].includes("postal_code"))?.long_name;
          let country = addr.find(a => a['types'].includes("country"))?.long_name;
          let city = addr.find(a => a['types'].includes("administrative_area_level_2"))?.long_name;

          this.homeService.userAddressComponent = {
            city,country,pincode
          }
          } else {
              console.log(
                  'Geocoding service: geocode was not successful for the following reason: '
                  + status
              );
          }
        }))
      }

      homeService.getOrderType().subscribe((resp: any) => {
        this.orderTypes = resp.filter((r:any) => r.active).reverse();
        // this.homeService.selectedOrderType = this.orderTypes[0]._id
      }, (error) => {
        console.log("Error Occured Here", error)
      })
  }




  ngOnInit(): void {
    this.getRestarentsList();
    // this.getPremiumPolicyData();
    // this.source$.subscribe();
    // this.collectionSize = this.listofRestarents.length;
    // this.getPremiumData();
  }


  getRestarentsList(term = '') {
    // if(term == '') {
      this.homeService.getCityBasedRestarents(this.cityId, term , this.location).subscribe((resp: any) => {
        this.listofRestarents = resp.docs.filter((d:any) => d.active == true && d.deliverable == true);
        this.listofRestarents.map((l:any) => {
          let distanceInKm = this.homeService.calcDistanceKm({
            lat: l?.lat,
            lng: l?.lng
          }, this.homeService.userPinLocation);
  
          l['distanceInKm'] = distanceInKm;
          return l;
        })
        this.paginated = this.listofRestarents.slice( (this.page-1) * this.pageSize, (this.page) * this.pageSize);
        this.collectionSize = this.listofRestarents.length;
      }, (error) => {
        console.error("Error Occured Here", error);
      });
    // } else {

    // }
  }

  // sortByOrder(key: any) {
  //   console.log("getting key data",key)
  //   this.key = key;
  //   this.ascSort = !this.ascSort;
  // }



  // navigation to search restarents
  restarentDetails(restarentsDetails: any) {
    let type = this.orderTypes.find((o:any) => o._id == this.homeService.selectedOrderType)?.name;
    localStorage.setItem('selectedOrderType', type);
    this.router.navigate([`restaurants/${restarentsDetails._id}/${type}`]);
  }




  findMosque = () => {
    this.getRestarentsList(this.searchTerm);
  }

  onPageChange = (e:any) => {
    this.paginated = this.listofRestarents.slice( (e-1) * this.pageSize, (e) * this.pageSize);

  }

 
}
