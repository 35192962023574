import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HomeService } from 'src/app/services/home.service';
import { SearchResult } from '../../interfaces/search-result';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { Category } from 'src/app/interfaces/category';
import { Constants } from 'src/app/config/constants';
import { LocationProduct } from 'src/app/interfaces/location-product';
import { LocationProductRes } from 'src/app/interfaces/location-product-res';
import { Product } from 'src/app/interfaces/product';
import { ToastrService } from 'ngx-toastr';
@Component({
  selector: 'app-list-of-restaurant',
  templateUrl: './list-of-restaurant.component.html',
  styleUrls: ['./list-of-restaurant.component.scss']
})
export class ListOfRestaurantComponent implements OnInit {

  @ViewChild('closeBtn') closeBtn: ElementRef;
  locationId: string = '';
  locationInfo: SearchResult | undefined;
  destroy$: Subject<boolean> = new Subject<boolean>();
  destroy2$: Subject<boolean> = new Subject<boolean>();
  productCategories: Category[] = [];
  productTopLevelCategories: Category[] = [];
  subCategories: Category[] = [];
  list_restarents: LocationProduct[] = [];
  selectedProduct: LocationProduct;

  restDetails: any;
  imagePathPrepend: string = Constants.REST_API_SERVER;

  emptyCart: boolean = false;
  // public sampleArray: any = [
  //   { id: 1, weight: "2kg", price: "$10", name: "Goat 1", qty: "1" },
  //   { id: 1, weight: "2kg", price: "$10", name: "Goat 1", qty: "1" },
  //   { id: 1, weight: "2kg", price: "$10", name: "Goat 1", qty: "1" },
  // ]

  sampleArray = [
    {
      'name': 'goat',
      'qty': 1,
      'price': 10,
      'weight': "2kg",
    }
  ]

  priceData: any[] = [];
  food: any;
  categories: Category[] = [];
  selectedCategory = 'all';
  searchProduct: '';
  imagesArray: any;
  images: any;
  objectKeys: any;
  gettingUserId: any;
  filtered: any;

  // orderType: string = 'REGULAR TYPE';
  orderType: string = "Donate to EasyZabiha";

  
  membersList: any[] = [];
  currency = 'USD';

  constructor(public router: Router, private route: ActivatedRoute, public homeService: HomeService,
    private toast: ToastrService,) {
    if(this.homeService.loggedInUser) this.homeService.getCartAPI();
    this.homeService.cartLoctionProducts = localStorage.getItem('cart') ?  JSON.parse(""+localStorage.getItem('cart')) : []
    this.homeService.userPinLocationCountry = localStorage.getItem('userPinLocationCountry');
        let country:string = this.homeService.userPinLocationCountry;
        this.currency = Constants.CURRENCY[country]
    this.homeService.userPinLocation = JSON.parse(""+localStorage.getItem('userLocation'));

    this.route.params.subscribe(params => {
      this.locationId = params['id'];
      this.orderType = params['type'];
      this.homeService.selectedLocation = "" +this.locationId;
      localStorage.setItem('selectedLocation', "" +this.locationId);
      this.getRestarentInfo(params['id']);
      this.getListRestarentService(params['id'])
      if(this.homeService.loggedInUser) this.getCartAPI()
      //this.homeService.cartLoctionProducts = this.homeService.cartLoctionProducts.filter(clp => clp.locationId == this.locationId)
      // homeService.cartLoctionProducts = new Array<LocationProduct>();
    });
    
  }

  ngOnInit(): void {
  }

  proceedToSummary() {
    localStorage.setItem('cart', JSON.stringify(this.homeService.cartLoctionProducts));
    this.router.navigate(['summary']);
  }
  
  remove(index:number) {
    if(confirm('Are you sure you want to remove this item?')) {
      if(this.homeService.loggedInUser)
      this.homeService.deleteItemFromCart(this.homeService.cartLoctionProducts[index]._id).subscribe(()=>{
        
      })
      // debugger;
      this.homeService.cartLoctionProducts.splice(index, 1);
      this.homeService.eventEmitter('remove_from_cart', 'user_cart', 'Cart')
      localStorage.setItem('cart', JSON.stringify(this.homeService.cartLoctionProducts));
  
    }
    
  }

  getProductCatagireService() {
    this.homeService.getProductsCatagires().subscribe((resp: Category[]) => {
      this.categories = resp.filter( (r) => r.parent == null);
      let restaurantCat = this.list_restarents.map(lr => 
        lr.product.categories.length > 0 ? lr.product.categories[0]._id : '');
        this.categories = this.categories.filter(c => restaurantCat.indexOf(c._id) > -1)
    }, (error) => {
      console.error("Error Occured Here", error)
    })
  }

  async orderRestarent(gettingRestInfo: LocationProduct) {
    gettingRestInfo.product.membersList = this.membersList.map(m => m.name);
    gettingRestInfo.locationId = this.locationId;
    if(this.homeService.cartLoctionProducts.length > 0) {
      if(this.locationId !== this.homeService.cartLoctionProducts[0].locationId) {
        if(confirm('Your cart has existing items. Do you want to clear it and add items from this supplier ?')) {
          this.homeService.cartLoctionProducts = [];
          if(this.homeService.loggedInUser)
            await this.homeService.clearCart({}).subscribe(() => {
              
            })
          // this.locationService.caluclateTotal();
        } else {
          return;
        }
      }
    }

    
  //   if(this.homeService.cartLoctionProducts.length > 0) {
  //     if((this.homeService.cartLoctionProducts[0].product.membersList.length > 0 && (this.orderType === 'Regular')) || 
  //      (this.homeService.cartLoctionProducts[0].product.membersList.length == 0 && this.orderType !== 'Regular' && this.orderType !== "Donate to EasyZabiha")) { // Selected product type different
  //         if(confirm('Your cart has existing items for same supplier (other order type). Do you want to clear it and add items ?')) {
  //           if(this.homeService.loggedInUser)
  //             await this.homeService.clearCart({}).subscribe(() => {});
  //         this.homeService.cartLoctionProducts = [];
          
  //         }
  //   }
  // }

    if(this.homeService.cartLoctionProducts.length > 0) {
      console.log(this.orderType)
      console.log(this.homeService.cartLoctionProducts[0].orderType)
      if(this.orderType != this.homeService.cartLoctionProducts[0].orderType) {
        if(confirm('Your cart has existing items for same supplier (other order type). Do you want to clear it and add items ?')) {
            if(this.homeService.loggedInUser)
              await this.homeService.clearCart({}).subscribe(() => {});
          this.homeService.cartLoctionProducts = [];
        } else {
          return;
        }
      }
    }
    this.homeService.eventEmitter('add_to_cart', 'user_cart', 'Cart', 'click', JSON.stringify(gettingRestInfo))
    this.homeService.cartLoctionProducts.push(gettingRestInfo);
    if(this.homeService.loggedInUser) {
      this.homeService.addToCart(Object.assign(gettingRestInfo, {orderType:localStorage.getItem('selectedOrderType')})).subscribe(() => {
        this.getCartAPI()
      })
    }
    this.closeBtn.nativeElement.click();
    console.log(this.homeService.cartLoctionProducts)
    localStorage.setItem('cart', JSON.stringify(this.homeService.cartLoctionProducts));
  }

  getCartAPI() {
    // debugger;
    if(this.homeService.userPinLocation) {
    this.homeService.getCart().subscribe((cart) => {
      if(cart) {
        if(cart['data'].length == 0) {
          return
        }
        this.homeService.cartLoctionProducts = cart['data'];
        this.homeService.cartLoctionProducts = cart['data'];
        localStorage.setItem('cart', JSON.stringify(this.homeService.cartLoctionProducts))

        if(this.homeService.cartLoctionProducts.length > 0) {
          let id = this.homeService.cartLoctionProducts[0].locationId;
          this.homeService.getRestarentInfo(id).subscribe((resp: any) => {
            this.homeService.selectedLocationObj = resp;
            localStorage.setItem('selectedLocationObj', JSON.stringify(resp));
          }, (error) => {
            console.error("Error Occured Here", error);
          });
        }

      }
    })
  } else {
    this.homeService.cartLoctionProducts = [];
        localStorage.setItem('cart', JSON.stringify(this.homeService.cartLoctionProducts))
  }
  }

  notInCartList(prod: LocationProduct) {
    return this.homeService.cartLoctionProducts.findIndex(c => c.productId == prod.productId)==-1
  }

  discountedPrice(prod: LocationProduct) {
    return prod.quantity * (prod.price * ((100 - prod.discount) / 100))
  }

  incrementQty(index: number) {
    // debugger;
    this.priceData[index].qty += 1;
  }

  decrementQty(index: number) {
    this.priceData[index].qty -= 1;
  }


  // getlistof restarents

  getListRestarentService(id: string) {
    this.homeService.getRestarentsList(id).subscribe((resp: LocationProductRes) => {
      this.getProductCatagireService();
      this.list_restarents = resp.products;
      this.list_restarents = this.list_restarents.map(l => {
        l.productId = l._id;
        return l
      })
    }, (error) => {
      console.error("Error Occured Here", error);
    });
  }

  getRestarentInfo(id: string) {
    
    this.homeService.getRestarentInfo(id).subscribe((resp: any) => {
      this.restDetails = resp;
      this.homeService.selectedLocationObj = resp;
      localStorage.setItem('selectedLocationObj', JSON.stringify(resp));
    }, (error) => {
      console.error("Error Occured Here", error);
    });
  }

  // filter list
  onSelect(categoryName: any) {
    this.selectedCategory = categoryName;
  }

  quantityChangeInput(e:any) {
    if(e.target.value.length == 3) return false
    return true
  }
  quantityChange(e:any, id: string) {

    if( Number(e.target.value) == 0 || Number(e.target.value) > 999) {
      if(Number(e.target.value) == 0 ) {
        // Remove 
        let index = this.homeService.cartLoctionProducts.findIndex(c => c.product._id == id)
        if(this.homeService.loggedInUser)
          this.homeService.deleteItemFromCart(this.homeService.cartLoctionProducts[index]._id).subscribe(()=>{
            
          })
        // debugger;
        this.homeService.cartLoctionProducts.splice(index, 1);
        localStorage.setItem('cart', JSON.stringify(this.homeService.cartLoctionProducts));
        return
      }
      this.toast.error('Item quantity should not more than 999.')
      return
    }
    let prodIndex = this.homeService.cartLoctionProducts.findIndex(c => c.product._id == id);
    this.homeService.cartLoctionProducts[prodIndex].quantity = Number(e.target.value);
    if(this.homeService.loggedInUser)
    this.homeService.updateCart(this.homeService.cartLoctionProducts[prodIndex]._id, {quantity: Number(e.target.value)}).subscribe(() => {

    })
    // debugger
  }

  getQuantity(id: string) {
    let prod = this.homeService.cartLoctionProducts.find(c => c.product._id == id);
    if(prod) {
      return prod.quantity;
    } else {
      return 0;
    }
  }

  onProductSelect(product: LocationProduct) {
    this.selectedProduct = product;
    this.selectedProduct.quantity = 1;
    product.product.specs.forEach((s,i) => {
      if(s.type == 'multi') {
        let l = this.selectedProduct.product.specs[i].values.split(',').length;
        this.selectedProduct.product.specs[i].ans = Array(l).fill(false)
      } else {
        this.selectedProduct.product.specs[i].ans = "";
      }
    })

    if(this.orderType == 'AQIQAH' || this.orderType == 'EID') {
      this.membersList = [];
      let arr = new Array(product.product.members).fill('');
      arr.forEach((m, i) => {
        this.membersList.push({
          name: ''
        })
      })


    }
    
  }

  subTotal() {
    return this.homeService.cartLoctionProducts.map(c =>  this.discountedPrice(c)).reduce((x,y) => x+y)
  }

  isDisabled() {
    // let requiredCount = this.selectedProduct.product.specs.filter(s => s.isMust=='on')?.length;
    // let ansCount = this.selectedProduct.product.specs.filter(s => s.ans!=''&&s.isMust=='on')?.length;
    // if(ansCount==requiredCount) return false;
    // return true;
    let requiredAns = 0;
    let requiredCount = this.selectedProduct.product.specs.filter(s => s.isMust=='on')?.length;
    this.selectedProduct.product.specs.forEach(s => {
      if(s.isMust == 'on') {
        if(s.type == 'single' || s.type == 'text') {
          if(s.ans != '') {
            requiredAns += 1;
          }
        } else if(s.type == 'multi') {
          let b:[boolean] = s.ans;
          if(b.filter(a => a).length > 0) {
            requiredAns += 1;
          }
        }
      }
    })
    

    if(this.orderType == 'AQIQAH'||this.orderType == 'EID') {
      if(this.selectedProduct.product.members == this.membersList.filter(m => m.name!='').length) {
        if(requiredCount == requiredAns || requiredCount == 0) {
          return false;
        }
      } 
    } else {
      if(requiredCount == requiredAns || requiredCount == 0) {
        return false;
      }
    }
    return true;
  }

  updateMultiSpec(event:any) {
    // if (-1 !== this.selectedProduct.product.specs.indexOf(event.source.name)) {
    //   if (event.checked) {
    //     this.data.somes.push(event.source.name);
    //   } else {
    //     this.data.somes.splice(this.data.somes.indexOf(event.source.name), 1);
    //   }
    // }
  }

  toggleRead(event: any) {
    $(event.target).parent().find('.productDesc').toggleClass('show-more')
    $(event.target).parent().find('.productDesc').hasClass('show-more') ?
      $(event.target).parent().find('.show-more-less').html('less')
    :
      $(event.target).parent().find('.show-more-less').html('more')
  }

}
