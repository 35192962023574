import { getCurrencySymbol, Location } from '@angular/common';
import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { GoogleMap } from '@angular/google-maps';
import { Router } from '@angular/router';
import { NgbAccordion } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { Constants } from 'src/app/config/constants';
import { LocationProduct } from 'src/app/interfaces/location-product';
import { OrderRes } from 'src/app/interfaces/order-res';
import { HomeService } from 'src/app/services/home.service';

@Component({
  selector: 'app-order-items',
  templateUrl: './order-items.component.html',
  styleUrls: ['./order-items.component.scss']
})
export class OrderItemsComponent implements OnInit,OnDestroy, AfterViewInit {
  accountInfo:boolean = true;

  paymentProcessing: boolean = false;
  paymentSuccess: boolean = false;
  paymentMessage: string = '';
  disabled: false;

  paymentForm:FormGroup;
  isSubmitted:boolean = false;
  email_mobile:string = '';
  full_name:string = '';

  // delivery_address:string = '';
  delivery_address:string = '';

  @ViewChild('a') accor: ElementRef<NgbAccordion>;

   //Maps
   zoom = 16
   center: google.maps.LatLngLiteral = {lat: -34, lng: 151};
   options: google.maps.MapOptions = {
     controlSize: 20,
     fullscreenControl: false,
     mapTypeControl: false,
     zoomControl: false,
     scrollwheel: false,
     disableDoubleClickZoom: true,
     draggable: false,
     maxZoom: 20,
     minZoom: 10,
   }
   geoCoder: google.maps.Geocoder = new google.maps.Geocoder();
   @ViewChild(GoogleMap, { static: false }) map: GoogleMap | undefined

   totalPrice: number = 0;
   totalTax: number = 0;
  //  totalDelivery: number = 50;
  //  caluclateDeliveryCharges: number = 50;
   totalDelivery: number = 0;
   caluclateDeliveryCharges: number = 0;
   tip: number = 0;
   serviceFee: number = 0;
   serviceHST: number = 0;
   pickup: boolean = false;
   currency: string = 'USD';

   @ViewChild('cardInfo') cardInfo: ElementRef;
   card: any;
  cardHandler = this.onChange.bind(this);
  cardError: any = null;
  intentProcessing = false;

  stripeIntent: any = null;

  isSpecialOrder = false; // orderType=='AQIQAH'||orderType=='EID

  donationOrderType = true;
  constructor(public router: Router, public formBuilder:FormBuilder, public homeService: HomeService,
    private toast: ToastrService,  private location: Location,
    private cd: ChangeDetectorRef) {
        this.homeService.userPinLocationCountry = localStorage.getItem('userPinLocationCountry');
        let country:string = this.homeService.userPinLocationCountry;
        this.currency = Constants.CURRENCY[country]
        // GET REQUIRED DATA FROM localStorage
        this.homeService.userPinLocation = JSON.parse(""+localStorage.getItem('userLocation'));
        this.homeService.userPinLocationAddress = "" + localStorage.getItem('userLocationAddress')

        if(this.homeService.userPinLocationAddress == '' || this.homeService.userPinLocationAddress == 'null') {
          this.router.navigate([''], {replaceUrl: true})
        }

        this.homeService.selectedLocation = "" +localStorage.getItem('selectedLocation');
        this.homeService.selectedLocationObj = JSON.parse(""+localStorage.getItem('selectedLocationObj'))

        if(this.homeService.userPinLocation) {
        this.homeService.cartLoctionProducts = JSON.parse(""+localStorage.getItem('cart'))
        this.homeService.cartLoctionProducts = this.homeService.cartLoctionProducts.filter(clp => clp.locationId == this.homeService.selectedLocationObj?._id)
        this.center = this.homeService.userPinLocation;

        let distanceInKm = this.homeService.calcDistanceKm({
          lat: this.homeService.selectedLocationObj?.lat,
          lng: this.homeService.selectedLocationObj?.lng
        }, this.homeService.userPinLocation);

        this.donationOrderType = this.homeService.homeRoute.toLocaleLowerCase().indexOf('donate') > -1
        
          this.homeService.getHomeSettings().subscribe((d) => {
            this.homeService.appSettings = d;
            let charges =  this.homeService.caluclateDeliveryCharges(distanceInKm)
            if(this.donationOrderType) {
              this.delivery_address = 'Donation';
              this.caluclateDeliveryCharges = 0;
              this.totalDelivery= 0;
            } else {
              this.caluclateDeliveryCharges = charges;
              this.totalDelivery = charges;
            }

          });
        

        this.homeService.selectedLocationObj['distanceInKm'] = distanceInKm;
        
        console.log(distanceInKm)
      }
      this.calc();
        if(localStorage.getItem('selectedOrderType')=='AQIQAH'||
          localStorage.getItem('selectedOrderType')=='EID') {
            this.isSpecialOrder = true;
          }
      if(this.homeService.loggedInUser) this.getCartAPI();

      
   }

   calc() {
    this.totalPrice = 0;
    this.totalTax = 0;
    this.homeService.cartLoctionProducts.forEach(cp => {
      let discountedPrice = cp.quantity * (cp.price * ((100-cp.discount)/100))
      this.totalPrice = this.totalPrice + discountedPrice;

      // let tax = cp.price * ((cp.tax)/100);
      let tax = discountedPrice * ((cp.tax)/100);
      this.totalTax = this.totalTax + tax;
    })

    this.serviceFee = this.homeService.selectedLocationObj.hasOwnProperty('serviceFee') ? 
                        this.homeService.selectedLocationObj.serviceFee : 0;

    this.serviceHST = this.homeService.selectedLocationObj.hasOwnProperty('serviceHST') ? 
                        this.homeService.selectedLocationObj.serviceHST : 0;

   }
  ngOnDestroy(): void {
    if (this.card) {
      // We remove event listener here to keep memory clean
      this.card.removeEventListener('change', this.cardHandler);
      this.card.destroy();
    }
  }


  remove(index:number) {
    if(confirm('Are you sure you want to remove this item?')) {
      if(this.homeService.loggedInUser)
      this.homeService.deleteItemFromCart(this.homeService.cartLoctionProducts[index]._id).subscribe(()=>{
        
      })
      this.homeService.cartLoctionProducts.splice(index, 1);
      localStorage.setItem('cart', JSON.stringify(this.homeService.cartLoctionProducts));
      this.calc();
      this.newStripeAPIFlow();

      if(this.homeService.cartLoctionProducts.length == 0) {
        this.goBack();
      }
    }
  }
  goBack() {
    this.location.back();
  }
  ngAfterViewInit(): void {
    // this.initiateCardElement();
    if(this.homeService.loggedInUser) {
      setTimeout(() => {
        this.newStripeAPIFlow();

      }, 4000)
    }

    this.homeService.changeLoggedIn.subscribe(loggedIn => {
      if(loggedIn) {
        this.getCartAPI()
        this.newStripeAPIFlow();
      }
    })

    if(!this.homeService.loggedInUser) {
      this.homeService.showLoginForm()
    }

    // $('#paymentModal').modal('show')
  }

  getCartAPI() {
    if(this.homeService.userPinLocation) {
    this.homeService.getCart().subscribe((cart) => {
      if(cart) {
        if(cart['data'].length == 0) {
          return
        }
        this.homeService.cartLoctionProducts = cart['data'];
        localStorage.setItem('cart', JSON.stringify(this.homeService.cartLoctionProducts))

        if(this.homeService.cartLoctionProducts.length > 0) {
          let id = this.homeService.cartLoctionProducts[0].locationId;
          this.homeService.getRestarentInfo(id).subscribe((resp: any) => {
            this.homeService.selectedLocationObj = resp;
            localStorage.setItem('selectedLocationObj', JSON.stringify(resp));
          }, (error) => {
            console.error("Error Occured Here", error);
          });
        }

        this.calc();

      }
    })
  } else {
    this.homeService.cartLoctionProducts = [];
        localStorage.setItem('cart', JSON.stringify(this.homeService.cartLoctionProducts))
  }
  }
  async newStripeAPIFlow() {
    this.intentProcessing = true;
    this.cd.detectChanges();
    this.stripeIntent = await this.createIntent();
    this.intentProcessing = false;
    this.cd.detectChanges();

    if(!this.card)
      await this.createCardForm();
  }
  
  // Step 1
  async createIntent() {
    
    

    let req = {
      amount: this.totalPrice+this.totalDelivery+this.totalTax+this.tip+this.serviceFee+this.serviceHST,
      currency: this.currency,
      name:this.homeService.loggedInUser.user.name
    };
    
    let intent = await this.homeService.stripeIntent(req).toPromise();
    return intent;
  }

  // Step 2
  async createCardForm() {

    this.card = elements.create('card');
    this.card.mount(this.cardInfo.nativeElement);

    this.card.addEventListener('change', this.cardHandler);
  }

  // Step 3
  async submitPayment() {
    this.cardError = null;
    this.paymentProcessing = true;
    const result = await stripe.handleCardPayment(
      this.stripeIntent.client_secret, this.card, {
        payment_method_data: {
        
        }
      }
    );

    // paymentIntent = result.paymentIntent;

    console.log(result)

    if (result.error) {
      console.error(result.error);
      this.paymentProcessing = false;
      this.paymentMessage = result.error.message;
    } else {
      this.orderPay(result.paymentIntent)
    }
  } 

  initiateCardElement() {
    // Giving a base style here, but most of the style is in scss file
    const cardStyle = {
        base: {
          iconColor: '#c4f0ff',
          color: '#fff',
          fontWeight: 500,
          fontFamily: 'Roboto, Open Sans, Segoe UI, sans-serif',
          fontSize: '16px',
          fontSmoothing: 'antialiased',
  
          ':-webkit-autofill': {
            color: '#fce883',
          },
          '::placeholder': {
            color: '#87BBFD',
          },
        },
        invalid: {
            color: '#fa755a',
            iconColor: '#fa755a',
            ':focus': {
              color: '#303238',
            }
        },
    };
    this.card = elements.create('card', {cardStyle});
    this.card.mount(this.cardInfo.nativeElement);
  this.card.addEventListener('change', this.cardHandler);
}

currencySymbol(code: string) {
  return getCurrencySymbol(code, 'wide')
}
onChange(change:any) {

  if(this.delivery_address == '') {
    this.toast.error('Delivery address should provide.')
    return;
  }

  if(change.complete) {
    if (change.error) {
      this.cardError = change.error.message;
  } else {
      this.cardError = '';
  }
  } else {
    this.cardError = "Card details required.";
  }
  this.cd.detectChanges();
}
async createStripeToken() {

    /*const {token, error} = await stripe.createToken(this.card);
    if (token) {
        this.onSuccess(token);
    } else {
        this.onError(error);
    }*/
}
onSuccess(token:any) {
    console.log("token",token)
    this.orderPay(token.id);
}
onError(error:any) {
    if (error.message) {
        this.cardError = error.message;
    }
}

  ngOnInit(): void {
    this.paymentForm = this.formBuilder.group({
      email_mobile : ['',Validators.required],
      full_name: ['',Validators.required],
      select_bank:['',Validators.required],
      card_number:['',Validators.required],
      expire_Date: ['',Validators.required],
      cvv : ['',Validators.required],
      card_holder:['',Validators.required],
      
    })
    // this.accor.nativeElement.expandAll();

    
  }

  paymentSucess() {
    $('#paymentModal').modal('hide');
    // if (this.card) {
    //   // We remove event listener here to keep memory clean
    //   this.card.removeEventListener('change', this.cardHandler);
    //   this.card.destroy();
    // }
    // Reset cart
    localStorage.setItem('cart', JSON.stringify([]));
    localStorage.setItem('selectedLocation', '');
    this.homeService.cartLoctionProducts = [];

    this.router.navigate(['confirmed']);
  }

  // submitted error forms
  get f() {
    return this.paymentForm.controls;
  }

  accountLogin() {
    this.accountInfo = false;
  }

  paymentSubmited() {
    this.isSubmitted = true;
    console.log("payment details submitted here");
  }

  orderPay(paymentIntent: any) {

    if(paymentIntent == null) return;
    let req:any = {
      "card_number": "",
      "first_name": this.homeService.loggedInUser.user.name,
      "last_name": "",
      "city": this.homeService.userAddressComponent.city,
      "country": this.homeService.userAddressComponent.country,
      "address": this.delivery_address + ', ' + this.homeService.userPinLocationAddress,
      "zip_code": this.homeService.userAddressComponent.pincode,
      "lat": this.homeService.userPinLocation.lat,
      "lng":this.homeService.userPinLocation.lng,
      "location_id": this.homeService.selectedLocation,
      'cart_items': [],
      paymentIntent,
      tip: this.tip,
      pickup: this.pickup,
      serviceFee: this.serviceFee,
      servciceHST: this.serviceHST,
      orderType: localStorage.getItem('selectedOrderType'),
      delivery: this.totalDelivery
    };

    this.homeService.cartLoctionProducts.forEach(product => {
      let prod = {
        id: product.product._id,
        quantity: product.quantity, 
        members: product.product.membersList,
        specs: product.product.specs.map(spec => {
          return {
            "q": spec.question,
            "a": spec.ans,
            "v": spec.values
          }
        })
      };

      req['cart_items'].push(prod);
    });

    this.homeService.placeOrder(req).subscribe((resp: OrderRes) => {
      this.homeService.recentOrder = resp;
      this.paymentProcessing = false;
      this.paymentSuccess = true
      this.paymentMessage = 'Your order placed.';
      this.homeService.eventEmitter('purchase', 'shop', 'Payment')
      this.homeService.clearCart({}).subscribe(() => {
        this.getCartAPI();
      })
      this.paymentSucess();
    }, (err: any) => {
      alert('error');
      this.paymentProcessing = false;
      this.paymentMessage = err;
    })
  }

  notInCartList(prod: LocationProduct) {
    return this.homeService.cartLoctionProducts.findIndex(c => c.product._id == prod.product._id)==-1
  }

  discountedPrice(prod: LocationProduct) {
    return prod.quantity * (prod.price * ((100 - prod.discount) / 100))
  }

  onPickUpChanged(e:any) {
    console.log(e.target.checked)
    if(e.target.checked) {
      this.totalDelivery = 0;
      this.delivery_address = ' ';
    } else {
      this.totalDelivery = this.caluclateDeliveryCharges;
      this.delivery_address = '';
    }

    this.newStripeAPIFlow();
  }

  tipChange(v:number) {
    if(this.tip < 0 || this.tip > 999) {
      this.toast.error('Tip amount should not more than 999.')
      this.tip = 999;
      this.cd.detectChanges();
      return;
    }
    this.tip = v;
    this.newStripeAPIFlow();
  }


  quantityChangeInput(e:any) {
    if(e.target.value.length == 3) return false
    return true
  }

  tipChangeInput(e:any) {
    if(Number(e.target.value) < 0 || Number(e.target.value) > 999) {
      this.toast.error('Tip amount should not more than 999.')
      this.tip = 999; 
      this.cd.detectChanges();
      e.preventDefault()
      return;
    }
    if(e.target.value != '')
    this.tip = Number(e.target.value)

    this.newStripeAPIFlow();
  }

  setMapLOcation() {
    this.homeService.userPinLocation = this.map?.getCenter().toJSON();

    if(this.homeService.userPinLocation) {
      this.geoCoder.geocode({
        location: this.homeService.userPinLocation
      }, ((results: google.maps.GeocoderResult[], status: google.maps.GeocoderStatus) => {
        if (status === google.maps.GeocoderStatus.OK) {

          let addr = results[0].address_components;
          let pincode = addr.find(a => a['types'].includes("postal_code"))?.long_name;
          let country = addr.find(a => a['types'].includes("country"))?.long_name;
          let city = addr.find(a => a['types'].includes("administrative_area_level_2"))?.long_name;

          this.homeService.userAddressComponent = {
            city,country,pincode
          }
          this.homeService.userPinLocationAddress = results[0].formatted_address;
        } else {
            console.log(
                'Geocoding service: geocode was not successful for the following reason: '
                + status
            );
        }
      }))
    }
  }
}
