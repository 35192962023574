import { Component, ElementRef, Input, NgZone, OnInit, ViewChild } from '@angular/core';
import { Constants } from 'src/app/config/constants';
import { PageSection } from 'src/app/interfaces/page-section';
import { Observable, of } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, switchMap, tap } from 'rxjs/operators';
import { SearchSection } from 'src/app/interfaces/search-section';
import { NgbTypeaheadSelectItemEvent } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { HomeService } from 'src/app/services/home.service';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { GoogleMap, MapMarker } from '@angular/google-maps';
import {ToastrService} from 'ngx-toastr';

@Component({
  selector: 'app-section1',
  templateUrl: './section1.component.html',
  styleUrls: ['./section1.component.scss']
})
export class Section1Component implements OnInit {

  @Input() section: PageSection;
  imagePathPrepend: string = Constants.REST_API_SERVER;
  faSearch = faSearch;
 
  searchTerm: any;
  searching = false;
  searchFailed = false;

  showMap = false;

  donationcountry = '';

  donationCountrieslist = [
    {
      key: 'usa',
      order:2,
      active: true,
      countryName: 'United States',
      cityName: 'New York',
      latlng: {
        lat: 40.71016294306725, 
        lng: -73.89594337659516
      }
    },
    {
      key: 'canada',
      order:1,
      active: true,
      countryName: 'Canada',
      cityName: 'Toronto',
      latlng: {
        lat: 43.72633507284459,
        lng: -79.45224715030476
      }
    },
    {
      key: 'india',
      order:3,
      active: true,
      countryName: 'India',
      cityName: 'Hyderabad',
      latlng: {
        lat: 17.4034888,
        lng: 78.4636891
      }
    },
    {
      key: 'Germany',
      order:4,
      active: true,
      countryName: 'Germany',
      cityName: 'Frankfurt',
      latlng: {
        lat: 50.126190398061055, 
        lng: 8.661276063444207
      }
    },
    {
      key: 'United Kingdom',
      order:5,
      active: true,
      countryName: 'United Kingdom',
      cityName: 'Central London',
      latlng: {
        lat: 51.511477523149715, 
        lng: -0.12594274701104796
      }
    },
    {
      key: 'Australia',
      order:6,
      active: true,
      countryName: 'Australia',
      cityName: 'Sydney',
      latlng: {
        lat: -33.822831285002586, 
        lng: 150.97450601895332
      }
    },
    {
      key: 'New Zealand',
      order:7,
      active: true,
      countryName: 'New Zealand',
      cityName: 'Wellington',
      latlng: {
        lat: -41.29593689806143, 
        lng: 174.77758727667742
      }
    },
    {
      key: 'Singapore',
      order:8,
      active: true,
      countryName: 'Singapore',
      cityName: 'Singapore',
      latlng: {
        lat: 1.3737178795244034, 
        lng: 103.82981753078882
      }
    },
    {
      key: 'United Arab Emirates',
      order:3,
      active: true,
      countryName: 'United Arab Emirates',
      cityName: 'Dubai',
      latlng: {
        lat: 25.091928603067092, 
        lng: 55.24417795831576
      }
    },
    {
      key: 'Norway',
      order:10,
      active: true,
      countryName: 'Norway',
      cityName: 'Oslo',
      latlng: {
        lat: 59.91822579476583, 
        lng: 10.752150534696026
      }
    }
  ]
  //Maps
  zoom = 16
  center: google.maps.LatLngLiteral = {lat: -34, lng: 151};
  options: google.maps.MapOptions = {
    controlSize: 20,
    fullscreenControl: false,
    mapTypeControl: false,
    zoomControl: true,
    scrollwheel: false,
    disableDoubleClickZoom: true,
    maxZoom: 20,
    minZoom: 10,
  }
  geoCoder: google.maps.Geocoder = new google.maps.Geocoder();
  @ViewChild(GoogleMap, { static: false }) map: GoogleMap | undefined
  @ViewChild('placeSearch') public searchElementRef: ElementRef;
  constructor(public homeService: HomeService,
    private toast: ToastrService,
    private ngZone: NgZone,
    public router: Router) { }

  ngOnInit(): void {
  }


  search = (text$: Observable<string>) => 
    // text$.pipe(
    //   debounceTime(300),
    //   distinctUntilChanged(),
    //   tap(() => this.searching = true),
    //   switchMap(term => {
    //     if(term != '')
    //     return this.homeService.searchData(term).pipe(
    //       tap(() => this.searchFailed = false),
    //       catchError(() => {
    //         this.searchFailed = true;
    //         return of([]);
    //       }))
    //     else return of([])
    //   }
        
    //   ),
    //   tap(() => this.searching = false)


    /**
     * Working for one address only
     */
    // text$.pipe(
    //   debounceTime(300),
    //   distinctUntilChanged(),
    //   tap(() => this.searching = true),
    //   switchMap((term) => {
    //     return new Observable(observer => {
    //       this.geoCoder.geocode({address: term}, (results, status) => {
    //         if (status === google.maps.GeocoderStatus.OK) {
    //           console.log(results)
    //           observer.next(results);
    //         } else {
    //           console.log('Error - ', results, ' & Status - ', status);
    //           observer.next({ lat: 0, lng: 0 });
    //         }
    //         observer.complete();
    //       });
    //     }) as Observable<any>;
    //   }
        
    //   ),
    //   tap(() => this.searching = false)
    // )

    /**
     * Working with places - but autocomplete UI issue
     */
    // text$.pipe(
    //   debounceTime(300),
    //   distinctUntilChanged(),
    //   tap(() => this.searching = true),
    //   switchMap((term) => {
    //     return new Observable(observer => {
    //       const autocomplete = new google.maps.places.Autocomplete(this.searchElementRef.nativeElement);
    //       autocomplete.addListener("place_changed", () => {
    //         this.ngZone.run(() => {
    //           //get the place result
    //           const place: google.maps.places.PlaceResult = autocomplete.getPlace();
    
    //           //verify result
    //           if (place.geometry === undefined || place.geometry === null) {
    //             return;
    //           }
              
    //           console.log(place)
    //         }); 
    //       });
    //     }) as Observable<any>;
    //   }
        
    //   ),
    //   tap(() => this.searching = false)
    // )

    text$.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      tap(() => this.searching = true),
      switchMap((term) => {
        // return new Observable(observer => {
          // fetch(`https://maps.googleapis.com/maps/api/place/textsearch/json?query=${term}&key=${Constants.GOOGLE_MAPS_API_KEY}`,{
          //   mode: 'no-cors',
          // }).then((places) => {
          //   console.log(places)
          // }).catch(err => {

          // })


        // }) as Observable<any>;
        if(term != '')
        return this.homeService.searchData(term).pipe(
          tap(() => this.searchFailed = false),
          catchError(() => {
            this.searchFailed = true;
            return of([]);
          }))
        else return of([])
      }
        
      ),
      tap(() => this.searching = false)
    )
  // formatter = (x: { name: string, country: { name: string } }) => x.name + ', ' + x.country.name;
  formatter = (x: { formatted_address: string}) => x.formatted_address;


  selectSearchItem = (e: NgbTypeaheadSelectItemEvent) => {
    this.homeService.userPinLocationAddress = e.item.formatted_address;
    localStorage.setItem('userLocationAddress', e.item.formatted_address);
    this.geoCoder.geocode({
      address: e.item.formatted_address
    }, ((results: google.maps.GeocoderResult[], status: google.maps.GeocoderStatus) => {
      if (status === google.maps.GeocoderStatus.OK) {
        console.log(results[0])

        let cityName = results[0]?.address_components.find(a => a?.types.findIndex(t => t == "locality") > -1) ?.long_name;
        if(!cityName)
          cityName = results[0]?.address_components.find(a => a?.types.findIndex(t => t == "administrative_area_level_2") > -1) ?.long_name;
        console.log(cityName)
        if(cityName) {
          this.homeService.searchLocationData(cityName).toPromise().then(d => {
            if(d.length == 0) {
              this.toast.error("Currently delivery is not available in your location, we will come soon!!")
            }
            this.searchTerm = d.length > 0 ? d[0] : '';
          })
        } else {

        }

        // Seller Country / user Pin Country
        let countryName = results[0]?.address_components.find(a => a?.types.findIndex(t => t == "country") > -1) ?.long_name;
        this.homeService.userPinLocationCountry = countryName;
        console.log(countryName)
        localStorage.setItem('userPinLocationCountry', ""+countryName);


        this.homeService.userPinLocation = results[0]?.geometry?.location;
        this.center = results[0]?.geometry?.location.toJSON()
      } else {
          console.log(
              'Geocoding service: geocode was not successful for the following reason: '
              + status
          );
      }
    }))

    this.showMap = true;

  }

  findMosque = () => {
    console.log(this.searchTerm)
    if(this.searchTerm != '')
      this.router.navigate([`city/${this.searchTerm._id}/${this.homeService.userPinLocation.lat},${this.homeService.userPinLocation.lng}`]);
  }

  setMapLOcation() {
    this.homeService.userPinLocation = this.map?.getCenter().toJSON();
    localStorage.setItem('userLocation', JSON.stringify(this.map?.getCenter().toJSON()));
    this.findMosque();
  }

  donateNow() {

    // if(this.donationcountry == 'canada') {
    //   this.setDonationLocationCanada();
    // } else if(this.donationcountry == 'usa') {
    //   this.setDonationLocationUSA()
    // } else if(this.donationcountry == 'india'){
    //   this.setDonationLocation()
    // } else {
    //   this.toast.error("Please select your country to donate.")
    // }

    if(this.donationcountry == '') {
      this.toast.error("Please select your country to donate.")
      return
    }

    let currentDonationCountry = this.donationCountrieslist.find(d => d.key == this.donationcountry)
    let cityName = currentDonationCountry?.cityName
    let countryName = currentDonationCountry?.countryName
    this.homeService.userPinLocationCountry = countryName;
    localStorage.setItem('userPinLocationCountry', ""+countryName);

    this.homeService.userPinLocationAddress = cityName || '';
    localStorage.setItem('userLocationAddress', cityName || '');
    if(cityName) {
      this.homeService.searchLocationData(cityName).toPromise().then(d => {
        if(d.length == 0) {
          this.toast.error("Currently donation is not available for this country, we will come soon!!")
        }
        this.searchTerm = d.length > 0 ? d[0] : '';
        let latLng = currentDonationCountry?.latlng

        this.homeService.userPinLocation = latLng;
        localStorage.setItem('userLocation', JSON.stringify(latLng));
        this.findMosque();
      })
    }

  }
  setDonationLocation() {
    let cityName = 'Hyderabad'
    let countryName = 'India'
    this.homeService.userPinLocationCountry = countryName;
    localStorage.setItem('userPinLocationCountry', ""+countryName);

    this.homeService.userPinLocationAddress = cityName;
    localStorage.setItem('userLocationAddress', cityName);
    if(cityName) {
      this.homeService.searchLocationData(cityName).toPromise().then(d => {
        if(d.length == 0) {
          this.toast.error("Currently delivery is not available in your location, we will come soon!!")
        }
        this.searchTerm = d.length > 0 ? d[0] : '';
        let latLng = {
          lat: 17.4034888,
          lng: 78.4636891
        }

        this.homeService.userPinLocation = latLng;
        localStorage.setItem('userLocation', JSON.stringify(latLng));
        this.findMosque();
      })
    }
  }

  setDonationLocationUSA() {
    let cityName = 'New York'
    let countryName = 'United States'
    this.homeService.userPinLocationCountry = countryName;
    localStorage.setItem('userPinLocationCountry', ""+countryName);

    this.homeService.userPinLocationAddress = cityName;
    localStorage.setItem('userLocationAddress', cityName);
    if(cityName) {
      this.homeService.searchLocationData(cityName).toPromise().then(d => {
        if(d.length == 0) {
          this.toast.error("Currently delivery is not available in your location, we will come soon!!")
        }
        this.searchTerm = d.length > 0 ? d[0] : '';
        let latLng = {
          lat: 40.71016294306725, 
          lng: -73.89594337659516
        }

        this.homeService.userPinLocation = latLng;
        localStorage.setItem('userLocation', JSON.stringify(latLng));
        this.findMosque();
      })
    }
  }

  setDonationLocationCanada() {
    let cityName = 'Toronto'
    let countryName = 'Canada'
    this.homeService.userPinLocationCountry = countryName;
    localStorage.setItem('userPinLocationCountry', ""+countryName);

    this.homeService.userPinLocationAddress = cityName;
    localStorage.setItem('userLocationAddress', cityName);
    if(cityName) {
      this.homeService.searchLocationData(cityName).toPromise().then(d => {
        if(d.length == 0) {
          this.toast.error("Currently delivery is not available in your location, we will come soon!!")
        }
        this.searchTerm = d.length > 0 ? d[0] : '';
        let latLng = {
          lat: 43.72633507284459,
          lng: -79.45224715030476
        }

        this.homeService.userPinLocation = latLng;
        localStorage.setItem('userLocation', JSON.stringify(latLng));
        this.findMosque();
      })
    }
  }
}
