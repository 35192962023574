import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'donationspipe'
})
export class DonationspipePipe implements PipeTransform {

  transform(donationCountries: any[], ...args: any[]): any[] {
    if(donationCountries.length > 0) {
      return donationCountries.filter(d => d.active).sort((a,b) => a.order - b.order)
    }
    return []
  }

}
