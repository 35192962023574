import { AfterViewInit, ChangeDetectorRef, Component, NgZone, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NgScrollbar } from 'ngx-scrollbar';
import { Subscription } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { GetOrders } from 'src/app/interfaces/get-orders';
import { OrderItem } from 'src/app/interfaces/order-item';
import { OrderRes } from 'src/app/interfaces/order-res';
import { HomeService } from 'src/app/services/home.service';

@Component({
  selector: 'app-my-orders',
  templateUrl: './my-orders.component.html',
  styleUrls: ['./my-orders.component.scss']
})
export class MyOrderComponent implements OnInit,OnDestroy, AfterViewInit {
 
  ordersList: OrderRes[] = [];
  OrdersCount = 0;
  loading = false;

  members: any[] = [];
  confirmMembers: boolean[] = [];
  pageSize = 20;
  page = 1;

  confirmDisabled = true;
  currentOrderItem: OrderItem;

  // Unsubscriber for elementScrolled stream.
  private _scrollSubscription = Subscription.EMPTY;

  // Get NgScrollbar reference
  @ViewChild(NgScrollbar, { static: true }) scrollbarRef: NgScrollbar;

  constructor(public router: Router, public homeService: HomeService,
    private ngZone: NgZone,
    private cd: ChangeDetectorRef) {

        
   }
   ngOnInit() {
    this.refreshAllOrders();
   }
  ngOnDestroy(): void {
    this._scrollSubscription.unsubscribe();
  }
  ngAfterViewInit(): void {
    

    this._scrollSubscription = this.scrollbarRef.verticalScrolled
      .pipe(
        map((e: any) => (e.target.offsetHeight  + e.target.scrollTop >= e.target.scrollHeight)),
        tap((size: boolean) => this.ngZone.run(() => {
          if(size) {
            // alert(size);
            this.page = this.page+1;
            this.refreshAllOrders();
          }
        }))
      )
      .subscribe();
  }

  refreshAllOrders() {
    this.loading = true;
    this.homeService.getOrders(`?page=${this.page}&page_size=${this.pageSize}`).subscribe((orders:GetOrders) => {
      this.ordersList = [...this.ordersList, ...orders.orders];
      this.OrdersCount = orders.page_meta.total_items_count;
      this.loading = false;
    })
  }



  confirmation(item: OrderItem) {
    this.members = item.members;
    this.confirmMembers = this.members.map((member) => false);
    this.currentOrderItem = item;
    if(this.confirmMembers.length > 0) this.confirmDisabled = true;
  }

  confirmPrayer() {
    this.homeService.changeStatus({
      "orderitem_id":this.currentOrderItem.id,
      "status":"IN_PROGRESS",
      "message":"Customer approved his prayer. Your order is in-progress with Supplier."
     }).subscribe((orders:any) => {
       $('#confirmModal').modal('hide')
       this.page = 1;
       this.ordersList = []
      this.refreshAllOrders();
      }, (err:any) => {
        
      })
  }

  memberConfirmedCheck(e:any) {
    
    let confirmLength = this.confirmMembers.filter(c => c == true).length;
    if(confirmLength == this.confirmMembers.length) {
      this.confirmDisabled = false;
    } else {
      this.confirmDisabled = true;
    }
  }
  
}
