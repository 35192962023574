import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AboutUsComponent } from './components/about-us/about-us.component';
import { CareersComponent } from './components/careers/careers.component';
import { ContactUsComponent } from './components/contact-us/contact-us.component';
import { LatestUpdatesComponent } from './components/latest-updates/latest-updates.component';
import { ListOfRestaurantComponent } from './components/list-of-restaurant/list-of-restaurant.component';
import { MyOrderComponent } from './components/my-orders/my-orders.component';
import { OrderConfirmedComponent } from './components/order-confirmed/order-confirmed.component';
import { OrderItemsComponent } from './components/order-items/order-items.component';
import { PrivacyPolicyComponent } from './components/privacy-policy/privacy-policy.component';
import { ProfileComponent } from './components/profile/profile.component';
import { RefundComponent } from './components/refund/refund.component';
import { RegisterDeliveryComponent } from './components/register-delivery/register-delivery.component';
import { RegisterSupplierComponent } from './components/register-supplier/register-supplier.component';
import { ResetComponent } from './components/reset/reset.component';
import { SearchRestaurantComponent } from './components/search-restaurant/search-restaurant.component';
import { TermsComponent } from './components/terms/terms.component';
import { VerifyComponent } from './components/verify/verify.component';
import { AuthGuardServiceGuard } from './guards/auth-guard-service.guard';

import { HomeComponent } from './home/home/home.component';
import { LocationComponent } from './pages/location/location.component';
import { LocationsComponent } from './pages/locations/locations.component';

const routes: Routes = [
  {path:'', component:HomeComponent, pathMatch: 'full'},
  {path:'order-type/:type', component:HomeComponent},
  {path:'regular', component:HomeComponent, pathMatch: 'full'},
  {path:'donation', component:HomeComponent, pathMatch: 'full'},
  {path:'eid', component:HomeComponent, pathMatch: 'full'},
  {path:'register/supplier',component:RegisterSupplierComponent},
  {path:'register/delivery',component:RegisterDeliveryComponent},
  {path:'city/:id/:location', component: SearchRestaurantComponent},
  {path:'verify/:id', component: VerifyComponent},
  {path:'reset/:id', component: ResetComponent},
  {path:'location/:id', component: LocationComponent},
  {path:'contact',component:ContactUsComponent},
  {path:'about',component:AboutUsComponent},
  {path:'privacy',component:PrivacyPolicyComponent},
  {path:'terms',component:TermsComponent},
  {path:'refund',component:RefundComponent},
  {path:'career',component:CareersComponent},
  {path:'updates',component:LatestUpdatesComponent},
  {path:'search_restarents',component:SearchRestaurantComponent},
  {path:'restaurants/:id/:type',component:ListOfRestaurantComponent},
  {path:'summary',component:OrderItemsComponent},
  {path:'confirmed',component:OrderConfirmedComponent},
  {path:'orders',component:MyOrderComponent, canActivate : [AuthGuardServiceGuard]},
  {path:'profile',component:ProfileComponent, canActivate : [AuthGuardServiceGuard]}

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
