<div id="site-wrapper">
  <ngx-loading-bar color="#007bff" height="3px" [includeSpinner]="false" ref="router"></ngx-loading-bar>
  <ngx-loading-bar color="#007bff" height="3px" [includeSpinner]="false" ref="http"></ngx-loading-bar>
  <app-header></app-header>
  <div class="container tabs-menu" *ngIf="router.url == '/'">
    <div class="row">
      <app-tabs></app-tabs>
    </div>
  </div>
  <router-outlet></router-outlet>
</div>