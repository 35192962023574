import { Component, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { HomeService } from '../../services/home.service';
import { PageSection } from '../../interfaces/page-section';
import { Constants } from '../../config/constants';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';


@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {
  destroy$: Subject<boolean> = new Subject<boolean>();
  pageSections: Array<PageSection> = new Array<PageSection>();
  pageSection1: any;
  pageSection2: any;
  pageSection3: any;
  imagePathPrepend: string = Constants.REST_API_SERVER;

  parentMessage = 'message from parent';
  bannerImages: any;
  constructor(
    public homeService: HomeService,
    public router: Router,
    public route: ActivatedRoute
  ) {
    //   router.events.subscribe((val) => {
    //     console.log(val instanceof NavigationEnd, val)
    //     console.log(val instanceof NavigationEnd, val)
    //     console.log(val instanceof NavigationEnd, val)
    //     console.log(val instanceof NavigationEnd, val)
    //     console.log(val instanceof NavigationEnd, val)
    // });
  }

  ngOnInit(): void {
    this.homeService
      .getHomePageData()
      .pipe(takeUntil(this.destroy$))
      .subscribe((data: PageSection[]) => {
        this.pageSections = data;
        this.pageChanges();
      });
    this.homeService.getHomeSettings().subscribe((d) => {
      this.homeService.appSettings = d;
    });

    this.route.queryParams.subscribe((params) => {
      console.log('Route', params);
    });

    this.homeService.pageChanged.subscribe((ordertypeId) => {
      setTimeout(() => {
        this.pageChanges();
        console.log(this.homeService.selectedOrderType);
      }, 500);
    });
  }

  pageChanges() {
    // Latest Changes - Jun 02,2022
    this.pageSection1 = this.pageSections.find(
      (ps) =>
        ps.name == Constants.ADMIN_PAGES_HOME_SECTION1 &&
        ps.orderTypeId == this.homeService.selectedOrderType
    );

    this.homeService.headerText = this.pageSection1?.heading;
    this.pageSection2 = this.pageSections.find(
      (ps) => ps.name == Constants.ADMIN_PAGES_HOME_SLIDER
    );
    if (this.pageSection2) this.bannerImages = this.pageSection2.images;
    this.pageSection3 = this.pageSections.find(
      (ps) => ps.name == Constants.ADMIN_PAGES_HOME_SECTION2
    );
  }
  ngOnDestroy() {
    this.destroy$.next(true);
    // Unsubscribe from the subject
    this.destroy$.unsubscribe();
  }
}
