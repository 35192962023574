import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { faUser } from '@fortawesome/free-regular-svg-icons';
import { UserRes } from 'src/app/interfaces/user.res';
import { HomeService } from 'src/app/services/home.service';

@Component({
  selector: 'app-verify',
  templateUrl: './verify.component.html',
  styleUrls: ['./verify.component.scss']
})
export class VerifyComponent implements OnInit {
  
  verifyId: string = '';
  verificationMsg: string = 'Verification is pending.';
  verified: boolean = false;
  constructor(public homeService: HomeService, private route: ActivatedRoute) { 
    this.route.params.subscribe(params => {
      this.verifyId = params['id'];
      
    });
  }
  ngOnInit(): void {
    this.homeService.userVerify({id: this.verifyId}).toPromise().then(() => {
      this.verificationMsg = 'Verification Success.';
      this.verified = true;
    }).catch((err) => {
      this.verificationMsg = 'Verification Failed.';
      this.verified = false;
    })
  }

}
