<div class="container">
    <h2 class="MuiTypography-h2 paddT5rem">
        <span class="dark-bl f-b">Join with</span> <span class="light-bl f-b"> Easy Zabiha </span>
    </h2>
    <div class="row pt-4">
        <!-- <div class="col-xs-12 col-sm-6 col-md-4">
            <h2 class="MuiTypography-h2 paddT5rem">
                <span class="dark-bl f-b">Join with</span> <span class="light-bl f-b"> Easy Zabiha </span>
            </h2>
            <p class="MuiTypography-subtitle1">Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the
                industry's standard dummy text ever since,Lorem Ipsum is simply dummy text of the printing and
                typesetting industry.
            </p>
        </div> -->

        <div class="col-xs-12 col-sm-6 col-md-4">

            <div class="innnerImg">
                <div class="subMenuHeader padd16">
                    <img src="../../assets/images/joinwith/1.png" class="driveImg">
                    <h5 class="MuiTypography-h6 paddingnative"> Partner with easy zabiha </h5>
                    <p class="MuiTypography-subtitle1 text-left">Expand your boundaries and attract new
                        customers by collaborating with us </p>
                    <button type="button" style="display: block;" class="btn btn-outline-secondary w-100 marB20" *ngIf="!homeService.loggedInUser" (click)="navToSupplier()">Join
                        Now</button>
                </div>

            </div>
        </div>

        <div class="col-xs-12 col-sm-6 col-md-4">

            <div class="innnerImg">
                <div class="subMenuHeader padd16">
                    <img src="../../assets/images/joinwith/2.png" class="driveImg">
                    <h5 class="MuiTypography-h6 paddingnative"> Deliver with easy zabiha </h5>
                    <p class="MuiTypography-subtitle1 text-left">Earn without limits at anytime and from anywhere </p>
                    <button type="button" style="display: block;" class="btn btn-outline-secondary w-100 marB20" *ngIf="!homeService.loggedInUser" (click)="navToDelivery()">Join
                        Now</button>
                </div>

            </div>
        </div>

        <div class="col-xs-12 col-sm-6 col-md-4">
            <!-- <div class="innnerImg">
                <img src="../../assets/images/meat.png" class="driveImg">
                <div class="subMenuHeader">
                    <h5 class="MuiTypography-h6"> Partner with eazy zabiha </h5>
                </div>
                <p class="MuiTypography-subtitle1">Lorem Ipsum is simply dummy text of the printing and typesetting
                    industry. </p>
                <button type="button" style="display: block;" class="btn btn-outline-secondary w-100">Join Now</button>
            </div> -->
            <div class="innnerImg">
                <div class="subMenuHeader padd16">
                    <img src="../../assets/images/joinwith/3.png" class="driveImg">
                    <h5 class="MuiTypography-h6 paddingnative"> Careers at easy zabiha </h5>
                    <p class="MuiTypography-subtitle1 text-left">Achieve your dreams by growing with our team</p>
                    <button type="button" style="display: block;"  (click)="navToSupplier()" class="btn btn-outline-secondary w-100 marB20" *ngIf="!homeService.loggedInUser" disabled>Join
                        Now</button>
                </div>

            </div>
        </div>



        <!-- <div class="col-sm-4 ">
            <div class="container">
                <div class="login-box">
                    <img src="../../assets/images/frame.png" class="avatar">
                    <div class="textContent">
                        <h2 class="driveTitle"> Drive with eazy zabiha </h2>
                        <p>Lorem Ipsum is simply dummy text of the
                            typesetting industry. Lorem Ipsum has been.
                        </p>
                        <button type="button" style="display: block;"
                            class="btn btn-outline-primary w-100">Join Now</button>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-sm-4 ">
            <div class="container">
                <div class="login-box">
                    <img src="../../assets/images/frame.png" class="avatar">
                    <div class="textContent">
                        <h2 class="driveTitle"> Drive with eazy zabiha </h2>
                        <p>Lorem Ipsum is simply dummy text of the
                            typesetting industry. Lorem Ipsum has been.
                        </p>
                        <button type="button" style="display: block;"
                            class="btn btn-outline-primary w-100"> Join Now </button>
                    </div>
                </div>
            </div>
        </div> -->


    </div>
</div>