<div class="menu-tabs">
    <!-- <a class="menu-tab-link" [routerLink]="['/']" (click)="tabChange('eid')" [ngClass]="{'active': selectedTab == 'eid'}" >
        <img class="menu-tab-img" src="assets/images/menu-tabs/e.png" /> EID
    </a>
    <a  class="menu-tab-link" [routerLink]="['regular']"  (click)="tabChange('regular')" [ngClass]="{'active': selectedTab == 'regular'}" >
        <img class="menu-tab-img" src="assets/images/menu-tabs/r.png" /> Regular
    </a>
    <a  class="menu-tab-link" [routerLink]="['donation']"  (click)="tabChange('donate')" [ngClass]="{'active': selectedTab == 'donate'}">
        <img class="menu-tab-img" src="assets/images/menu-tabs/d.png"/> Donation
    </a> -->

    <a class="menu-tab-link" *ngFor="let orderType of orderTypes" [routerLink]="getRouterLink(orderType.name)" (click)="tabChange(orderType.name)" [ngClass]="{'active': selectedTab == orderType.name}" >
        <img class="menu-tab-img" [src]="imagePathPrepend + orderType?.icon?.path" /> {{orderType.name}}
    </a>
</div>