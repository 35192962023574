<div>
  <div class="text-center hintPlaceholder verifyMsg">{{verificationMsg}}</div>

  <form [formGroup]="loginForm" (ngSubmit)="doSubmit()" class="resetForm" *ngIf="verificationMsg==''">
    <div class="form-group">
      <label> New Password </label>
      <input type="password" formControlName="password" class="form-control" placeholder="password"
      [(ngModel)]="password"
        [ngClass]="{ 'is-invalid': submitted && submitError.password.errors }" />
      <div *ngIf="submitted && submitError.password.errors" class="invalid-feedback">
        <div *ngIf="submitError.password.errors.required"> Password Name is required</div>
        <div *ngIf="submitError.password.errors.minlength"> Password length must be at least 6.</div>
      </div>
    </div>

    <div class="form-group">
      <button class="btn btn-success btn-block" type="submit"> Submit </button>
    </div>
  </form>

</div>