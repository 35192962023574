<div class="container">
    
    <h2 class="MuiTypography-h2 paddT5rem">
        <div class="container-top-header">Easy Zabiha process</div>
        <span class="dark-bl f-b">How it</span> <span class="light-bl f-b"> Works </span>
    </h2>
    <div class="row pt-4">
        <div class="col how-it-block">
            <img class="how-it-image" src="../../../assets/images/howit/1.png" />
            <div class="how-it-title">Responsibly sourced</div>
            <div class="how-it-subtitle">Our supplier partners source
                superior quality grass-fed /
                pasture-raised animals from
                local farmers</div>
        </div>
        <div class="col-auto how-it-block-divider">
            <img class="how-it-image-divider" src="../../../assets/images/howit/forward.png" />
        </div>
        <div class="col how-it-block">
            <img class="how-it-image" src="../../../assets/images/howit/2.png" />
            <div class="how-it-title">Tailor your package</div>
            <div class="how-it-subtitle">You customize your order for
                pickup or delivery</div>
        </div>
        <div class="col-auto how-it-block-divider">
            <img class="how-it-image-divider" src="../../../assets/images/howit/forward.png" />
        </div>
        <div class="col how-it-block">
            <img class="how-it-image" src="../../../assets/images/howit/3.png" />
            <div class="how-it-title">Live order tracking</div>
            <div class="how-it-subtitle">You can track the status of
                your package right from
                placing the order to delivery</div>
        </div>
    </div>
    <div class="row pt-4">
        <div class="col-auto how-it-block-divider">
            <img class="how-it-image-divider" style="visibility: hidden;width: 145px;" src="../../../assets/images/howit/forward.png" />
        </div>
        <div class="col how-it-block">
            <img class="how-it-image" src="../../../assets/images/howit/5.png" />
            <div class="how-it-title">You enjoy</div>
            <div class="how-it-subtitle">You cook, share and enjoy
                high quality HALAL meat you
                can trust</div>
        </div>
        <div class="col-auto how-it-block-divider">
            <img class="how-it-image-divider" src="../../../assets/images/howit/back.png" />
        </div>
        <div class="col how-it-block">
            <img class="how-it-image" src="../../../assets/images/howit/4.png" />
            <div class="how-it-title">Door delivery</div>
            <div class="how-it-subtitle">Your order is delivered
                straight to your door saving
                you a lot of time and effort</div>
        </div>
        <div class="col-auto how-it-block-divider-updown">
            <img class="how-it-image-divider" src="../../../assets/images/howit/upward.png" />
        </div>
        
    </div>
</div>