import { AfterViewInit, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { HomeService } from 'src/app/services/home.service';
import {ToastrService} from 'ngx-toastr';
@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit,OnDestroy, AfterViewInit {
 
  req = {
    name: '',
    email: ''
  }
  constructor(public router: Router, public homeService: HomeService,
    private cd: ChangeDetectorRef, private toast: ToastrService) {
      let user = localStorage.getItem('user');

      if(user != null) {
        this.homeService.loggedInUser = JSON.parse(user);
        this.req.name = this.homeService.loggedInUser.user.name;
        this.req.email = this.homeService.loggedInUser.user.email;
      }
      
        
   }
  ngOnDestroy(): void {
    
  }
  ngAfterViewInit(): void {

  }

  ngOnInit(): void {
   
  }

  async save() {

  this.homeService.saveprofile(this.req).subscribe(async (user) => {
    this.homeService.loggedInUser.user = user;
      // localStorage.setItem('user', JSON.stringify(resp));
      const logInUser = JSON.stringify(this.homeService.loggedInUser);
      localStorage.setItem('user', logInUser);
      this.homeService.changeMyLogginIn(true);

    this.presentToast('Profile saved successfully.');


  }, (err) => {
    this.toast.error('Profile not saved.')
  });
}

async presentToast(msg:string) {
  this.toast.success(msg)
}

  
}
