import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-why-easy-zaiha',
  templateUrl: './why-easy-zaiha.component.html',
  styleUrls: ['./why-easy-zaiha.component.scss']
})
export class WhyEasyZaihaComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
