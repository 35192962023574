<div class="loginFormData" >
  <div class="modal right fade" id="loginModelCenter" tabindex="-1" role="dialog" aria-labelledby="loginModelCenterTitle"
    aria-hidden="true">
    <div class="modal-dialog" role="document">
      <div class="modal-close-outside">
        <button type="button" class="close primary-color" data-dismiss="modal" aria-label="Close" #closeBtn>
          <span aria-hidden="true" #closeBtn>&times;</span>
        </button>
      </div>
      <div class="modal-content">
        <div class="modal-body authPopup">
          
          <form [formGroup]="loginForm" (ngSubmit)="doLogin()" *ngIf="currentForm=='login'">
            <div class="logoBlock">
              <img src="../../../assets/images/logo.png" />
            </div>
            <div class="form-group">
              <label>Email</label>
              <input type="text" formControlName="email" class="form-control" [(ngModel)]="account.email" maxlength="50"
                [ngClass]="{ 'is-invalid': submitted && f.email.errors }" />
              <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                <div *ngIf="f.email.errors.required"> Email is required</div>
                <div *ngIf="f.email.errors.email">Email must be a valid email address</div>
              </div>
            </div>
            <div class="form-group">
              <label>Password</label>
              <input type="password" formControlName="password" class="form-control" [(ngModel)]="account.password"
                [ngClass]="{ 'is-invalid': submitted && f.password.errors }" minlength="6" maxlength="30" />
              <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
                <div *ngIf="f.password.errors.required">Password is required</div>
                <!-- <div *ngIf="f.password.errors.minlength">Password must be at least 6 characters</div> -->
              </div>
            </div>
            <div class="form-group">
              <button class="btn btn-success btn-block" type="submit"> Login </button>
            </div>

            <!-- <div class="row">    
              <div class="col-6 text-center">     -->
                  <!-- <button (click)="signInWithFB()" class="loginBtn loginBtn--facebook">    
                      Login with Facebook    
                  </button>     -->

                  <!-- <div class="g-signin2" data-width="150" data-onsuccess="signInWithGoogle" data-longtitle="true"></div>
                  <br />
                     
              </div>    
              <div class="col-6 text-right">     -->
                  <!-- <button (click)="signInWithGoogle()" class="loginBtn loginBtn--google">    
                      Login with Google    
                  </button>     -->
                  <!-- <div class="fb-login-button" data-width="150" data-size="medium" data-button-type="login_with" data-auto-logout-link="false" data-use-continue-as="true"></div>

              </div>    
          </div> -->

            <div class="form-group text-center">
              <label class="primary-color">- Or -</label>
            </div>
            <div class="form-group">
              <button class="btn btn-outline-success btn-block" (click)="showRegisterForm()"> Register </button>
            </div>

            <div class="text-center cursorPoin forgot" (click)="showForgotForm()"> Forgot Password ?
            </div>

          </form>

          <form [formGroup]="forgotForm" (ngSubmit)="forgotPwd()" *ngIf="currentForm=='forgot'">
            <div class="logoBlock">
              <img src="../../../assets/images/logo.png" />
            </div>
            <div class="form-group">
              <label>Email</label>
              <input type="text" formControlName="email" class="form-control" 
              [(ngModel)]="account.email"
              maxlength="50"
                [ngClass]="{ 'is-invalid': submitted && forgotEror.email.errors }" />
              <div *ngIf="submitted && forgotEror.email.errors" class="invalid-feedback">
                <div *ngIf="forgotEror.email.errors.required"> Email is required</div>
              </div>
            </div>
            <div class="form-group">
              <button class="btn btn-success btn-block" type="submit"> Submit </button>
            </div>
          </form>

          <form [formGroup]="registerForm" (ngSubmit)="doRegister()" *ngIf="currentForm=='register'">
            <div class="logoBlock">
              <img src="../../../assets/images/logo.png" />
            </div>
            <div class="form-group">
              <label> First Name </label>
              <input type="text" formControlName="first_name" class="form-control" placeholder="First Name (max 30 characters)" maxlength="30"
              [(ngModel)]="registerUser.first_name"
                [ngClass]="{ 'is-invalid': registerFormSubmitted && registerEror.first_name.errors }" />
              <div *ngIf="registerFormSubmitted && registerEror.first_name.errors" class="invalid-feedback">
                <div *ngIf="registerEror.first_name.errors.required"> First Name is required</div>
                <div *ngIf="registerEror.first_name.errors.maxlength">First Name is should be max of 30 characters</div>
              </div>
            </div>

            <div class="form-group">
              <label> Last Name </label>
              <input type="text" formControlName="last_name" class="form-control" placeholder="Last Name (max 30 characters)"  maxlength="30"
              [(ngModel)]="registerUser.last_name"
                [ngClass]="{ 'is-invalid': registerFormSubmitted && registerEror.last_name.errors }" />
              <div *ngIf="registerFormSubmitted && registerEror.last_name.errors" class="invalid-feedback">
                <div *ngIf="registerEror.last_name.errors.required"> Last Name is required</div>
                <div *ngIf="registerEror.last_name.errors.maxlength">Last Name is should be max of 30 characters</div>
                <!-- <div *ngIf="f.email.errors.email">Email must be a valid email address</div> -->
              </div>
            </div>

            <div class="form-group">
              <label>Email</label>
              <input type="text" formControlName="register_email" class="form-control" placeholder="E-mail Address"  maxlength="50"
              [(ngModel)]="registerUser.email"
                [ngClass]="{ 'is-invalid': registerFormSubmitted && registerEror.register_email.errors }" />
              <div *ngIf="registerFormSubmitted && registerEror.register_email.errors" class="invalid-feedback">
                <div *ngIf="registerEror.register_email.errors.required"> Email is required</div>
                <div *ngIf="registerEror.register_email.errors.email">Email must be a valid email address</div>
              </div>
            </div>

            <div class="form-group">
              <label> Password </label>
              <input type="password" formControlName="register_pwd" class="form-control" placeholder="Password (minimum 6 characters)"
              [(ngModel)]="registerUser.password" minlength="6"
                [ngClass]="{ 'is-invalid': registerFormSubmitted && registerEror.register_pwd.errors }" />
              <div *ngIf="registerFormSubmitted && registerEror.register_pwd.errors" class="invalid-feedback">
                <div *ngIf="registerEror.register_pwd.errors.required"> Password Name is required</div>
                <div *ngIf="registerEror.register_pwd.errors.minlength"> Password length must be at least 6 characters.</div>

              </div>
            </div>

            <div class="form-group">
              <label> Mobile Number </label>
              <div class="input-group mb-3">
                <div class="input-group-prepend">
                  <select [(ngModel)]="registerUser.country_code"
                  class="form-control country-codes" formControlName="country_code" id="sel1" style="height: 35px;
                  border-top-right-radius: 0;
                  border-bottom-right-radius: 0;">
                    <option  value="undefined" [selected]="true">Country</option>
                    <option [value]="code" *ngFor="let code of countryCodes">{{code}}</option>
                  </select>
                </div>
                <input type="tel" formControlName="mobile_number" class="form-control" placeholder="Mobile Number"
                [(ngModel)]="registerUser.mobile_number" (change)="mobileNumChanges()"
                maxlength="15"
                minlength="8"
                  [ngClass]="{ 'is-invalid': registerFormSubmitted && registerEror.mobile_number.errors }" />
                <div class="input-group-append">
                  <button class="btn btn-outline-secondary" [disabled]="validateButtonDisable()" type="button" (click)="mobileOTP()">Validate</button>
                </div>
              </div>
              <div>
                <input type="checkbox" name="smsAccept" id="smsAccept" checked > <span class="noteText">By continuing you will receive a one-time verification code to your phone number by SMS. Message and data rates may apply.</span>
              </div>
              <div *ngIf="registerFormSubmitted && registerEror.mobile_number.errors" class="invalid-feedback">
                <div *ngIf="registerEror.mobile_number.errors.required"> Mobile Number Name is required</div>
              </div>
            </div>

            <div  class="form-group" *ngIf="otpSent">
              <label> One time password </label>
              <ng-otp-input #ngOtpInput (onInputChange)="onOtpChange($event)" [config]="{length:6, allowNumbersOnly: true}"></ng-otp-input>
            </div>
            <div *ngIf="otpSent" style="font-size: 12px;margin-bottom: 10px;"><b>{{counter$ | async}} Seconds</b> to <b *ngIf="!otpReSent" style="color:gray;">resend OTP</b><b *ngIf="otpReSent" style="color: blue;cursor: pointer;" (click)="mobileOTP();count=120;otpReSent=false;">resend OTP</b></div>

            <div class="form-group">
              <button class="btn btn-success btn-block" type="submit" [disabled]="otp.length!=6"> Register </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>


<div class="main-header">
  <div class="container">
    <div class="row">
  
      <div class="col-auto app-holder">
        <div class="app-btn">
          <a href="/">
            <img src="assets/images/logo.png" class="logo">
          </a>
        </div>
      </div>
      <div class="col app-header-text">
        {{homeService?.headerText}}
      </div>
      <div class="col-auto user-profile">
        <!-- <div class="main"> -->
        <div class="toogleBtn" style="float: right;">
          <div class="dropdown dropdown-user">
            <a href="#" class=" user-pic" data-toggle="dropdown">
              <div class="items-cart-inner">
                <div class="top-user">

                  <img src="assets/images/menu.svg" style="
                    margin-left: 20px;" class="userIcon">
                </div>
              </div>
            </a>
            <ul class="dropdown-menu">
              <li *ngIf="!homeService.loggedInUser"><a href="#" data-toggle="modal" data-target="#loginModelCenter" (click)="showLoginForm()">Login</a> </li>
              <li *ngIf="!homeService.loggedInUser"><a href="#" data-toggle="modal" data-target="#loginModelCenter" (click)="showRegisterForm()">Register</a> </li>
              <li *ngIf="homeService.loggedInUser"><a href="javascript:void(0)" (click)="navToProfile()">Profile</a> </li>
              <li *ngIf="homeService.loggedInUser"><a href="javascript:void(0)" (click)="navToOrders()">Orders</a> </li>
              <li *ngIf="homeService.loggedInUser" (click)="logout()"><a href="javascript:void(0)">Logout</a> </li>
              <!-- <li><a routerLink="search_restarents"> Search Restarents </a> </li> -->
            </ul>
          </div>
        </div>
        <div class="register" style="float: right;" *ngIf="!homeService.loggedInUser">
          <img src="assets/images/user.svg" class="userIcon" />

          <span class="cursorPoin" id="loginLink" data-toggle="modal" data-target="#loginModelCenter" (click)="showLoginForm()">
            Login
          </span>
          <span style="color:white;"> / </span>
          <span class="cursorPoin" data-toggle="modal" data-target="#loginModelCenter" (click)="showRegisterForm()">
            Register
          </span>
        </div>

        <div class="register" style="float: right;" *ngIf="homeService.loggedInUser">
          <img src="assets/images/user.svg" class="userIcon" />

          <span class="cursorPoin">
            {{homeService.loggedInUser?.user?.name}}
          </span>

        </div>

        <div class="headerHomeIcon headerCartIcon ml0" *ngIf="homeService.loggedInUser" [ngClass]="{'disabled':!homeService.cartLoctionProducts || homeService.cartLoctionProducts.length == 0}">
          <a href="/summary" style="color: white;" [attr.disabled]="(!homeService.cartLoctionProducts || homeService.cartLoctionProducts.length == 0) && (homeService.userPinLocationAddress != '')">
            <i class="fa fa-shopping-cart" aria-hidden="true"></i>
          </a>
          <span class="badge" *ngIf="homeService.cartLoctionProducts.length != 0">
            {{homeService.cartLoctionProducts.length}}
          </span>
        </div>

        <div class="headerHomeIcon">
          <a href="/" style="color: white;">
            <i class="fa fa-home" aria-hidden="true"></i>
          </a>
        </div>


        <!-- </div> -->
        <!-- <div class="dropdown dropdown-user">
          <a href="#" class=" user-pic" data-toggle="dropdown">
            <div class="">
              <div class="">
                <img src="assets/images/user.svg" class="userIcon" />
                <span class="userInfo">Login / Register</span>
              </div>
            </div>
          </a>
          <ul class="dropdown-menu">
            <li><a href="#">Profile</a> </li>
            <li><a href="#">Logout</a> </li>
          </ul>
        </div> -->
      </div>



      <!-- End User Profile-->
    </div>
  </div>
</div>