<div class="headerOverFlow">
    <ng-scrollbar>
        <div>
            <section class="banner-wrapper">
                <app-section1 [section]="pageSection1"></app-section1>
            </section>
            <app-section2 *ngIf="homeService.homeRoute.toLowerCase().indexOf('donat') !== -1"></app-section2>

            <section class="banner-sec-two pt-4">
                <app-slider-banners [bannerImages]="bannerImages"></app-slider-banners>
            </section>
            <section class="download-app-sec">
                <app-downloadapp [pageSection3]="pageSection3"></app-downloadapp>
            </section>
            <!-- <section class="why-easy-sec">
                <app-why-easy-zaiha></app-why-easy-zaiha>
            </section> -->
            <section class="Join-with-easy">
                <app-how-it-works></app-how-it-works>
            </section>
            <section class="Join-with-easy">
                <app-join-with-easy-zaiha></app-join-with-easy-zaiha>
            </section>

            <section class="footer-sec">
                <app-footer-bar></app-footer-bar>
            </section>            
        </div>
    </ng-scrollbar>
</div>