import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { City } from 'src/app/interfaces/city';
import { HomeService } from 'src/app/services/home.service';
import { SearchResult } from '../../interfaces/search-result';
import { faArrowLeft, faMapMarkerAlt} from '@fortawesome/free-solid-svg-icons';
import { Category } from '../../interfaces/category';
import { Constants } from '../../config/constants';


@Component({
  selector: 'app-location',
  templateUrl: './location.component.html',
  styleUrls: ['./location.component.scss']
})
export class LocationComponent implements OnInit {

  faBackward = faArrowLeft;
  faMapMarkedAlt = faMapMarkerAlt;
  locationId: string = '';
  locationInfo: SearchResult | undefined;
  destroy$: Subject<boolean> = new Subject<boolean>();
  destroy2$: Subject<boolean> = new Subject<boolean>();

  productCategories: Category[] = [];
  productTopLevelCategories: Category[] = [];
  subCategories: Category[] = [];

  activeCategory: string = '';
  activeSubCategory: string = '';

  imagePathPrepend: string = Constants.REST_API_SERVER;

  constructor(private route: ActivatedRoute,
    private homeService: HomeService,
    private router: Router) { }

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      this.locationId = params['id'];
      this.homeService.getLocationInfo(this.locationId).pipe(takeUntil(this.destroy$)).subscribe((data: SearchResult )=>{
        this.locationInfo = data;
      })
      this.homeService.getProductCategories().pipe(takeUntil(this.destroy2$)).subscribe((data: Category[] )=>{
        this.productCategories = data;
        this.productTopLevelCategories = data.filter(d => d.parent == null)
      })
    })
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    // Unsubscribe from the subject
    this.destroy$.unsubscribe();
    this.destroy2$.next(true);
    this.destroy2$.unsubscribe();
  }

  categoryClick = (id: string) => {
    this.activeCategory = id;
    this.subCategories = this.productCategories.filter(p => p.parent == id);
  }
  subCategoryClick = (id: string) => {
    console.log(id)
    this.activeSubCategory = id;
  }

}
