import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { faUser } from '@fortawesome/free-regular-svg-icons';
import { UserRes } from 'src/app/interfaces/user.res';
import { HomeService } from 'src/app/services/home.service';

import {ToastrService} from 'ngx-toastr';

import { Observable, timer } from 'rxjs';
import { take, map } from 'rxjs/operators';

import { SocialAuthService } from "angularx-social-login";
import { FacebookLoginProvider, GoogleLoginProvider } from "angularx-social-login";

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  counter$: Observable<number>;
  count = 120;

  @ViewChild('closeBtn') closeBtn: ElementRef;
  @ViewChild('ngOtpInput') ngOtpInputRef:ElementRef;
  faUserCircle = faUser;

  loginForm: FormGroup;
  registerForm: FormGroup;
  forgotForm: FormGroup;
  userLoginForm:boolean = true;

  currentForm = 'login';

  submitted: boolean = false;
  registerFormSubmitted: boolean = false;

  otpSent: boolean = false;
  otpReSent: boolean = false;

  otp: string = '';

  countryCodes: string[] = [];
  _countriesInfo: any;

  account: { email: string, password: string } = {
    email: '',
    password: ''
  };

  forgotdata: { email: string} = {
    email: '',
  }

  registerUser: { first_name: string, last_name: string, email: string, mobile_number: string, password: string, country_code: string } = {
    first_name: '',
    last_name: '',
    email: '',
    mobile_number: '',
    password: '',
    country_code: 'undefined'
  }

  constructor(public formBuilder: FormBuilder, public homeService: HomeService,
    private router: Router, private toast: ToastrService,
    private authService: SocialAuthService) { 
    let user = localStorage.getItem('user');
    if(user != null) {
      homeService.loggedInUser = JSON.parse(user);
      // Get Cart
      this.getCartAPI()
    }

    this.homeService.FooterInfo().subscribe((resp: any) => {
      this._countriesInfo = resp;
      this.countryCodes = this._countriesInfo.map((c:any )=> c.countryCode);
    }, (error) => {
      console.error('Error Occured here', error);
    })
  }

  get f() {
    return this.loginForm.controls;
  }

  get registerEror() {
    return this.registerForm.controls;
  }

  get forgotEror() {
    return this.forgotForm.controls;
  }

  signInWithGoogle(): void {
    this.authService.signIn(GoogleLoginProvider.PROVIDER_ID);
  }

  signInWithFB(): void {
    this.authService.signIn(FacebookLoginProvider.PROVIDER_ID);
  }

  signOut(): void {
    this.authService.signOut();
  }

  ngAfterViewInit() {
    if(this.ngOtpInputRef) this.ngOtpInputRef.nativeElement.setValue('')

    this.homeService.invokeLogin.subscribe((b) => {
    if(b)    {  
      this.showLoginForm()
      document.getElementById('loginLink')?.click()
    }
    })

    this.authService.authState.subscribe((user) => {
      this.homeService.loggedInUser = user;
        localStorage.setItem('user', JSON.stringify(user));
        this.homeService.changeMyLogginIn(true);
        // Get Cart
        this.getCartAPI()
      console.log(user);
    });
  }
  ngOnInit(): void {
    this.loginForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required, Validators.minLength(6)]],
    });

    this.forgotForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
    });

    this.registerForm = this.formBuilder.group({
      first_name: ['', [Validators.required, Validators.maxLength(30)]],
      last_name: ['', [Validators.required, Validators.maxLength(30)]],
      country_code: ['undefined', [Validators.required]],
      register_email: ['', [Validators.required, Validators.email, Validators.maxLength(60)]],
      mobile_number: ['', [Validators.required, Validators.maxLength(13)]],
      register_pwd: ['', [Validators.required, Validators.minLength(6)]],
    })

    this.homeService.cartLoctionProducts = JSON.parse(""+localStorage.getItem('cart'))

  }


  doLogin() {
    this.submitted = true;
    if (this.loginForm.invalid) {
      return;
    } else {
      this.homeService.userLogin(this.account).subscribe((resp: UserRes) => {
        this.homeService.eventEmitter('login','user_login', 'Login')
        this.closeBtn.nativeElement.click();
        this.homeService.loggedInUser = resp;
        localStorage.setItem('user', JSON.stringify(resp));
        this.homeService.changeMyLogginIn(true);
        // Get Cart
        this.getCartAPI()
        // this.router.navigate(['']);
      }, (error) => {
        console.log("Error Occured Here", error);
        this.toast.error(error)

      });
    }
  }

  getCartAPI() {
    // debugger;
    this.homeService.getCart().subscribe((cart) => {
      if(cart) {
        if(cart['data'].length == 0) {
          return
        }
        this.homeService.cartLoctionProducts = cart['data'];
        localStorage.setItem('cart', JSON.stringify(this.homeService.cartLoctionProducts))

        if(this.homeService.cartLoctionProducts.length > 0) {
          let id = this.homeService.cartLoctionProducts[0].locationId;
          this.homeService.getRestarentInfo(id).subscribe((resp: any) => {
            this.homeService.selectedLocationObj = resp;
            localStorage.setItem('selectedLocationObj', JSON.stringify(resp));
          }, (error) => {
            console.error("Error Occured Here", error);
          });
        }

      }
    })
  
  }

  doRegister() {
    this.registerFormSubmitted = true;
    if (this.registerForm.invalid) {
      return;
    } else {
      console.log("register user sucessfully");
      let obj = {
        name: this.registerUser.first_name + ' ' + this.registerUser.last_name,
        email: this.registerUser.email,
        phone: this.registerUser.country_code + this.registerUser.mobile_number,
        password: this.registerUser.password,
        otp: this.otp,
        role: 'user'
      }
      this.homeService.userRegister(obj).subscribe((resp: any) => {
        this.homeService.eventEmitter('sign_up','user_sign_up', 'Signup')
          this.closeBtn.nativeElement.click();
          // alert("Verification email sent successfully.");
          this.toast.success('Account created and verification link sent to your email.')
          this.registerUser= {
            first_name: '',
            last_name: '',
            email: '',
            mobile_number: '',
            password: '',
            country_code: 'undefined'
          };
          this.otpSent = false;
          if(this.ngOtpInputRef) this.ngOtpInputRef.nativeElement.setValue('')
      }, (error) => {
        console.log("Error Occured Here", error);
        this.toast.error(error)
      });
    }
  }

  validateButtonDisable() {
    if(this.registerForm.invalid) return true;
    if(this.registerUser.country_code == 'undefined') return true;
    if(this.otpSent) return true
    return false;
  }

  mobileNumChanges() {
    this.otpSent = false;
  }

  forgotPwd() {
    this.submitted = true;
    // this.userLoginForm = false;

    this.homeService.userForgotPassword({email: this.account.email}).subscribe((resp: UserRes) => {
      this.homeService.eventEmitter('forgot','user_forgot', 'Forgot')
      this.closeBtn.nativeElement.click();
      // alert("Reset email sent successfully.");
      this.toast.success('Reset link sent to your email.')
    }, (error) => {
      console.log("Error Occured Here", error);
      this.toast.error(error)
    });
  }

  showLoginForm() {
    this.currentForm = 'login'
  }
  showForgotForm() {
    this.currentForm = 'forgot'
  }
  showRegisterForm() {
    this.currentForm = 'register'
  }
  logout() {
    if(confirm('Are you sure you want to log out?')) {
      localStorage.clear();
      this.homeService.changeMyLogginIn(false);
      this.homeService.loggedInUser = null as any;
      this.homeService.cartLoctionProducts = [];
      this.router.navigate(['/']);
    }
  }
  mobileOTP() {
    if(this.registerUser.country_code == 'undefined') {
      this.toast.error('Country Code is required');
      return;
    }
    if(this.registerUser.mobile_number == '') {
      this.toast.error('Mobile number is required');
      return;
    }
    this.homeService.userRegisterMobileOtp({phone: this.registerUser.country_code + this.registerUser.mobile_number}).subscribe((data) => {
      if(data.errors) {

      } else if(data) {
        this.otpSent = true;
        this.counter$ = timer(0,1000).pipe(
          take(this.count),
          map(() => {
            let c = --this.count
            if(c==0) this.otpReSent = true;
            return c;
          })
        );
      }
    })
    
  }
  onOtpChange(e:any) {
    this.otp = e;
  }
  navToOrders() {
    this.router.navigate(['/orders']);
  }
  navToProfile() {
    this.router.navigate(['/profile']);
  }
}
