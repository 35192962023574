import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Constants } from 'src/app/config/constants';
import { HomeService } from 'src/app/services/home.service';

@Component({
  selector: 'app-footer-bar',
  templateUrl: './footer-bar.component.html',
  styleUrls: ['./footer-bar.component.scss']
})
export class FooterBarComponent implements OnInit {

  _countriesInfo: any = [];
  imagePathPrepend: string = Constants.REST_API_SERVER;

  constructor(public router: Router, public homeService: HomeService) {
    this.homeService.FooterInfo().subscribe((resp: any) => {
      this._countriesInfo = resp;
    }, (error) => {
      console.error('Error Occured here', error);
    })
  }

  ngOnInit(): void {
  }

  contactUs() {
    this.router.navigate(['contact']);
  }

  aboutUs() {
    this.router.navigate(['about']);
  }

  careers() {
    this.router.navigate(['career']);
  }

  latestUpdates() {
    this.router.navigate(['updates']);
  }

  privacy() {
    this.router.navigate(['privacy']);
  }

  terms() {
    this.router.navigate(['terms']);
  }

  refund() {
    this.router.navigate(['refund']);
  }
}
