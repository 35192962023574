import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from "@angular/common/http";

import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { PageSection } from '../interfaces/page-section';
import { Constants } from '../config/constants';
import { SearchSection } from '../interfaces/search-section';
import { SearchResult } from '../interfaces/search-result';
import { City } from '../interfaces/city';
import { Category } from '../interfaces/category';
import { of } from 'rxjs';
import { LocationProduct } from '../interfaces/location-product';
import { LocationProductRes } from '../interfaces/location-product-res';
import { UserRes } from '../interfaces/user.res';
import { ContactUs } from '../interfaces/contact-us';
import { OrderRes } from '../interfaces/order-res';

import { ToastrService } from 'ngx-toastr';
import { GetOrders } from '../interfaces/get-orders';

declare let gtag:Function;
@Injectable({
  providedIn: 'root',
})
export class HomeService {
  userPinLocation: any;
  userPinLocationAddress: string = '';
  userPinLocationCountry: any = '';
  appSettings: any;

  homeRoute: string = '';

  recentOrder: OrderRes;
  userAddressComponent: any = {};
  cartLoctionProducts: Array<LocationProduct> = new Array<LocationProduct>();
  selectedOrderType = '';
  selectedLocation = '';
  selectedLocationObj: any;
  loggedInUser: any;
  userCart: any[] = [];

  headerText = '';

  // Observalbles
  private loggedInBehaviour = new BehaviorSubject<boolean>(false);
  changeLoggedIn = this.loggedInBehaviour.asObservable();

  private _invokeLoginForm = new BehaviorSubject<boolean>(false);
  invokeLogin = this._invokeLoginForm.asObservable();

  private pageChangedBehaviour = new BehaviorSubject<string>('');
  pageChanged = this.pageChangedBehaviour.asObservable();

  constructor(private httpClient: HttpClient, private toast: ToastrService) {}

  handleError(error: HttpErrorResponse) {
    console.log(error);
    let errorMessage = 'Unknown error!';
    if (error.error instanceof ErrorEvent) {
      // Client-side errors
      errorMessage = `${error.error.message}`;
    } else {
      // Server-side errors
      console.log(error.error);
      errorMessage = `${error.error.message}`;
    }
    // window.alert(errorMessage);
    return throwError(errorMessage);
  }

  changeMyLogginIn(loggedIN: boolean) {
    this.loggedInBehaviour.next(loggedIN);
  }

  pageChangedTrigger(id: string) {
    this.pageChangedBehaviour.next(id);
  }

  showLoginForm() {
    this._invokeLoginForm.next(true);
  }

  public getHomePageData() {
    return this.httpClient
      .get<PageSection[]>(Constants.REST_API_SERVER + '/home/all')
      .pipe(retry(3), catchError(this.handleError));
  }

  public getHomeSettings() {
    return this.httpClient
      .get<any>(Constants.REST_API_SERVER + '/home/settings')
      .pipe(retry(3), catchError(this.handleError));
  }

  public searchData(term: string) {
    return this.httpClient
      .get<SearchSection[]>(`${Constants.REST_API_SERVER}/home/search/${term}`)
      .pipe(retry(3), catchError(this.handleError));
  }

  public searchLocationData(term: string) {
    return this.httpClient
      .get<SearchSection[]>(
        `${Constants.REST_API_SERVER}/home/searchlocation/${term}`
      )
      .pipe(retry(3), catchError(this.handleError));
  }

  public getLocationsBasedOnCity(cityId: string) {
    return this.httpClient
      .get<SearchResult[]>(
        `${Constants.REST_API_SERVER}/home/city/${cityId}/locations`
      )
      .pipe(retry(3), catchError(this.handleError));
  }

  public getCityBasedRestarents(cityID: string, term: any, location: any) {
    let params = `filter=${term}&fields=name&page=1&limit=500&sort=name&order=1&location=${location}&orderType=${this.selectedOrderType}`;
    return this.httpClient
      .get<SearchResult[]>(
        `${Constants.REST_API_SERVER}/home/city/${cityID}/locations?${params}`
      )
      .pipe(retry(3), catchError(this.handleError));
  }

  // public getCityBasedSearch(params: any) {
  //   return this.httpClient.get<SearchResult[]>(`${Constants.REST_API_SERVER}/home/location/search/1`, { params }).pipe(retry(3), catchError(this.handleError));
  // }

  getProductsCatagires() {
    return this.httpClient
      .get<Category[]>(Constants.REST_API_SERVER + '/home/products/categories')
      .pipe(retry(3), catchError(this.handleError));
  }

  public getCityInfo(cityId: string) {
    return this.httpClient
      .get<City>(`${Constants.REST_API_SERVER}/home/city/${cityId}`)
      .pipe(retry(3), catchError(this.handleError));
  }

  public getLocationInfo(locationId: string) {
    return this.httpClient
      .get<SearchResult>(
        `${Constants.REST_API_SERVER}/home/location/${locationId}`
      )
      .pipe(retry(3), catchError(this.handleError));
  }

  public getRestarentsList(locationId: string) {
    return this.httpClient
      .get<LocationProductRes>(
        `${Constants.REST_API_SERVER}/home/location/products/${locationId}`
      )
      .pipe(retry(3), catchError(this.handleError));
  }

  public getRestarentInfo(locationId: string) {
    // http://localhost:3000/home/location/60682bffd289951090e61b9e
    return this.httpClient
      .get<SearchResult>(
        `${Constants.REST_API_SERVER}/home/location/${locationId}`
      )
      .pipe(retry(3), catchError(this.handleError));
  }

  getOrderType() {
    return this.httpClient
      .get<PageSection[]>(Constants.REST_API_SERVER + '/home/order-types')
      .pipe(retry(3), catchError(this.handleError));
  }

  public getProductCategories() {
    return this.httpClient
      .get<Category[]>(`${Constants.REST_API_SERVER}/home/products/categories`)
      .pipe(retry(3), catchError(this.handleError));
  }

  public FooterInfo() {
    return this.httpClient
      .get<PageSection[]>(Constants.REST_API_SERVER + '/home/countries')
      .pipe(retry(3), catchError(this.handleError));
  }

  public userLogin(account: any) {
    return this.httpClient
      .post<UserRes>(Constants.REST_API_SERVER + '/auth/login', account)
      .pipe(retry(3), catchError(this.handleError));
  }
  /**Contactus */
  public ContactUS(contact: any) {
    return this.httpClient
      .post<ContactUs>(Constants.REST_API_SERVER + '/home/contactus', contact)
      .pipe(retry(3), catchError(this.handleError));
  }

  public userRegister(registeraccount: any) {
    return this.httpClient
      .post<SearchResult[]>(
        Constants.REST_API_SERVER + '/auth/register',
        registeraccount
      )
      .pipe(retry(3), catchError(this.handleError));
  }

  public userRegisterMobileOtp(registeraccount: any) {
    return this.httpClient
      .post<any>(
        Constants.REST_API_SERVER + '/auth/registermobileotp',
        registeraccount
      )
      .pipe(retry(3), catchError(this.handleError));
  }

  public userForgotPassword(forgot: any) {
    return this.httpClient
      .post<any>(Constants.REST_API_SERVER + '/auth/forgot', forgot)
      .pipe(retry(3), catchError(this.handleError));
  }

  public userVerify(verify: any) {
    return this.httpClient
      .post<any>(Constants.REST_API_SERVER + '/auth/verify', verify)
      .pipe(retry(3), catchError(this.handleError));
  }

  public userReset(reset: any) {
    return this.httpClient
      .post<any>(Constants.REST_API_SERVER + '/auth/reset', reset)
      .pipe(retry(3), catchError(this.handleError));
  }

  /**
   * Cart
   */
  public getCart() {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${this.loggedInUser.token}`,
    });
    return this.httpClient
      .get<any>(Constants.REST_API_SERVER + '/cart?page=1&page_size=100', {
        headers: headers,
      })
      .pipe(retry(3), catchError(this.handleError));
  }
  public addToCart(verify: any) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${this.loggedInUser.token}`,
    });
    return this.httpClient
      .post<any>(Constants.REST_API_SERVER + '/cart', verify, {
        headers: headers,
      })
      .pipe(retry(3), catchError(this.handleError));
  }
  public updateCart(id: any, verify: any) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${this.loggedInUser.token}`,
    });
    return this.httpClient
      .put<any>(Constants.REST_API_SERVER + '/cart/' + id, verify, {
        headers: headers,
      })
      .pipe(retry(3), catchError(this.handleError));
  }
  public deleteItemFromCart(id: any) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${this.loggedInUser.token}`,
    });
    return this.httpClient
      .delete<any>(Constants.REST_API_SERVER + '/cart/' + id, {
        headers: headers,
      })
      .pipe(retry(3), catchError(this.handleError));
  }
  public clearCart(verify: any) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${this.loggedInUser.token}`,
    });
    return this.httpClient
      .post<any>(Constants.REST_API_SERVER + '/cart/clear', verify, {
        headers: headers,
      })
      .pipe(retry(3), catchError(this.handleError));
  }
  public getCartAPI() {
    if (this.userPinLocation) {
      this.getCart().subscribe((cart) => {
        if (cart) {
          if (cart['data'].length > 0) {
            this.cartLoctionProducts = cart['data'];
          }
          localStorage.setItem(
            'cart',
            JSON.stringify(this.cartLoctionProducts)
          );

          if (this.cartLoctionProducts.length > 0) {
            let id = this.cartLoctionProducts[0].locationId;
            this.getRestarentInfo(id).subscribe(
              (resp: any) => {
                this.selectedLocationObj = resp;
                localStorage.setItem(
                  'selectedLocationObj',
                  JSON.stringify(resp)
                );
              },
              (error) => {
                console.error('Error Occured Here', error);
              }
            );
          }
        }
      });
    } else {
      this.cartLoctionProducts = [];
      localStorage.setItem('cart', JSON.stringify(this.cartLoctionProducts));
    }
  }
  // pagination
  getData(dataArray: any) {
    return of(dataArray);
  }

  public placeOrder(req: any) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${this.loggedInUser.token}`,
    });
    return this.httpClient
      .post<OrderRes>(Constants.REST_API_SERVER + '/orders', req, {
        headers: headers,
      })
      .pipe(retry(3), catchError(this.handleError));
  }

  public changeStatus(req: any) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${this.loggedInUser.token}`,
    });
    return this.httpClient
      .post<any>(Constants.REST_API_SERVER + '/orders/order_item/status', req, {
        headers: headers,
      })
      .pipe(retry(3), catchError(this.handleError));
  }

  public getOrders(pageInfo: string) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${this.loggedInUser.token}`,
    });
    return this.httpClient
      .get<GetOrders>(Constants.REST_API_SERVER + '/orders' + pageInfo, {
        headers: headers,
      })
      .pipe(retry(3), catchError(this.handleError));
  }

  public stripeIntent(req: any) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${this.loggedInUser.token}`,
    });
    return this.httpClient
      .post<OrderRes>(Constants.REST_API_SERVER + '/stripe/intents', req, {
        headers: headers,
      })
      .pipe(retry(3), catchError(this.handleError));
  }

  public saveprofile(req: any) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${this.loggedInUser.token}`,
    });
    return this.httpClient
      .patch<any>(Constants.REST_API_SERVER + '/profile', req, { headers })
      .pipe(retry(3), catchError(this.handleError));
  }

  public googlePlacesAPI(term: string) {
    return this.httpClient
      .get<any>(
        `https://maps.googleapis.com/maps/api/place/textsearch/json?query=${term}&key=${Constants.GOOGLE_MAPS_API_KEY}`
      )
      .pipe(retry(3), catchError(this.handleError));
  }

  public calcDistanceKm(coords1: any, coords2: any) {
    var R = 6371; // Radius of the earth in km
    var dLat = this.deg2rad(coords2.lat - coords1.lat); // deg2rad below
    var dLon = this.deg2rad(coords2.lng - coords1.lng);
    var a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(this.deg2rad(coords1.lat)) *
        Math.cos(this.deg2rad(coords2.lat)) *
        Math.sin(dLon / 2) *
        Math.sin(dLon / 2);
    var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    var d = R * c; // Distance in km
    return d;
  }

  deg2rad(deg: number) {
    return deg * (Math.PI / 180);
  }

  caluclateDeliveryCharges(distanceInKm: number) {
    let isDonationOrderType =
      this.homeRoute.toLocaleLowerCase().indexOf('donate') > -1;

    if (isDonationOrderType) return 0;

    if (this.appSettings) {
      if (distanceInKm <= this.appSettings['FREE_DELIVERY_DISTANCE']) return 0;
      else return this.appSettings['DELIVERY_CHARGES'];
    } else {
      return 100;
    }
  }

  public eventEmitter(
    eventName: string,
    eventCategory: string,
    eventAction: string,
    eventLabel: any = null,
    eventValue: any = null
  ) {
    gtag('event', eventName, {
      eventCategory: eventCategory,
      eventLabel: eventLabel,
      eventAction: eventAction,
      eventValue: eventValue,
    });
  }
}



