<div id="site-footer" class="site-footer footer">
  <div class="footer-bottom">
    <div class="container" style="padding: 20px">
      <div class="row nomargin">
        <div class="col-xs-12 col-sm-4 col-md-4">
          <div class="">
            <img src="../../assets/images/logo.png" class="logoImgs" />
          </div>

          <div class="module-heading">
            <p class="copyright">
              <a href="https://www.linkedin.com/" target="_blank" class="copyright">
                <i class="fa fa-linkedin-square fnt25" aria-hidden="true"></i>
              </a>
              <span class="paddL1">
                <a href="https://www.facebook.com/" target="_blank" class="copyright">
                  <i class="fa fa-facebook fnt25" aria-hidden="true"></i>
                </a>
              </span>
              
              <span class="paddL1">
                <a href="https://twitter.com/" target="_blank" class="copyright">
                  <i class="fa fa-twitter fnt25" aria-hidden="true"></i>
                </a>
              </span>
              <span class="paddL1">
                <a href="https://www.instagram.com/" target="_blank" class="copyright">
                  <i class="fa fa-instagram fnt25" aria-hidden="true"></i>
                </a>
              </span>
            </p>


          </div>

          <!-- <div class="module-heading font13">
            <p class="appFeatures">
              <span (click)="careers()"> Careers </span>
              <span class="footerTerms" (click)="aboutUs()"> About us </span>
              <span class="footerTerms" (click)="latestUpdates()">
                Latest update
              </span>
              <span class="footerTerms" (click)="contactUs()"> Contact Us </span>
            </p>
          </div> -->

          <!-- <div class="main">
            <div class="careers_info" (click)="careers()"> Careers </div>
            <div class="careers_info" (click)="aboutUs()"> About us </div>
            <div class="careers_info" (click)="latestUpdates()"> Latest update</div>
            <div class="careers_info" (click)="contactUs()"> Contact Us </div>
          </div> -->
        </div>

        <div class="col-xs-12 col-sm-4 col-md-4">
          <div class="row module-body nomargin module-title mb-3">
            <div class="col p-0">
              <div class="middle-title">COMPANY</div>
              <div class="footer-link" (click)="aboutUs()"> About Easy Zabiha </div>
            </div>
            <div class="col p-0">
              <div class="middle-title">CONTACT</div>
              <div class="footer-link" (click)="careers()"> Careers </div>
              <div class="footer-link" (click)="contactUs()"> Contact Us </div>

            </div>
          </div>
        </div>

        <div class="col p-0">
          <div class="module-heading">
            <h4 class="module-title mb-3">
              A world of customers is at your doorstep
            </h4>
            <p class="contentText font13 mb-5">
              Easy Zabiha platform gives you the unique experience to connect
              more customers with Ease.
            </p>
          </div>

          <div class="row module-body nomargin">
            <div class="col-12 p-0">
              <ul class="list-unstyled">

                <li class="footer_info" *ngFor="let cuntriesList of _countriesInfo; let i = index">
                  <a class="countries_name" title="Contact us">
                    <img [src]="imagePathPrepend + cuntriesList?.flag?.path" class="stateLogos">
                    {{cuntriesList.name}}
                  </a>

                </li>
                <!-- imagePathPrepend -->
                <!-- <li class="listStaes">
                  <a class="hylinks" title="About us"
                    ><img
                      src="../../assets/images/usa-logo.png"
                      class="stateLogos"
                    />CANADA</a
                  >
                </li>
                <li class="listStaes">
                  <a class="hylinks" title="faq"
                    ><img
                      src="../../assets/images/usa-logo.png"
                      class="stateLogos"
                    />UK</a
                  >
                </li>
                <li class="listStaes">
                  <a class="hylinks" title="Popular Searches"
                    ><img
                      src="../../assets/images/usa-logo.png"
                      class="stateLogos"
                    />GERMANY</a
                  >
                </li>
                <li class="listStaes" class="last">
                  <a class="hylinks" title="Where is my order?"
                    ><img
                      src="../../assets/images/usa-logo.png"
                      class="stateLogos"
                    />AUSTRALIA</a
                  >
                </li> -->
              </ul>
            </div>
            <!-- <div class="col-xs-12 col-sm-6 col-md-6">
              <ul class="list-unstyled">
                <li class="listStaes">
                  <a class="hylinks" title="Contact us"><img src="../../assets/images/usa-logo.png"
                      class="stateLogos" />NEW ZEALAND</a>
                </li>
                <li class="listStaes">
                  <a class="hylinks" title="About us"><img src="../../assets/images/usa-logo.png"
                      class="stateLogos" />SINGAPORE</a>
                </li>
                <li class="listStaes">
                  <a class="hylinks" title="faq"><img src="../../assets/images/usa-logo.png"
                      class="stateLogos" />UAE</a>
                </li>
                <li class="listStaes">
                  <a class="hylinks" title="Popular Searches"><img src="../../assets/images/usa-logo.png"
                      class="stateLogos" />Specials</a>
                </li>
                <li class="listStaes" class="last">
                  <a class="hylinks" title="Where is my order?"><img src="../../assets/images/usa-logo.png"
                      class="stateLogos" />SAUDI ARABIA</a>
                </li>
              </ul>
            </div> -->
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="footerCopy font13">
    <div class="container">
      <div class="row nomargin">
        <div class="col-xs-12 col-sm-6 col-md-6">
          <div class="font14">
            <p class="copyright">&copy; 2022 Easy zabiha Org.</p>
          </div>
        </div>

        
        <div class="col-xs-12 col-sm-6 col-md-6 text-right">
          <div class="main">
            <div class="privacy_info" (click)="privacy()">  Privacy Policy  </div>
            <div class="privacy_info" (click)="terms()"> Terms and conditions </div>
            <div class="privacy_info" (click)="refund()"> Refund and Cancellation Policy </div>
            <!-- <div class="careers_info" > Latest update</div> -->
            <!-- <div class="careers_info" (click)="contactUs()"> Contact Us </div> -->
          </div>
          <!-- <div class="module-heading">
            <p class="copyright">
              Privacy Policy <span class="paddL1"> Terms and conditions </span>
              <span class="paddL1"> Refund and Cancellation Policy </span>
            </p>
          </div> -->
        </div>
      </div>
    </div>
  </div>
</div>