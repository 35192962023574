<section class="location-detail">
    <div class="row mr-0 ml-0">
        <div class="col-4">
            <div class="leftSection">
                <div class="location-header">
                    <fa-icon [icon]="faBackward"></fa-icon> <span class="pl-2">Search Result</span>
                    <div class="details pt-4" *ngIf="locationInfo">
                        <h4 class="loc-name">{{locationInfo.name}}</h4>
                        <div class="loc-addr"><fa-icon [icon]="faMapMarkedAlt"></fa-icon> <span class="pl-2">{{locationInfo.address}}, {{locationInfo.city.name}}, {{locationInfo.city.country.name}}.</span></div>
                    </div>
                </div>
                <div class="product-categories">
                    <div *ngFor="let category of productTopLevelCategories; let i=index" class="category" [ngClass]="{'active': activeCategory==category._id}"  (click)="categoryClick(category._id)">
                        <img [src]="imagePathPrepend + category.image" > <span class="pl-3">{{category.name}}</span>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-8 pl-0 pr-0">
            <div class="row mr-0 ml-0">
                <div class="col-auto p-4">
                    <div>Category</div>
                    <div class="subcategory" [ngClass]="{'active': activeSubCategory==''}" (click)="subCategoryClick('')">
                        <span>All</span>
                    </div>
                    <div *ngFor="let category of subCategories; let i=index" [ngClass]="{'active': activeSubCategory==category._id}" class="subcategory" (click)="subCategoryClick(category._id)">
                        <span>{{category.name}}</span>
                    </div>
                </div>
                <div class="col">
                </div>
            </div>
        </div>
    </div>
</section>
