<!-- <p>slider-banners works!</p> -->
<!-- <div id="carouselExampleIndicators" class="carousel slide" data-ride="carousel">
    <ol class="carousel-indicators">
        <li data-target="#carouselExampleIndicators" data-slide-to="0" class="active"></li>
        <li data-target="#carouselExampleIndicators" data-slide-to="1"></li>
        <li data-target="#carouselExampleIndicators" data-slide-to="2"></li>
    </ol>
    <div class="carousel-inner">
        <div class="carousel-item active">
            <img class="d-block w-100 height400" src="../../assets/images/test.png" alt="First slide">
        </div>
        <div class="carousel-item">
            <img class="d-block w-100 height400" src="../../assets/images/test.png" alt="Second slide">
        </div>
        <div class="carousel-item">
            <img class="d-block w-100 height400" src="../../assets/images/test.png" alt="Third slide">
        </div>
    </div>
    <a class="carousel-control-prev" href="#carouselExampleIndicators" role="button" data-slide="prev">
        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
        <span class="sr-only">Previous</span>
    </a>
    <a class="carousel-control-next" href="#carouselExampleIndicators" role="button" data-slide="next">
        <span class="carousel-control-next-icon" aria-hidden="true"></span>
        <span class="sr-only">Next</span>
    </a>
</div> -->

<!-- <div id="carouselExampleIndicators" #mycarouselControls class="carousel slide" data-ride="carousel" data-interval="2000">
    <div class="carousel-inner">                
            <ul *ngFor="let pic of imagePaths;let i=index; first as isFirst">
                <div [ngClass]="isFirst ? 'carousel-item active' : 'carousel-item' ">
                    <li><img [src]="imagePathPrepend + pic.path" class="d-block w-100 carousel-pic" alt="..."></li>
                </div>                                     
            </ul>
    </div>
</div> -->

<div id="carouselExampleCaptions" class="carousel slide" data-ride="carousel">
    <ol class="carousel-indicators">
        <li data-target="#carouselExampleCaptions" *ngFor="let item of imagePaths;let index = index"
            [attr.data-slide-to]="index"></li>
    </ol>
    <div class="carousel-inner">
        <div class="carousel-item" *ngFor="let item of imagePaths;let index = index;let isFirst = first"
            [ngClass]="{active:isFirst}">
            <img style="margin:60px 0;" [src]="imagePathPrepend + item.path" class="d-block w-100 height400" [alt]="imagePaths.title">
            <!-- <div class="carousel-caption d-none d-md-block">
                <h5 [textContent]="item.title"></h5>
                <p [textContent]="item.symmery"></p>
            </div> -->
        </div>
    </div>
    <a class="carousel-control-prev" href="#carouselExampleCaptions" role="button" data-slide="prev">
        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
        <span class="sr-only">Previous</span>
    </a>
    <a class="carousel-control-next" href="#carouselExampleCaptions" role="button" data-slide="next">
        <span class="carousel-control-next-icon" aria-hidden="true"></span>
        <span class="sr-only">Next</span>
    </a>
</div>


<!-- <div *ngFor="let name of imagePaths;let i = index">    
    <img [src]="imagePathPrepend + name.path">
</div> -->