import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { HomeComponent } from './home/home/home.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LocationsComponent } from './pages/locations/locations.component';
import { GoogleMapsModule } from '@angular/google-maps';
import { LocationComponent } from './pages/location/location.component';

import { RouterModule } from '@angular/router';
import { HeaderComponent } from './components/header/header.component';
import { Section1Component } from './components/home/section1/section1.component';
import { NgScrollbarModule } from 'ngx-scrollbar';

import { FooterBarComponent } from './components/footer-bar/footer-bar.component';
import { AboutUsComponent } from './components/about-us/about-us.component';
import { ContactUsComponent } from './components/contact-us/contact-us.component';
import { DownloadappComponent } from './components/downloadapp/downloadapp.component';
import { JoinWithEasyZaihaComponent } from './components/join-with-easy-zaiha/join-with-easy-zaiha.component';
import { SliderBannersComponent } from './components/slider-banners/slider-banners.component';
import { WhyEasyZaihaComponent } from './components/why-easy-zaiha/why-easy-zaiha.component';
import { CareersComponent } from './components/careers/careers.component';
import { LatestUpdatesComponent } from './components/latest-updates/latest-updates.component';
import { ExploreCareersComponent } from './components/explore-careers/explore-careers.component';
import { SearchRestaurantComponent } from './components/search-restaurant/search-restaurant.component';
import { ListOfRestaurantComponent } from './components/list-of-restaurant/list-of-restaurant.component';
import { LoginComponent } from './pages/login/login.component';
import { OrderItemsComponent } from './components/order-items/order-items.component';
import { OrderConfirmedComponent } from './components/order-confirmed/order-confirmed.component';
import { CustomerSpecificationsComponent } from './components/customer-specifications/customer-specifications.component';
import {NgbPaginationModule, NgbAlertModule} from '@ng-bootstrap/ng-bootstrap';
import { Section2Component } from './components/home/section2/section2.component';
import { ProductsPipePipe } from './pipes/productsPipe/products-pipe.pipe';
import { ResetComponent } from './components/reset/reset.component';
import { VerifyComponent } from './components/verify/verify.component';
import { NgOtpInputModule } from 'ng-otp-input';
import { ToastrModule } from 'ngx-toastr';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LoadingBarHttpClientModule } from '@ngx-loading-bar/http-client';
import { LoadingBarRouterModule } from '@ngx-loading-bar/router';
import { LoadingBarModule } from '@ngx-loading-bar/core';
import { MyOrderComponent } from './components/my-orders/my-orders.component';
import { OrderComponent } from './components/order/order.component';
import { ProfileComponent } from './components/profile/profile.component';
import { RegisterSupplierComponent } from './components/register-supplier/register-supplier.component';
import { RegisterDeliveryComponent } from './components/register-delivery/register-delivery.component';
import { PrivacyPolicyComponent } from './components/privacy-policy/privacy-policy.component';
import { TermsComponent } from './components/terms/terms.component';
import { RefundComponent } from './components/refund/refund.component';
import { IntegerInputDirective } from './directives/interget-input.directive';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { DonationspipePipe } from './pipes/donationsPipe/donationspipe.pipe';
import { HowItWorksComponent } from './components/how-it-works/how-it-works.component';
import { TabsComponent } from './components/home/tabs/tabs.component';

import { SocialLoginModule, SocialAuthServiceConfig } from 'angularx-social-login';
import {
  GoogleLoginProvider,
  FacebookLoginProvider
} from 'angularx-social-login';

@NgModule({
  declarations: [
    IntegerInputDirective,
    AppComponent,
    HomeComponent,
    LocationsComponent,
    LocationComponent,
    SliderBannersComponent,
    DownloadappComponent,
    WhyEasyZaihaComponent,
    JoinWithEasyZaihaComponent,
    HeaderComponent,
    AboutUsComponent,
    ContactUsComponent,
    FooterBarComponent,
    Section1Component,
    CareersComponent,
    LatestUpdatesComponent,
    ExploreCareersComponent,
    SearchRestaurantComponent,
    ListOfRestaurantComponent,
    LoginComponent,
    OrderItemsComponent,
    OrderConfirmedComponent,
    CustomerSpecificationsComponent,
    Section2Component,
    ProductsPipePipe,
    ResetComponent,
    VerifyComponent,
    MyOrderComponent,
    OrderComponent,
    ProfileComponent,
    RegisterSupplierComponent,
    RegisterDeliveryComponent,
    PrivacyPolicyComponent,
    TermsComponent,
    RefundComponent,
    DonationspipePipe,
    HowItWorksComponent,
    TabsComponent,
  ],


  imports: [
    BrowserModule,
    ScrollingModule,
    AppRoutingModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
    NgbModule,
    FontAwesomeModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    GoogleMapsModule,
    RouterModule,
    NgScrollbarModule,
    NgbPaginationModule, 
    NgbAlertModule,
    NgOtpInputModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot({
      timeOut: 3000,
      positionClass: 'toast-bottom-right',
      preventDuplicates: true,
    }),
    LoadingBarHttpClientModule,
    LoadingBarRouterModule,
    LoadingBarModule,
    SocialLoginModule
  ],
  exports: [

  ],
  entryComponents: [

  ],

  providers: [
    {
      provide: 'SocialAuthServiceConfig',
      useValue: {
        autoLogin: false,
        providers: [
          {
            id: GoogleLoginProvider.PROVIDER_ID,
            provider: new GoogleLoginProvider(
              '723310664820-be048kopt4v3c2an0aoqdlad6b937d8g.apps.googleusercontent.com'
            )
          },
          {
            id: FacebookLoginProvider.PROVIDER_ID,
            provider: new FacebookLoginProvider('566705445015779')
          }
        ],
        onError: (err) => {
          console.error(err);
        }
      } as SocialAuthServiceConfig,
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
