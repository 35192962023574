<div class="mainHeader">

    <ng-scrollbar>


        <div id="site" class="restarent-items">
            <div class="restarent-items-infor ">
                <div class="container">
                    <div class="row">
                        <div class="col-xs-12 col-sm-6 col-md-8">
                            
                            <div class="marB16 card bxshadow summaryCard">
                                <div class="container">
                                    <div class="row">
                                        <div class="col-auto">
                                            <div class="card-number">1</div>
                                        </div>
                                        <div class="col nopadding">
                                            <h5>Account</h5>
                                            <div class="summary-body">
                                                <p class="summary-hint" *ngIf="!homeService.loggedInUser">
                                                    To place your order now, log in to your existing account or register.
                                                </p>
                                                <div class="row" *ngIf="homeService.loggedInUser">
                                                    <div class="col-xs-12 col-sm-6 col-md-6">
                                                        <div class="text-label">Full name</div>
                                                        <div class="text-value">{{homeService.loggedInUser?.user?.name}}</div>
                                                    </div>
                                                    <div class="col-xs-12 col-sm-6 col-md-6">
                                                        <div class="text-label">Email address</div>
                                                        <div class="text-value">{{homeService.loggedInUser?.user?.email}}</div>
                                                    </div>
                                                    <div class="col-xs-12 col-sm-6 col-md-6" style="margin-top: 10px;">
                                                        <div class="text-label">Mobile</div>
                                                        <div class="text-value">{{homeService.loggedInUser?.user?.phone}}</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>

                            <div class="marB16 card bxshadow summaryCard">
                                <div class="container">
                                    <div class="row">
                                        <div class="col-auto">
                                            <div class="card-number">2</div>
                                        </div>
                                        <div class="col nopadding">
                                            <h5>Delivery</h5>
                                            <div class="summary-body" *ngIf="!donationOrderType">
                                                <div class="row" *ngIf="homeService.loggedInUser">
                                                    <div class="col-xs-6 col-sm-6 col-md-6">
                                                        <div class="text-label">Delivery address</div>
                                                        <div class="text-value">{{homeService.userPinLocationAddress}}</div>

                                                        
                                                          <div class="form-check"  style="margin-top:40px;" >
                                                            <input type="checkbox" [(ngModel)]="pickup" class="form-check-input" id="exampleCheck1" (change)="onPickUpChanged($event)">
                                                            <label class="form-check-label theme-color" for="exampleCheck1">Pickup Available</label>
                                                        </div>
                                                        <small class="text-label" *ngIf="pickup">{{homeService.selectedLocationObj?.address}}</small>

                                                        
                                                        </div>
                                                        <div class="col-xs-6 col-sm-6 col-md-6 text-right">
                                                            <div class="form-group">
                                                                <label class="text-label mb-0" [ngClass]="{'required': delivery_address==''}">Contactless Delivery *</label>
                                                                <input type="text" class="form-control" placeholder="Delivery address" [ngClass]="{'is-invalid': delivery_address==''}"  maxlength="100"
                                                                [(ngModel)]="delivery_address" />
                                                                <small class="text-label">eg: Apartment/Suite Number, Building Name, etc</small>
                                                              </div>
                                                    </div>
                                                </div>
                                                <div class="row" *ngIf="homeService.loggedInUser">
                                                    
                                                    <div class="col mapRightFixed">
                                                        <!-- <img src="assets/images/banners/map.jpg" style="width:100%"> -->
                                                        <google-map width="100%" height="100%" 
                                                        [center]="center"
                                                        [zoom]="zoom"
                                                        [options]="options">
                                                        </google-map>
                                        
                                                        <img src="assets/images/marker.png" class="mapMarkerCenter" />
                                                        <div class="setMarkerLocation" (click)="setMapLOcation()" *ngIf="false">
                                                            Set Pin Location
                                                        </div>
                                                    </div>
                                                    
                                                </div>
                                            </div>

                                            <div class="summary-body" *ngIf="!donationOrderType">
                                                <div class="row" *ngIf="homeService.loggedInUser">
                                                    <div class="col-xs-6 col-sm-6 col-md-6">
                                                        <div class="text-label">Delivery</div>
                                                        <div class="text-value"></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>

                            <div class="marB16 card bxshadow summaryCard">
                                <div class="container">
                                    <div class="row">
                                        <div class="col-auto">
                                            <div class="card-number">3</div>
                                        </div>
                                        <div class="col nopadding">
                                            <h5>Payment</h5>
                                            <div class="summary-body">
                                                
                                                <div class="row">
                                                    <div class="col-xs-12 col-sm-12 col-md-12">
                                                        <div id="tipBlock">
                                                            <b>Thank your delivery person with a tip</b>
                                                            <div class="f12lightgray">
                                                                Your tip means a lot,it will be tranferred full with rider's weekly payment.
                                                            </div>
                                                            <div class="mt-4">
                                                                <div class="row no-gutters">
                                                                    <div class="col-xs-4 col-sm-4 col-md-4 tiplabel">
                                                                        Select amount
                                                                    </div>
                                                                    <div class="col-auto">
                                                                        <div class="tip" [ngClass]="{active: tip==0}" (click)="tipChange(0)">
                                                                            {{0|currency:currency:'symbol':'1.0-0'}}
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-auto">
                                                                        <div class="tip" [ngClass]="{active: tip==5}" (click)="tipChange(5)">
                                                                            {{5|currency:currency:'symbol':'1.0-0'}}
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-auto">
                                                                        <div class="tip" [ngClass]="{active: tip==10}" (click)="tipChange(10)">
                                                                            {{10|currency:currency:'symbol':'1.0-0'}}
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-auto">
                                                                        <div class="tip" [ngClass]="{active: tip==15}" (click)="tipChange(15)">
                                                                            {{15|currency:currency:'symbol':'1.0-0'}}
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-auto">
                                                                        <div class="tip" [ngClass]="{active: tip==20}" (click)="tipChange(20)">
                                                                            {{20|currency:currency:'symbol':'1.0-0'}}
                                                                        </div>
                                                                    </div>
                                                                    <div class="col text-right align-items-end">
                                                                        <div class="tipInputBlock float-right">
                                                                            {{currencySymbol(currency)}} <input integerInput type="number" (keypress)="quantityChangeInput($event)" (keyup)="tipChangeInput($event)" class="tipInput" [(ngModel)]="tip" min="0" max="999" />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-xs-12 col-sm-12 col-md-12">
                                                        <label for="card-info">Pay with Credit Card / Debit Card <img src="assets/images/stripe-cards.png" style="height: 50px;margin-left: 10px; margin-top: -4px;" /></label>
                                                        <div id="form-field">
                                                            <div id="card-info" #cardInfo></div>
                                                        </div>
                                                        <div class="payByCardOverlay" *ngIf="intentProcessing">
                                                            <i class="fa fa-spinner fa-spin"></i>
                                                        </div>
                                                    </div>
                                                    <div class="col-xs-12 col-sm-12 col-md-12 text-right" *ngIf="homeService.cartLoctionProducts.length>0">
                                                        <button class="btn btn-success" *ngIf="!paymentProcessing" (click)="submitPayment()" data-toggle="modal" data-target="#paymentModal" [disabled]="delivery_address==''||cardError!=''">
                                                            Pay {{totalPrice+totalDelivery+totalTax+tip+serviceFee+serviceHST| currency:currency}}
                                                        </button>

                                                        <button class="btn btn-success" [disabled]="true" *ngIf="paymentProcessing">
                                                            <i class="fa fa-spinner fa-spin" aria-hidden="true"></i> Processing Payment... {{totalPrice+totalDelivery+totalTax+tip+serviceFee+serviceHST| currency:currency}}
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            
                        </div>

                        <div class="col-xs-12 col-sm-6 col-md-4 nopadding">
                            <div class="card bxshadow marT10 height200">
                                <div class="padd10">
                                    <div class="emtyCart" *ngIf="homeService.cartLoctionProducts.length ==0">
                                        <div class="emptyImg">
                                            <img src="../../../assets/images/emptycart.png" class="cartEmptyImg">
                                        </div>

                                        <div class="cartEmptyLabel">
                                            No products in cart.
                                        </div>

                                    </div>
                                    <div class="cart" *ngIf="homeService.cartLoctionProducts.length > 0">
                                        <h4 class="oreder">
                                            <img src="../../../assets/images/bag.png"> Order cart
                                        </h4>
                                        <div class="orderItems">
                                            <div *ngFor="let price of homeService.cartLoctionProducts;let i = index">
                                                <div class="orderItem">
                                                    <span class="item-quantity" style="margin-right: 10px;">{{price.quantity ? price.quantity : 1}} X </span>
                                                    {{price.product.name}} <span style="float: right;"> {{discountedPrice(price) | currency:currency}}
                                                        <span style="float: right;color: red; padding: 0 10px;font-size: 16px;margin-top: -3px;" (click)="remove(i)"> <i class="fa fa-times-circle" aria-hidden="true"></i> </span>
                                                    </span>
                                                    <!-- <div class="item-ans">
                                                        <div *ngFor="let prod of price.product.specs">{{prod.ans}}</div>
                                                    </div> -->
                                                </div>
                                                
                                            </div>
                                        </div>
                                        <div class="dashed-line"></div>
                                        <div class="price-block">
                                            <div class="title">Total Bill</div>
                                            <div class="row">
                                                <div class="col price-label">Item total</div>
                                                <div class="col-auto text-right price-value">{{totalPrice | currency:currency}}</div>
                                            </div>
                                            <div class="row">
                                                <div class="col price-label">Tax</div>
                                                <div class="col-auto text-right price-value">{{totalTax| currency:currency}}</div>
                                            </div>
                                            <div class="row">
                                                <div class="col price-label">Delivery charges</div>
                                                <div class="col-auto text-right price-value">{{totalDelivery| currency:currency}}</div>
                                            </div>
                                            
                                            <div class="row">
                                                <div class="col price-label">Tip</div>
                                                <div class="col-auto text-right price-value">{{tip| currency:currency}}</div>
                                            </div>

                                            <div class="row">
                                                <div class="col price-label">Service Fee</div>
                                                <div class="col-auto text-right price-value">{{serviceFee| currency:currency}}</div>
                                            </div>
                                            <div class="row">
                                                <div class="col price-label">Service HST</div>
                                                <div class="col-auto text-right price-value">{{serviceHST| currency:currency}}</div>
                                            </div>
                                            <div class="dashed-line"></div>
                                            <div class="row">
                                                <div class="col price-label price-total">To pay</div>
                                                <div class="col-auto text-right price-value price-total">{{totalPrice+totalDelivery+totalTax+tip+serviceFee+serviceHST| currency:currency}}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        
        <app-footer-bar></app-footer-bar>
    </ng-scrollbar>
</div>
<div class="modal fade" id="paymentModal" tabindex="-1" role="dialog" 
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered " role="document">
      
      <div class="modal-content" style="border-radius: 10px;overflow: hidden;">
        <div class="modal-close-inside">
            <button type="button" class="closeButton" data-dismiss="modal" aria-label="Close" #closeBtn>
              <span aria-hidden="true" #closeBtn>
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 18 18"><path d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"/></svg>
              </span>
            </button>
          </div>
        <div class="modal-body specPopup" style="padding:0;">
            <div>
                <div class="module-heading">
                    
                    <div  *ngIf="!paymentProcessing">
                        <i class="fa fa-check" aria-hidden="true" *ngIf="paymentSuccess"></i>
                        <i class="fa fa-close" aria-hidden="true" style="color:red;" *ngIf="!paymentSuccess"></i> &nbsp;&nbsp;&nbsp; {{paymentMessage}}<br />
                    </div>

                    <div *ngIf="paymentProcessing">
                        <i class="fa fa-spinner fa-spin" aria-hidden="true"></i> &nbsp;&nbsp;&nbsp; Processing your payment...
                    </div>
                </div>
                <!-- <button class="btn btn-success w-100" data-dismiss="modal"> 
                    Close
                </button> -->
          </div>
        </div>
      </div>
    </div>
</div>