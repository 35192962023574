import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { HomeService } from 'src/app/services/home.service';

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.scss']
})
export class ContactUsComponent implements OnInit {
  @ViewChild('closeBtn') closeBtn: ElementRef;
  @ViewChild('ngOtpInput') ngOtpInputRef:ElementRef;
  contactForm: FormGroup;
  submitted: boolean = false;

  account: { name: string, email: string, mobile_number: string, message: string} = {
      name: '', email: '', mobile_number: '', message: '',
    }

  constructor(public formBuilder: FormBuilder, public homeService: HomeService,
    private router: Router,
     private toast: ToastrService
    ) { }

  ngOnInit(): void {
    this.contactForm = this.formBuilder.group({
      name: ['', [Validators.required, Validators.maxLength(20)]],
      email: ['', [Validators.required, Validators.email]],
      mobile_number: ['', Validators.required],
      message: ['', [Validators.required, Validators.maxLength(100)]],
    });
  }

  get f() { return this.contactForm.controls; }
formSbmitted() {
  this.submitted = true;

  if (this.contactForm.invalid) {
    return;
  }else {
    console.log("register user sucessfully");
    let obj = {
      name: this.account.name,
      email: this.account.email,
      mobile_number: this.account.mobile_number,
      message: this.account.message,
    }
    this.homeService.ContactUS(obj).subscribe((resp: any) => {
      this. account= {
        name: '', email: '', mobile_number: '', message: '',
      }
      // this.contactForm.reset();
        // this.closeBtn.nativeElement.click();
        // alert("Contactus message sent successfully.");
        this.toast.success('Contactus message sent successfully.')
        this.router.navigateByUrl('/')

    }, (error) => {
      console.log("Error Occured Here", error);
      this.toast.error(error)
    });
    
  }

}
  // form submitted
  // submit() {
  //   this.submitted = true;

  //   if (this.contactForm.invalid) {
  //     return;
  //   }else {
  //     this.homeService.ContactUS(this.account).subscribe((resp: ContactUS) => {
  //       this.closeBtn.nativeElement.click();
  //       this.homeService.loggedInUser = resp;
  //       localStorage.setItem('user', JSON.stringify(resp));
  //       this.homeService.changeMyLogginIn(true);
  //       // this.router.navigate(['']);
  //     }, (error) => {
  //       console.log("Error Occured Here", error);
  //       this.toast.error(error)

  //     });
  //   }
  //   //  success
  //   console.log("sucess response value", this.account)
  // }
}
