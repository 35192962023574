<section class="map-search">
    <div class="row mr-0 ml-0">
        <div class="col-7 pl-0 pr-0">
            <div class="search-results-top">
                <div class="links-s">
                    <div class="links-s-1">
                        <div class="row">
                            <div class="col searchText">
                                <fa-icon [icon]="faSearch"></fa-icon> <span class="ml-3" *ngIf="cityInfo">{{cityInfo.name}}, {{cityInfo.country.name}}</span>
                            </div>
                            <div class="col-auto">
                                <a href="javascript:void(0)" (click)="onChangeLocation()">Change</a>
                            </div>
                        </div>
                        
                    </div>
                    <h4 class="results mt-3">{{searchResults.length}} Masjids in {{cityInfo.name}}, {{cityInfo.country.name}}</h4>
                </div>
            </div>
            <div class="search-results-bottom mt-3">
                <div class="row p-4" *ngFor="let item of searchResults; let i=index" (click)="setCenter(item)">
                    <div class="col-5 ml-0 mr-0 pl-0">
                        <img [src]="item.image.length>0 ? imagePathPrepend+item.image[0].path :'assets/images/banners/search-img.jpg'" class="location-img">
                    </div>
                    <div class="col-7 ml-0 mr-0 pr-0">
                        <div class="row1 mt-3">
                            <span class="title">{{item.name}}</span>             
                        </div>
                        <div class="row2 mt-1">
                            <span class="share"><fa-icon [icon]="faMapMarkedAlt"></fa-icon> {{item.address}}, {{item.city.name}}, {{item.city.country.name}}</span>
                        </div>
                        <div class="row3 mt-4">
                            <span class="serve" (click)="markerClick(item)">Serve Items</span>
                        </div>
                    </div>
                </div>
                
            </div>
        </div>
        <div class="col-5 mapRightFixed pl-0 pr-0">
            <!-- <img src="assets/images/banners/map.jpg" style="width:100%"> -->
            <google-map width="100%" height="100%" 
            [center]="center"
            [options]="options">
                <map-marker
                    #markerElem
                    *ngFor="let marker of markers"
                    [position]="marker.position"
                    [label]="marker.label"
                    [title]="marker.title"
                    [options]="marker.options"
                    (mapClick)="markerClick(marker)"
                ></map-marker>
            </google-map>
        </div>
    </div>
</section>

