<div class="section2-wrapper">
    <div class="container">
        <div class="row">
            <div class="col left-container">
                <div class="content-text">
                    <h2 class="MuiTypography-h2 mb-3">
                        <span class="dark-bl f-b" style="color: #158A44;">Reward of feeding the needy in Islam</span>
                    </h2>
                    <!-- <div class="info">Festival of the Sacrifice</div> -->
                    <div>Each time a person is benefited through your donation, you perform a virtuous act that will make you the best of people that will enter Jannah !!</div>
                    <div class="mt-3">Please share a word and encourage your family and friends to donate and support for a cause. Our Prophet (saw) said, “Whoever guides someone to goodness will have a reward like one who did it”.</div>
                </div>
            </div>
            <div class="col-auto right-container">
                <img src="assets/images/section-2.png" />
            </div>

        </div>
    </div>
</div>
